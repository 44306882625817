import React from 'react'
import MultiSelectPropertyType from './multiselect-property'
import CustomSelect from '@shared/forms/select'
import { Project } from 'types'
import {
  CITY_OPTIONS,
  PROPERTY_TYPES,
  STATE_OPTIONS,
  STATUS_OPTIONS,
  TIMELINE_OPTIONS,
} from '@utils/constants'

interface DetailsFormProps {
  project: Project
  onInputChange: (name: keyof Project, value: string | string[]) => void
}

const DetailsForm: React.FC<DetailsFormProps> = ({
  project,
  onInputChange,
}) => {
  return (
    <div>
      <div className="my-4">
        <label className="fw-bolder form-label text-capitalize">
          What is your project name
        </label>
        <input
          type="text"
          className="form-control rounded-pill py-3 px-4 fs-14 mb-3"
          value={project.projectName}
          onChange={(e) => onInputChange('projectName', e.target.value)}
          placeholder="Enter project name"
        />
      </div>

      <div>
        <label className="fw-bolder form-label text-capitalize">
          Describe your project
        </label>
        <textarea
          className="form-control form-control-0 ps-3 py-3 border"
          placeholder="Enter project description"
          value={project.description}
          required
          rows={5}
          onChange={(e) => onInputChange('description', e.target.value)}
        />
      </div>

      <div className="my-4">
        <MultiSelectPropertyType
          title="Property Types"
          placeholder="Select property types"
          selectedOptions={project.projectPropertiesType}
          setSelectedOptions={(types) =>
            onInputChange('projectPropertiesType', types)
          }
          options={PROPERTY_TYPES}
        />
      </div>

      <CustomSelect
        label="Project Status"
        value={project.projectStatus}
        onChange={(value) => onInputChange('projectStatus', value)}
        options={STATUS_OPTIONS}
      />

      <div className="my-4">
        <label className="fw-bolder form-label text-capitalize">
          Size of project
        </label>
        <input
          type="text"
          className="form-control rounded-pill py-3 px-4 fs-14 mb-3"
          value={project.projectSize}
          onChange={(e) => onInputChange('projectSize', e.target.value)}
          placeholder="Enter project size"
        />
      </div>

      <div className="my-4">
        <label className="fw-bolder form-label text-capitalize">
          Number of properties
        </label>
        <input
          type="number"
          className="form-control rounded-pill py-3 px-4 fs-14 mb-3"
          value={project.propertyNumber}
          onChange={(e) => onInputChange('propertyNumber', e.target.value)}
          placeholder="Enter number of properties"
        />
      </div>

      <CustomSelect
        label="Project timeline"
        value={project.projectTimeline}
        onChange={(value) => onInputChange('projectTimeline', value)}
        options={TIMELINE_OPTIONS}
      />

      <div className="my-4">
        <label className="fw-bolder form-label text-capitalize">
          Enter Address
        </label>
        <input
          type="text"
          className="form-control rounded-pill py-3 px-4 fs-14 mb-3"
          value={project.address}
          onChange={(e) => onInputChange('address', e.target.value)}
          placeholder="Enter accurate address"
        />
      </div>

      <CustomSelect
        label="City"
        value={project.city}
        onChange={(value) => onInputChange('city', value)}
        options={CITY_OPTIONS}
      />

      <CustomSelect
        label="State"
        value={project.state}
        onChange={(value) => onInputChange('state', value)}
        options={STATE_OPTIONS}
      />
    </div>
  )
}

export default DetailsForm
