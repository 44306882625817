import React, { useState, useRef } from 'react'
import imageIcon from '../../../assets/icons/image.svg'

type ImageUploadProps = {
  title: string
  onImageUpload: (file: File) => void
}

const ImageUpload: React.FC<ImageUploadProps> = ({ title, onImageUpload }) => {
  const [previewUrl, setPreviewUrl] = useState<string | null>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleImageClick = () => {
    fileInputRef.current?.click()
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      onImageUpload(file)
      const reader = new FileReader()
      reader.onload = () => {
        setPreviewUrl(reader.result as string)
      }
      reader.readAsDataURL(file)
    }
  }

  return (
    <div className="w-full">
      <h4 className="text-uppercase fw-600 fs-12 m-1">{title}</h4>
      <div
        className="image-upload p-4"
        onClick={handleImageClick}
        style={{ cursor: 'pointer' }}
      >
        {previewUrl ? (
          <img
            src={previewUrl}
            alt="Uploaded"
            className='rounded-4'
            style={{ maxWidth: '100%', maxHeight: '100px' }}
          />
        ) : (
          <img src={imageIcon} alt="Upload" />
        )}
      </div>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        accept="image/*"
        style={{ display: 'none' }}
      />
    </div>
  )
}

export default ImageUpload
