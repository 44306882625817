import React, { useState } from 'react'
import { DeveloperDashboardLayout } from '@layouts/DeveloperDashboardLayout'
import ProjImgUpload from '../Projects/proj-img-upload'
import { createForum } from '@services/krentDeveloper'
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'

interface FileWithPreview extends File {
  preview?: string
}

interface ForumData {
  forumName: string
  forumDescription: string
  images: FileWithPreview[]
}

const AddNewForum: React.FC = () => {
  const [forumData, setForumData] = useState<ForumData>({
    forumName: '',
    forumDescription: '',
    images: [],
  })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const navigate = useNavigate()

  const handleInputChange = (field: keyof ForumData, value: string) => {
    setForumData((prev) => ({ ...prev, [field]: value }))
  }

  const handleFilesSelected = (files: FileWithPreview[]) => {
    setForumData((prev) => ({ ...prev, images: files }))
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setIsSubmitting(true)
  
    try {
      const formData = new FormData()
      formData.append('title', forumData.forumName)
      formData.append('description', forumData.forumDescription)
      forumData.images.forEach((file) => {
        formData.append(`photo`, file)
      })
  
      await createForum(formData)
      toast.success('Created forum successfully!')
      
      setTimeout(() => {
        navigate('/developer-forum')
      }, 2000)
    } catch (error) {
      console.error('Error creating forum:', error)
      toast.error('Failed to create forum. Please try again.')
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <DeveloperDashboardLayout title="Forum">
      <ToastContainer limit={1} />

      <div className="flex items-center gap-2">
        <p>Home</p>
        <i className="fa-light fa-angle-right"></i>
        <p className="">Dashboard</p>
        <i className="fa-light fa-angle-right"></i>
        <p className="">Forum</p>
        <i className="fa-light fa-angle-right"></i>
        <p className="text-primary">Add a forum</p>
      </div>
      <div className="mt-4">
        <h6>Create a new forum</h6>
        <p>
          Join the Conversation: Connect, Collaborate, and Innovate with
          Industry Experts
        </p>
        <form className="my-3" onSubmit={handleSubmit}>
          <div>
            <label className="fw-bolder form-label text-capitalize">
              Upload Forum Image
            </label>
            <ProjImgUpload
              onFilesSelected={handleFilesSelected}
              uploading={isSubmitting}
              multiple={false}
            />
          </div>
          <div className="my-4">
            <label className="fw-bolder form-label text-capitalize">
              Forum name
            </label>
            <input
              type="text"
              className="form-control rounded-pill py-3 px-4 fs-14 mb-3"
              value={forumData.forumName}
              onChange={(e) => handleInputChange('forumName', e.target.value)}
              placeholder="Choose a name for your forum"
            />
          </div>
          <div>
            <label className="fw-bolder form-label text-capitalize">
              Forum description
            </label>
            <textarea
              className="form-control form-control-0 ps-3 py-3 border"
              placeholder="Forum description"
              value={forumData.forumDescription}
              required
              rows={5}
              onChange={(e) =>
                handleInputChange('forumDescription', e.target.value)
              }
            />
          </div>
          <button
            type="submit"
            className="btn btn-primary mt-4"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Creating Forum...' : 'Create Forum'}
          </button>
        </form>
      </div>
    </DeveloperDashboardLayout>
  )
}

export default AddNewForum
