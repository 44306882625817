import React, { useCallback, useState, forwardRef, useEffect } from 'react'
import CustomSelect from '@shared/forms/select'
import { PropertyDetailsState } from '@interfaces/app.interface'

interface PropertyDetailsProps {
  propertyDetails: PropertyDetailsState
  onPropertyDetailsChange: (newDetails: Partial<PropertyDetailsState>) => void
  onValidationChange?: (isValid: boolean) => void
}

interface PropertyDetailsRef {
  validate: () => boolean
}

const PropertyDetails = forwardRef<PropertyDetailsRef, PropertyDetailsProps>(
  ({ propertyDetails, onPropertyDetailsChange, onValidationChange }, ref) => {
    const [errors, setErrors] = useState<
      Partial<Record<keyof PropertyDetailsState, string>>
    >({})
    const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false)

    const validateField = (
      field: keyof PropertyDetailsState,
      value: string,
    ): string => {
      if (!value?.toString().trim()) return 'This field is required'
      if (field.startsWith('numberOf')) {
        if (isNaN(Number(value))) return 'Must be a valid number'
        if (Number(value) < 0) return 'Must be a positive number'
      }
      return ''
    }

    const renderError = (field: keyof PropertyDetailsState) =>
      hasAttemptedSubmit &&
      errors[field] && <p className="text-red text-sm ml-2">{errors[field]}</p>

    const validateForm = useCallback((): boolean => {
      const newErrors: Partial<Record<keyof PropertyDetailsState, string>> = {}
      let isValid = true

      const requiredFields: (keyof PropertyDetailsState)[] = [
        'propertyName',
        'propertyDescription',
        'propertyType',
        'numberOfUnits',
        'numberOfAvailableUnits',
        'propertyDeedType',
        'numberOfBedrooms',
        'numberOfBathrooms',
        'numberOfFloors',
        'numberOfParkingSpaces',
      ]

      requiredFields.forEach((field) => {
        const error = validateField(field, propertyDetails[field] as string)
        if (error) {
          newErrors[field] = error
          isValid = false
        }
      })

      setErrors(newErrors)
      onValidationChange?.(isValid)
      return isValid
    }, [propertyDetails, onValidationChange])
    useEffect(() => {
      const isValid = validateForm()
      onValidationChange?.(isValid)
    }, [propertyDetails, validateForm, onValidationChange])

    useEffect(() => {
      if (hasAttemptedSubmit) {
        validateForm()
      }
    }, [propertyDetails, validateForm, hasAttemptedSubmit])

    const handleChange = (
      field: keyof PropertyDetailsState,
      value: string | string[],
    ) => {
      if (hasAttemptedSubmit) {
        const error = validateField(field, value as string)
        setErrors((prev) => ({
          ...prev,
          [field]: error,
        }))
      }
      onPropertyDetailsChange({ [field]: value })
    }

    React.useImperativeHandle(
      ref,
      () => ({
        validate: () => {
          setHasAttemptedSubmit(true)
          return validateForm()
        },
      }),
      [validateForm],
    )

    const deedTypeOptions = [
      { value: 'Cetificate of Occupancy', label: 'Cetificate of Occupancy' },
      { value: 'Deed of Assignment', label: 'Deed of Assignment' },
      { value: "Governor's consent", label: "Governor's consent" },
    ]

    const propertyTypes = [
      { value: 'duplex', label: 'Duplex' },
      { value: 'bungalow', label: 'Bungalow' },
      { value: 'flats', label: 'Flats' },
    ]

    return (
      <div>
        <div className="my-4">
          <label className="fw-bolder form-label text-capitalize">
            What is your property name
          </label>
          <input
            type="text"
            className={`form-control rounded-pill py-3 px-4 fs-14 mb-1 ${
              hasAttemptedSubmit && errors.propertyName ? 'border-red-500' : ''
            }`}
            value={propertyDetails.propertyName}
            onChange={(e) => handleChange('propertyName', e.target.value)}
            placeholder="Enter property name"
          />
          {renderError('propertyName')}
        </div>

        <div>
          <label className="fw-bolder form-label text-capitalize">
            Describe your property
          </label>
          <textarea
            className={`form-control form-control-0 ps-3 py-3 border ${
              hasAttemptedSubmit && errors.propertyDescription
                ? 'border-red-500'
                : ''
            }`}
            placeholder="Enter property description"
            value={propertyDetails.propertyDescription}
            required
            rows={5}
            onChange={(e) =>
              handleChange('propertyDescription', e.target.value)
            }
          />
          {renderError('propertyDescription')}
        </div>

        <div
          className={
            hasAttemptedSubmit && errors.propertyType ? 'border-red-500' : ''
          }
        >
          <CustomSelect
            label="What is the property type"
            value={propertyDetails.propertyType}
            onChange={(value) => handleChange('propertyType', value as string)}
            options={propertyTypes}
          />
          {renderError('propertyType')}
        </div>
        <div className=" my-2">
          {[
            { label: 'unit(s)', field: 'numberOfUnits' as const },
            {
              label: ' unit(s) available',
              field: 'numberOfAvailableUnits' as const,
            },
          ].map(({ label, field }) => (
            <div key={field} className="my-4">
              <label className="fw-bolder form-label capitalize">
                Number of property {label}
              </label>
              <input
                type="number"
                className={`form-control rounded-pill py-3 px-4 fs-14 mb-1 ${
                  hasAttemptedSubmit && errors[field] ? 'border-red-500' : ''
                }`}
                value={propertyDetails[field]}
                onChange={(e) => handleChange(field, e.target.value)}
                placeholder={`Enter number of ${label}`}
              />
              {renderError(field)}
            </div>
          ))}
        </div>

        <div
          className={
            hasAttemptedSubmit && errors.propertyDeedType
              ? 'border-red-500'
              : ''
          }
        >
          <CustomSelect
            label="Property deed type"
            value={propertyDetails.propertyDeedType}
            onChange={(value) =>
              handleChange('propertyDeedType', value as string)
            }
            options={deedTypeOptions}
          />
          {renderError('propertyDeedType')}
        </div>

        <div className="grid-4 my-2">
          {[
            { label: 'bedroom(s)', field: 'numberOfBedrooms' as const },
            { label: 'bathroom', field: 'numberOfBathrooms' as const },
            { label: 'floor(s)', field: 'numberOfFloors' as const },
            {
              label: 'parking space(s)',
              field: 'numberOfParkingSpaces' as const,
            },
          ].map(({ label, field }) => (
            <div key={field}>
              <label className="fw-bolder form-label capitalize">
                Number of {label}
              </label>
              <input
                type="number"
                className={`form-control rounded-pill py-3 px-4 fs-14 mb-1 ${
                  hasAttemptedSubmit && errors[field] ? 'border-red-500' : ''
                }`}
                value={propertyDetails[field]}
                onChange={(e) => handleChange(field, e.target.value)}
                placeholder={`Enter number of ${label}`}
              />
              {renderError(field)}
            </div>
          ))}
        </div>
      </div>
    )
  },
)

PropertyDetails.displayName = 'PropertyDetails'

export default PropertyDetails
