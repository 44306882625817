import React, { useEffect, useState } from 'react'
import { useSessionStorage } from '../../hooks'
import { createPurchaseRequest } from '@services/krentDeveloper'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
interface DocData {
  id: number
  documentTitle: string
  file: File | null
}
interface uploadReceiptModalProps {
  show: boolean
  onClose: () => void
  propertyId?: string
  item: any
  isAgentUpload?: boolean
}
const UploadReceipt: React.FC<uploadReceiptModalProps> = ({
  show,
  onClose,
  propertyId,
  item,
  isAgentUpload,
}) => {
  const [receiptFile, setReceiptFile] = useState<File | null>(null)
  const [receiptFileURL, setReceiptFileURL] = useState<string | null>(null)
  const [user] = useSessionStorage('user', {})
  const [kycDocuments, setKycDocuments] = useState<DocData[]>([
    { id: 1, documentTitle: '', file: null },
  ])
  const [isUploading, setIsUploading] = useState(false)
  const [uploadError, setUploadError] = useState<string | null>(null)

  const [clientFirstName, setClientFirstName] = useState('')
  const [phone, setPhone] = useState('')
  const [clientLastName, setClientLastName] = useState('')
  const [clientEmail, setClientEmail] = useState('')

  useEffect(() => {
    if (isAgentUpload && item?.clientName) {
      const nameParts = item.clientName.split(' ')
      setClientFirstName(nameParts[0] || '')
      setClientLastName(nameParts.slice(1).join(' ') || '')
    }
  }, [isAgentUpload, item])

  const handleReceiptFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      setReceiptFile(file)
      setReceiptFileURL(URL.createObjectURL(file))
    }
  }
  const addMoreDocuments = () => {
    setKycDocuments((prevDocs) => [
      ...prevDocs,
      { id: prevDocs.length + 1, documentTitle: '', file: null },
    ])
  }

  const handleKYCDocChange = (id: number, field: string, value: string) => {
    setKycDocuments((prevDocs) =>
      prevDocs.map((doc) => (doc.id === id ? { ...doc, [field]: value } : doc)),
    )
  }
  const handleKYCFileUpload = (
    id: number,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = e.target.files?.[0]
    if (file) {
      setKycDocuments((prevDocs) =>
        prevDocs.map((doc) => (doc.id === id ? { ...doc, file } : doc)),
      )
    }
  }

  const handleUploadAll = async () => {
    if (!receiptFile) {
      toast.error('Please select a receipt file to upload')
      return
    }

    const incompleteDocuments = kycDocuments.filter(
      (doc) => !doc.file || !doc.documentTitle,
    )

    const isKycDocumentEmpty =
      !item.kycDocument || Object.keys(item.kycDocument).length === 0

    if (isKycDocumentEmpty && incompleteDocuments.length > 0) {
      toast.error(
        'Please fill in all KYC document titles and upload files for all documents.',
      )
      return
    }

    setIsUploading(true)
    setUploadError(null)

    try {
      if (!propertyId) {
        throw new Error('Property ID is missing')
      }

      const formData = new FormData()

      formData.append('purchaseRequestReceipt', receiptFile)

      if (isAgentUpload) {
        formData.append('clientFirstName', clientFirstName)
        formData.append('clientLastName', clientLastName)
        formData.append('clientEmail', clientEmail)
        formData.append('phone', phone)
      } else {
        formData.append('clientFirstName', user.firstname)
        formData.append('clientLastName', user.lastname)
        formData.append('clientEmail', user.email)
      }

      kycDocuments.forEach((doc) => {
        if (doc.file) {
          formData.append(doc.documentTitle, doc.file)
        }
      })

      const response = await createPurchaseRequest(propertyId, formData)

      if (response?.success || response?.message) {
        toast.success(
          response.message || 'Receipt and KYC documents uploaded successfully',
        )
        setTimeout(() => {
          onClose()
        }, 2000)
      } else {
        throw new Error(response?.message || 'Upload failed')
      }
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.message ||
        error?.message ||
        error?.toString() ||
        'Failed to upload documents. Please try again.'

      setUploadError(errorMessage)
      toast.error(errorMessage)
      setIsUploading(false)
    } finally {
      setIsUploading(false)
    }
  }

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <>
          <div className="h-80 overflow-scroll">
            {isAgentUpload ? (
              <>
                <div>
                  <h6>Upload client documents</h6>
                </div>
                <div>
                  <div className="my-2">
                    <label className="fw-bolder form-label text-capitalize">
                      Client first name
                    </label>
                    <input
                      type="text"
                      className="form-control rounded-pill py-2 px-4 fs-14"
                      value={clientFirstName}
                      onChange={(e) => setClientFirstName(e.target.value)}
                      placeholder="Enter client's first name"
                    />
                  </div>
                  <div className="my-2">
                    <label className="fw-bolder form-label text-capitalize">
                      Client last name
                    </label>
                    <input
                      type="text"
                      className="form-control rounded-pill py-2 px-4 fs-14"
                      value={clientLastName}
                      onChange={(e) => setClientLastName(e.target.value)}
                      placeholder="Enter client's last name"
                    />
                  </div>
                  <div className="my-2">
                    <label className="fw-bolder form-label text-capitalize">
                      Client Email
                    </label>
                    <input
                      type="email"
                      className="form-control rounded-pill py-2 px-4 fs-14"
                      value={clientEmail}
                      onChange={(e) => setClientEmail(e.target.value)}
                      placeholder="Enter client's email"
                    />
                  </div>
                  <div className="my-2">
                    <label className="fw-bolder form-label text-capitalize">
                      Client Phone Number
                    </label>
                    <input
                      type="number"
                      className="form-control rounded-pill py-2 px-4 fs-14"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      placeholder="Enter client's phone number"
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div>
                  <h6>Please upload proof of payment document</h6>
                  <p>
                    Uploading this will prevent scams and provide you a sense of
                    security to be able to ensure follow up if required
                  </p>
                </div>
                <div className="mt-4">
                  <h6>
                    If you haven't made payments, please proceed to make payment
                  </h6>
                  <p>
                    Since you did not make payment offline, kindly make the
                    transfer to this details and upload the proof of funds
                  </p>
                  <div className="my-3">
                    <p className="fs-16">
                      <span className="fw-bold">Acc name:&nbsp;</span>
                      Krent space
                    </p>
                    <p className="fs-16">
                      <span className="fw-bold">Bank name:&nbsp;</span>
                      Wema bank
                    </p>
                    <p className="fs-16">
                      <span className="fw-bold">Acc number:&nbsp;</span>
                      0123456789
                    </p>
                  </div>
                </div>
              </>
            )}

            <div className="w-full gap-4 my-4">
              {item?.purchaseRequestReceipt ? (
                <div className="my-4">
                  <h6>Previously uploaded Receipt</h6>
                  <div>
                    <div className="flex items-center gap-4 my-2">
                      <a
                        download
                        href={item?.purchaseRequestReceipt}
                        className="border-green rounded-10 p-2 text-primary w-full flex justify-between"
                      >
                        <p>
                          {item?.purchaseRequestReceipt.length > 20
                            ? `${item?.purchaseRequestReceipt.slice(0, 20)}...`
                            : item?.purchaseRequestReceipt}
                        </p>
                        <i className="fa-regular fa-download text-primary p-1"></i>
                      </a>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="my-4">
                    <label
                      htmlFor="file-upload"
                      className="text-secondary border px-3 rounded-10 fw-bold cursor-pointer w-full"
                    >
                      {isAgentUpload
                        ? 'Upload client receipt'
                        : ' Click to upload your receipt'}
                    </label>

                    <input
                      type="file"
                      id="file-upload"
                      className="hidden"
                      onChange={handleReceiptFileUpload}
                    />
                  </div>
                  <hr />
                  {receiptFileURL && (
                    <div className="text-center my-4">
                      {receiptFile?.type.startsWith('image/') && (
                        <img
                          src={receiptFileURL}
                          alt="Uploaded File"
                          className="w-full max-h-64 object-contain"
                        />
                      )}

                      {receiptFile?.type === 'application/pdf' && (
                        <embed
                          src={receiptFileURL}
                          type="application/pdf"
                          width="100%"
                          height="400px"
                        />
                      )}

                      {!receiptFile?.type.startsWith('image/') &&
                        receiptFile?.type !== 'application/pdf' && (
                          <p className="text-secondary">
                            Uploaded Document:{' '}
                            <a
                              href={receiptFileURL}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {receiptFile?.name}
                            </a>
                          </p>
                        )}
                    </div>
                  )}
                </>
              )}

              <div>
                {item?.kycDocument && Object.keys(item.kycDocument).length > 0 && (
                  <div className="my-4">
                    <h6>Previously uploaded documents</h6>
                    <div>
                      {Object.entries(item.kycDocument).map(([key, value]) => (
                        <div key={key} className="flex items-center gap-4 my-2">
                          <h6 className="capitalize fs-14 w-30">{key}:</h6>
                          <a
                            download
                            href={String(value)}
                            className="border-green rounded-10 p-2 text-primary w-full flex justify-between"
                          >
                            <p>
                              {String(value).length > 20
                                ? `${String(value).slice(0, 20)}...`
                                : String(value)}
                            </p>
                            <i className="fa-regular fa-download text-primary p-1"></i>
                          </a>
                        </div>
                      ))}
                    </div>
                    <h6>Have more KYC documents?</h6>
                  </div>
                )}
                {isAgentUpload ? (
                  <div>
                    <h6>Upload client KYC documents</h6>
                  </div>
                ) : (
                  <div>
                    <h6>Please upload your KYC documents</h6>
                    <p>
                      Uploading this will prevent scams and provide you a sense
                      of security to be able to ensure follow up if required
                    </p>
                  </div>
                )}

                <div className="w-full gap-4 my-4">
                  {kycDocuments.map((doc) => (
                    <div key={doc.id} className="my-4">
                      <div className="my-2">
                        <label className="fw-bolder form-label text-capitalize">
                          Document Title
                        </label>
                        <input
                          type="text"
                          className="form-control rounded-pill py-2 px-4 fs-14"
                          value={doc.documentTitle}
                          onChange={(e) =>
                            handleKYCDocChange(
                              doc.id,
                              'documentTitle',
                              e.target.value,
                            )
                          }
                          placeholder="Enter document Title"
                        />
                      </div>

                      <div className="my-2">
                        {doc.file ? (
                          <p className="text-secondary border px-3 rounded-10 fw-bold">
                            Uploaded: {doc.file.name}
                          </p>
                        ) : (
                          <>
                            <label
                              htmlFor={`file-upload-${doc.id}`}
                              className="text-secondary border px-3 rounded-10 fw-bold cursor-pointer w-full"
                            >
                              {isAgentUpload
                                ? 'Upload KYC document'
                                : 'Click to upload your KYC document'}
                            </label>
                            <input
                              type="file"
                              id={`file-upload-${doc.id}`}
                              className="hidden"
                              onChange={(e) => handleKYCFileUpload(doc.id, e)}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  ))}

                  <button
                    className="fw-bold text-primary my-4 border-green bg-white rounded-1 px-2"
                    onClick={addMoreDocuments}
                    disabled={isUploading}
                  >
                    + Add More Document
                  </button>
                </div>
              </div>

              {uploadError && <p className="text-danger">{uploadError}</p>}
            </div>
          </div>
          <button
            onClick={handleUploadAll}
            className="bg-primary rounded-10 p-2 text-white fw-bold w-full text-center cursor-pointer"
            disabled={isUploading}
          >
            {isUploading ? 'Uploading...' : 'Upload and submit'}
          </button>
        </>
      </Modal.Body>
    </Modal>
  )
}

export default UploadReceipt
