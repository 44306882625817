import React, { useState } from 'react'
import image from '../../../../assets/images/profile-pic.png'
import { createPostinForum } from '@services/krentDeveloper'
import { useSessionStorage } from '../../../../hooks'
import { formatDateToTime } from '@utils/helpers'

interface Post {
  _id: string
  content: string
  createdAt: string
  createdBy: {
    firstname: string
    email: string
  }
}

interface ForumMainMenuProps {
  posts: Post[]
  forumId: string | null
  fetchPosts: () => void
  selectedForum: any
}

const ForumMainMenu: React.FC<ForumMainMenuProps> = ({
  posts,
  forumId,
  fetchPosts,
  selectedForum,
}) => {
  const [chatMessage, setChatMessage] = useState('')
  const [user] = useSessionStorage('user', {})
  const email = user.email

  const handleSendMessage = async (e: React.FormEvent) => {
    e.preventDefault()

    if (forumId && chatMessage) {
      const payload = { content: chatMessage }
      try {
        await createPostinForum(forumId, new AbortController().signal, payload)
        console.log('Message sent:', chatMessage)
        setChatMessage('') // Clear the input after sending
        fetchPosts() // Refetch posts after sending
      } catch (err) {
        console.error('Error sending message:', err)
      }
    }
  }

  return (
    <div className="h-100 d-flex flex-column">
      {/* Forum Header */}
      <div className="d-flex align-items-center mb-3">
        <img
          src={image}
          alt="Avatar"
          className="rounded-circle me-3"
          style={{ 
            width: '55px', 
            height: '55px', 
            objectFit: 'cover' 
          }}
        />
        <div>
          <h6 className="mb-1">{selectedForum?.title}</h6>
          <p className="text-muted mb-0">{selectedForum?.description}</p>
        </div>
      </div>

      {/* Forum Posts */}
      <div className="flex-grow-1 position-relative border">
        <div className="overflow-auto  rounded p-3" style={{ maxHeight: 'calc(100% - 60px)', marginBottom: '60px' }}>
          {posts.map((post) => (
            <div 
              key={post._id} 
              className={`d-flex mb-3 ${
                post.createdBy.email === email ? 'justify-content-end' : ''
              }`}
            >
              {post.createdBy.email === email ? (
                <>
                  <div className="text-end me-2">
                    <div className="bg-primary text-white p-2 rounded">
                      <small className="d-block text-capitalize">
                        {post.createdBy.firstname.toLowerCase()}
                      </small>
                      <p className="mb-1">{post.content}</p>
                    </div>
                    <small className="text-muted">
                      {formatDateToTime(post.createdAt)}
                    </small>
                  </div>
                  <img
                    src={selectedForum.coverImage || image}
                    alt="Avatar"
                    className="rounded-circle"
                    style={{ 
                      width: '40px', 
                      height: '40px', 
                      objectFit: 'cover' 
                    }}
                  />
                </>
              ) : (
                <>
                  <img
                    src={image}
                    alt="Avatar"
                    className="rounded-circle me-2"
                    style={{ 
                      width: '40px', 
                      height: '40px', 
                      objectFit: 'cover' 
                    }}
                  />
                  <div>
                    <div className="bg-light p-2 rounded">
                      <small className="d-block text-primary text-capitalize">
                        {post.createdBy.firstname.toLowerCase()}
                      </small>
                      <p className="mb-1">{post.content}</p>
                    </div>
                    <small className="text-muted">
                      {formatDateToTime(post.createdAt)}
                    </small>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>

        <div className="position-absolute bottom-0 start-0 end-0 p-3 bg-white border-top">
          <form onSubmit={handleSendMessage}>
            <div className="input-group">
              <input
                type="text"
                value={chatMessage}
                onChange={(e) => setChatMessage(e.target.value)}
                className="form-control"
                placeholder="Enter a message"
              />
              <button
                className="btn btn-primary d-flex align-items-center"
                type="submit"
              >
                Send
                <i className="fas fa-paper-plane ms-2"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ForumMainMenu