import { useSessionStorage } from '../../hooks'
import { createPurchaseRequest } from '@services/krentDeveloper'
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

type Step = 'KYC' | 'Payment' | 'Upload' | 'Success'

interface MultiStepPaymentModalProps {
  show: boolean
  onClose: () => void
  propertyId?: string
}

interface StepIndicatorProps {
  currentStep: number
  totalSteps: number
}

const StepIndicator: React.FC<StepIndicatorProps> = ({
  currentStep,
  totalSteps,
}) => {
  return (
    <div
      className="stepper-container"
      style={
        {
          '--total-steps': totalSteps,
          '--current-step': currentStep,
        } as React.CSSProperties
      }
    >
      {Array.from({ length: totalSteps }, (_, index) => (
        <div
          key={index}
          className={`step-item ${index + 1 <= currentStep ? 'active' : ''}`}
        >
          <div className="step-number">{index + 1}</div>
          {index < totalSteps - 1 && <div className="step-line"></div>}
        </div>
      ))}
    </div>
  )
}

const MultiStepPaymentModal: React.FC<MultiStepPaymentModalProps> = ({
  show,
  onClose,
  propertyId,
}) => {
  const [currentStep, setCurrentStep] = useState<Step>('KYC')
  const [madePayment, setMadePayment] = useState<boolean | null>(null)
  const navigate = useNavigate()
  const totalSteps = 3

  const [uploadedFile, setUploadedFile] = useState<File | null>(null)
  const [fileURL, setFileURL] = useState<string | null>(null)
  const [user] = useSessionStorage('user', {})

  const clientFirstName = user.firstname
  const clientLastName = user.lastname
  const clientEmail = user.email

  const handleFileUpload = (e: any) => {
    const file = e.target.files[0]
    if (file) {
      console.log(file, 'file')
      setUploadedFile(file)
      setFileURL(URL.createObjectURL(file))
    }
  }

  const handleUploadProof = async () => {
    if (!uploadedFile) {
      alert('Please select a file to upload')
      return
    }

    const formData = new FormData()
    formData.append('purchaseRequestReceipt', uploadedFile)
    formData.append('clientFirstName', clientFirstName)
    formData.append('clientLastName', clientLastName)
    formData.append('clientEmail', clientEmail)

    try {
      const response = await createPurchaseRequest(propertyId || '', formData)
      console.log('Purchase request created:', response)
      handleNextStep()
    } catch (error: any) {
      console.error('Error creating purchase request:', error)
      toast.error(error?.error || 'Error')
    }
  }

  const handleAddKYC = () => {
    navigate('/settings/kyc')
    onClose()
  }

  const handleNextStep = () => {
    switch (currentStep) {
      case 'KYC':
        setCurrentStep('Payment')
        break
      case 'Payment':
        setCurrentStep('Upload')
        break
      case 'Upload':
        setCurrentStep('Success')
        break
      default:
        onClose()
    }
  }

  const getCurrentStepNumber = () => {
    switch (currentStep) {
      case 'KYC':
        return 1
      case 'Payment':
        return 2
      case 'Upload':
        return 3
      case 'Success':
        return 3
      default:
        return 1
    }
  }

  const renderStep = () => {
    switch (currentStep) {
      case 'KYC':
        return (
          <div>
            <div className="flex items-center gap-2">
              <i className="fa-user fa-regular"></i>
              <h5 className="fs-14">Did you fill the KYC document offline?</h5>
            </div>
            <p>
              <span className="fw-bold">KYC (Know Your Customer)</span> is a
              process to verify your identity to ensure security and compliance
              with regulations.
            </p>
            <div className="w-full flex gap-4 my-4">
              <button
                className="bg-primary rounded-10 p-2 text-white fw-bold w-full"
                onClick={handleNextStep}
              >
                Yes, upload receipt
              </button>
              <button
                className="bg-white border-green text-primary rounded-10 p-2 w-full text-white fw-bold"
                onClick={handleAddKYC}
              >
                No, add KYC
              </button>
            </div>
          </div>
        )
      case 'Payment':
        return (
          <div>
            <h6>Did you make payments yet?</h6>
            <div className="w-full flex gap-4 my-4">
              <button
                className="bg-primary rounded-10 p-2 text-white fw-bold w-full"
                onClick={() => {
                  setMadePayment(true)
                  handleNextStep()
                }}
              >
                Yes
              </button>
              <button
                className="bg-white border-green text-primary rounded-10 p-2 w-full text-white fw-bold"
                onClick={() => {
                  setMadePayment(false)
                  handleNextStep()
                }}
              >
                No
              </button>
            </div>
          </div>
        )
      case 'Upload':
        return (
          <div>
            {madePayment ? (
              <div>
                <h6>Please upload proof of payment document</h6>
                <p>
                  Uploading this will prevent scams and provide you a sense of
                  security to be able to ensure follow up if required
                </p>
              </div>
            ) : (
              <div>
                <h6>Please proceed to make payment</h6>
                <p>
                  Since you did not make payment offline, kindly make the
                  transfer to this details and upload the proof of funds
                </p>
                <div className="my-3">
                  <p className="fs-16">
                    <span className="fw-bold">Acc name:&nbsp;</span>
                    Krent space
                  </p>
                  <p className="fs-16">
                    <span className="fw-bold">Bank name:&nbsp;</span>
                    Wema bank
                  </p>
                  <p className="fs-16">
                    <span className="fw-bold">Acc number:&nbsp;</span>
                    0123456789
                  </p>
                </div>
              </div>
            )}
            <div className="w-full gap-4 my-4">
              <div className="my-4 text-center">
                <label
                  htmlFor="file-upload"
                  className="text-secondary border px-3 rounded-10 fw-bold cursor-pointer"
                >
                  Click to upload your document
                </label>

                <input
                  type="file"
                  id="file-upload"
                  className="hidden"
                  onChange={handleFileUpload}
                />
              </div>

              {fileURL && (
                <div className="text-center my-4">
                  {uploadedFile?.type.startsWith('image/') && (
                    <img
                      src={fileURL}
                      alt="Uploaded File"
                      className="w-full max-h-64 object-contain"
                    />
                  )}

                  {uploadedFile?.type === 'application/pdf' && (
                    <embed
                      src={fileURL}
                      type="application/pdf"
                      width="100%"
                      height="400px"
                    />
                  )}

                  {!uploadedFile?.type.startsWith('image/') &&
                    uploadedFile?.type !== 'application/pdf' && (
                      <p className="text-secondary">
                        Uploaded Document:{' '}
                        <a
                          href={fileURL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {uploadedFile?.name}
                        </a>
                      </p>
                    )}
                </div>
              )}

              <button
                onClick={handleUploadProof}
                className="bg-primary rounded-10 p-2 text-white fw-bold w-full text-center cursor-pointer"
              >
                Upload and submit
              </button>
            </div>
          </div>
        )
      case 'Success':
        return (
          <div className="text-center">
            <svg
              width="140"
              height="112"
              viewBox="0 0 140 112"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              {/* SVG path data */}
            </svg>
            <h6 className="my-4">Document uploaded successfully!</h6>
          </div>
        )
    }
  }

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        {/* <Modal.Title>Payment Process</Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        {currentStep !== 'Success' && (
          <StepIndicator
            currentStep={getCurrentStepNumber()}
            totalSteps={totalSteps}
          />
        )}
        {renderStep()}
      </Modal.Body>
    </Modal>
  )
}

export default MultiStepPaymentModal
