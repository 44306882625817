import React, { useCallback, useEffect, useState } from 'react'
import AllProjects from '../Projects/all-projects'
import {
  DeveloperProjectsResponse,
  DevProject,
} from '@interfaces/app.interface'
import { getAllDeveloperProjects } from '@services/krentDeveloper'
import { useNavigate } from 'react-router-dom'
import { useSessionStorage } from '../../../hooks'

const FeaturedProjects = () => {
  const [projects, setProjects] = useState<DevProject[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const navigate = useNavigate()
  const [user] = useSessionStorage('user', {});

  const fetchProjects = useCallback(async () => {
    setLoading(true)
    setError(null)
    const abortController = new AbortController()

    try {
      const response: DeveloperProjectsResponse = await getAllDeveloperProjects(
        user.id,
        // abortController.signal,
      )

      if (Array.isArray(response.data)) {
        setProjects(response.data.slice(0, 3))
      } else {
        throw new Error('Unexpected response format')
      }
    } catch (error) {
      setError('Failed to fetch projects. Please try again.')
    } finally {
      setLoading(false)
    }

    return () => {
      abortController.abort()
    }
  }, [])

  const handleViewAllProjects = () => {
    navigate('/developer-projects')
  }

  useEffect(() => {
    fetchProjects()
  }, [fetchProjects])

  return (
    <div>
      <div className="flex items-center justify-between ">
        <h6>FEATURED PROJECTS</h6>
        <button
          className="fw-bold text-white bg-primary rounded-10 p-2 px-3 flex gap-2 items-center"
          onClick={handleViewAllProjects}
        >
          <i className="fa-sharp-duotone fa-light fa-eye"></i>
          View all projects
        </button>
      </div>
      <div className="my-10">
        {loading && <p>Loading...</p>}
        {error && <p className="text-red-500">{error}</p>}
        {!loading && !error && (
          <AllProjects projects={projects} pagination={false} />
        )}
      </div>
    </div>
  )
}

export default FeaturedProjects
