import React from 'react'

const PropertyFeatures = ({
  title,
  icon,
  items,
}: {
  title: string
  icon: string
  items: string[]
}) => (
  <ul className="p-0">
    <div className="flex items-center mb-3 gap-2">
      <img src={icon} alt={`${title} icon`} />
      <h6>{title}</h6>
    </div>
    {items.map((item, idx) => (
      <li key={idx} className="my-2">
        <i className="fa fa-check text-white bg-primary px-1 py-1 rounded-full fa-solid"></i>
        &nbsp;
        <span className="capitalize">{item}</span>
      </li>
    ))}
  </ul>
)

export default PropertyFeatures
