type SelectProps = {
  label?: string
  options: { value: string; label: string }[]
  icon?: string
  iconType?: 'iconly' | 'fa'
  value?: string
  change: (e: React.ChangeEvent<HTMLSelectElement>) => void
  required?: boolean
}

export const SelectDropdown: React.FC<SelectProps> = ({
  label,
  options,
  icon = 'ChevronDown',
  iconType = 'iconly',
  value,
  change,
  required = false,
}: SelectProps) => {
  return (
    <div className="form-group m-1">
      <label className="text-uppercase fw-600 fs-12 m-1" htmlFor="dropdown">
        {label}
      </label>

      <div className="input-group">
        <span className="input-group-text input-group-text-0" id="basic-addon1">
          {iconType === 'iconly' ? (
            <i className={`iconly-${icon} icli fs-4`}></i>
          ) : (
            <i className={`fa-light fa-${icon} fs-5`}></i>
          )}
        </span>

        <select
          id="dropdown"
          className="form-control form-control-0 ps-3 py-3 border-start-0"
          value={value}
          onChange={change}
          required={required}
        >
          <option value="" disabled>
            Select an option
          </option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}
