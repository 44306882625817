import { DashboardLayout } from '@layouts/DashboardLayout'
import Tabs from '@shared/Tabs'
import Interests from 'components/Developer/Purchases/interests'
import PurchasedInterests from 'components/Developer/Purchases/purchases'
import React, { useEffect } from 'react'

const Purchases = () => {
  const purchasesComponents = {
    Interests: <Interests />,
    Purchased: <PurchasedInterests />,
  }
  useEffect(() => {
    localStorage.removeItem('InterestIdReroute')
    localStorage.removeItem('agentId')
  }, [])
  return (
    <DashboardLayout>
      <div>
        <div className="row mb-3">
          <h3 className="fw-bold mb-1">Purchases</h3>
          <p className="text-secondary fs-14">View your purchases on krent</p>
        </div>
        <div className="my-10">
          <Tabs components={purchasesComponents} roundedTabs />
        </div>
      </div>
    </DashboardLayout>
  )
}

export default Purchases
 