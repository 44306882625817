import { numberWithCommas } from "./helpers";

import dashboardIcon from "../../src/assets/icons/dashboard.svg";
import projectIcon from "../../src/assets/icons/project.svg";
import salesIcon from "../../src/assets/icons/sales.svg";
import forumIcon from "../../src/assets/icons/forum.svg";
import profileIcon from "../../src/assets/icons/profile.svg";
import settingsIcon from "../../src/assets/icons/settings.svg";
import graphIcon from "../../src/assets/icons/graph.svg";
import { DevProject } from "@interfaces/app.interface";

type Cycle = {
  [key: string]: string;
};
export interface Project {
  id: number;
  name?: string;
  status: string;
  address?: string;
  subgroups: string[];
}
export interface DevSingleProjectResponse {
  success: boolean;
  data: DevSingleProject;
}
export interface DevSingleProject {
  id: number;
  projectName: string;
  projectTimeline?: string;
  address?: string;
  projectPropertiesType?: string[];
  description?: string;
  properties?: string;
  projectSize?: string;
}
export interface ProjectCardProps {
  project: DevProject;
  client?: boolean
}
export interface ProjectCardsProps {
  projects: DevProject[];
  pagination?: boolean;
}
export interface Property {
  _id: string;
  propertyName?: string;
  propertyDeedType: string;
  address?: string;
  subgroups: string[];
  coverImage?: string;
  numberOfUnits?: string;
  otherImages?: string[];
  price?: string;
  currency?: string;
  numberOfBathrooms?: number;
  numberOfBedrooms?: number;
  location?: {
    coordinates: [number, number];
  };
  utilitiesAndAmenities?: string[];
  entertainment?: string[];
  kitchen?: string[];
  safety?: string[];
  others?: string[];
  propertyType?: string;
  project?: string;
  propertyDescription?: string;
}
export interface Agent {
  firstname: string;
  lastname: string;
  id?: string;
}
export interface PropertyInterest {
  _id?: string;
  id: string;
  type: string;
  name: string;
  status: string;
  paymentStatus?: string;
  property: {
    currency?: string;
    price?: string;
    propertyDeedType?: string;
    numberOfBedrooms?: number;
    numberOfBathrooms?: number;
    _id?: string;
  };
  createdAt?: string;
}
export interface PropertyCardProps {
  property: Property;
  client?: boolean
}
export interface PropertyCardsProps {
  property: Property[];
}
interface DevNavigationLink {
  name: string;
  icon: any;
  route?: string;
}
type MetricsType = {
  name: string;
  icon: any;
  color?: string;
  count?: number;
};

// type ProjectType = {
//   id: number;
//   name: string;
//   projectTimeline: string;
//   address?: string;
//   subgroups: string[];
// };
export const CycleToInterval = (cycle: string): string => {
  const cToI: Cycle = {
    yearly: "year",
    monthly: "month",
    weekly: "week",
    daily: "day",
  };
  return cToI[cycle];
};

export const ROLES = {
  LANDLORD: "landlord",
  USER: "user",
  AGENT: "agent",
};
export const DevNavigationLinks: DevNavigationLink[] = [
  {
    name: "Dashboard",
    icon: dashboardIcon,
    route: "/developer-dashboard",
  },
  {
    name: "Projects",
    icon: projectIcon,
    route: "/developer-projects",
  },
  {
    name: "Sales",
    icon: salesIcon,
    route: "/developer-sales",
  },
  {
    name: "Forum",
    icon: forumIcon,
    route: "/developer-forum",
  },
  {
    name: "Profile",
    icon: profileIcon,
    route: "/developer-profile",
  },
  {
    name: "Settings",
    icon: settingsIcon,
    route: "/developer-settings",
  },
];
export const MetricsInfo: MetricsType[] = [
  { name: "customers", icon: forumIcon, count: 0 },
  { name: "listings", icon: projectIcon, count: 0, color: "red" },
  { name: "sales", icon: graphIcon, count: 0 },
  { name: "revenue", icon: salesIcon, count: 0 },
];
export const ProjectSample: DevProject[] = [
  {
    _id: 1,
    projectName: "Yeet complex",
    projectTimeline: "previous",
    address: "Lekki Phase 1, Lekki, Lagos",
    projectPropertiesType: ["duplex", "town houses", "flats"],
    projectSize: "1000 sqft",
  },
  {
    _id: 2,
    projectName: "Yeet complex",
    projectTimeline: "current",
    address: "Lekki Phase 1, Lekki, Lagos",
    projectPropertiesType: ["penthouses", "apartments"],
    projectSize: "1000 sqft",
  },
  {
    _id: 3,
    projectName: "Yeet complex",
    projectTimeline: "future",
    address: "Lekki Phase 1, Lekki, Lagos",
    projectPropertiesType: ["duplex", "town houses", "flats"],
    projectSize: "1000 sqft",
  },
  {
    _id: 4,
    projectName: "Yeet complex",
    projectTimeline: "previous",
    address: "Lekki Phase 1, Lekki, Lagos",
    projectPropertiesType: ["duplex", "town houses", "flats"],
    projectSize: "1000 sqft",
  },
  {
    _id: 5,
    projectName: "Yeet complex",
    projectTimeline: "current",
    address: "Lekki Phase 1, Lekki, Lagos",
    projectPropertiesType: ["penthouses", "apartments"],
    projectSize: "1000 sqft",
  },
  {
    _id: 6,
    projectName: "Yeet complex",
    projectTimeline: "future",
    address: "Lekki Phase 1, Lekki, Lagos",
    projectPropertiesType: ["duplex", "town houses", "flats"],
    projectSize: "1000 sqft",
  },
];
export const PropertySample: Property[] = [
  {
    _id: "1",
    propertyName: "Yeet complex",
    propertyDeedType: "shortlet",
    address: "Lekki Phase 1, Lekki, Lagos",
    subgroups: ["duplex", "town houses", "flats"],
  },
  {
    _id: "2",
    propertyName: "Yeet complex",
    propertyDeedType: "rent",
    address: "Lekki Phase 1, Lekki, Lagos",
    subgroups: ["penthouses", "apartments"],
  },
  {
    _id: "3",
    propertyName: "Yeet complex",
    propertyDeedType: "sale",
    address: "Lekki Phase 1, Lekki, Lagos",
    subgroups: ["duplex", "town houses", "flats"],
  },
];

export const sold_props_data = [
  {
    header: "S/N",
    accessor: "id",
    Cell: ({ row }: { row: any }) => row.index + 1,
  },

  {
    header: "Property name",
    accessor: "propertyName",
    Cell: ({ value }: any) => value,
  },
  {
    header: "Property Amount (₦)",
    accessor: "propertyAmount",
    Cell: ({ value }: any) => numberWithCommas(value),
  },
  {
    header: "Status",
    accessor: "status",
  },
];

export const recent_requests_data = [
  {
    header: "Request date",
    accessor: "requestDate",
    Cell: ({ value }: any) => value,
  },

  {
    header: "Client name",
    accessor: "clientName",
    Cell: ({ value }: any) => value,
  },
  {
    header: "Property Name",
    accessor: "propertyName",
    Cell: ({ value }: any) => value,
  },
  {
    header: "Property Category",
    accessor: "propertyCategory",
    Cell: ({ value }: any) => value,
  },
  {
    header: "Status",
    accessor: "status",
  },
  {
    header: "Action",
    accessor: "action",
  },
  {
    header: "",
    accessor: "details",
  },
];
export const soldpropsinfo = [
  {
    name: "Yeat apartment",
    amount: "5,000,000",
    location: "Ojo, Lagos State",
    type: "state",
    cutoff: "200",
    slots: "198",
    icon: "unilag",
    quota: 450,
    score: 40,
    index: 1,
  },
];
export type Option = {
  value: string;
  label: string;
};

export const STATUS_OPTIONS: Option[] = [
  { value: "ongoing", label: "Ongoing" },
  { value: "completed", label: "Completed" },
  { value: "planned", label: "Planned" },
];

export const TIMELINE_OPTIONS: Option[] = [
  { value: "previous", label: "Previous" },
  { value: "future", label: "Future" },
  { value: "current", label: "Current" },
];

export const CITY_OPTIONS: Option[] = [
  { value: "lag", label: "Lagos" },
  { value: "ogun", label: "Ogun" },
  { value: "ib", label: "Ibadan" },
];

export const STATE_OPTIONS: Option[] = [
  { value: "abj", label: "Abuja" },
  { value: "lag", label: "Lagos" },
  { value: "ph", label: "Port Harcourt" },
];

export const PROPERTY_TYPES: Option[] = [
  { value: "duplex", label: "Duplex" },
  { value: "bungalow", label: "Bungalow" },
  { value: "flats", label: "Flats" },
];
export const navItems = [
  { title: 'Home', url: '/staycation' },
  { title: 'Packages', url: '/staycation-packages' },
  { title: 'Contact', url: '/staycation-contact' },
]