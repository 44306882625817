import React, { useEffect, useState } from 'react'
import buildingImg from '../../../assets/images/building.png'
import DevelopersPagination from '@shared/navbars/DevelopersNavbar/Pagination'
import { getDeveloperShownInterestProperties } from '@services/krentDeveloper'
import { formatDate, numberWithCommas } from '@utils/helpers'
import { PropertyInterest } from '@utils/constants'
import { useNavigate } from 'react-router-dom'

const ITEMS_PER_PAGE = 50

const SalesInterest: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [shownInterests, setShownInterests] = useState<PropertyInterest[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)
  const navigate = useNavigate()

  useEffect(() => {
    const fetchInterests = async () => {
      setIsLoading(true)
      setError(null)
      try {
        const abortController = new AbortController()
        const response = await getDeveloperShownInterestProperties(
          abortController.signal,
        )
        setShownInterests(response.data)
        setTotalPages(Math.ceil(response.data.length / ITEMS_PER_PAGE))
      } catch (err) {
        setError(
          err instanceof Error ? err : new Error('An unknown error occurred'),
        )
      } finally {
        setIsLoading(false)
      }
    }

    fetchInterests()
  }, [])

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage)
  }
  const handleViewInterestDetails = (propertyId: string) => {
    navigate(`/dev-interest-details/${propertyId}`, {
      state: { shownInterest: true },
    })
  }
  if (isLoading) return <div>Loading...</div>
  if (error) return <div>Error: {error.message}</div>

  return (
    <>
      {shownInterests.length > 0 ? (
        <div>
          <div className="my-10">
            {shownInterests.map((item, idx) => (
              <div
                key={item.id}
                className="sale-interest-card flex justify-between"
                onClick={() =>
                  handleViewInterestDetails(item.property._id || '')
                }
              >
                <div className="flex gap-4">
                  <h6>{idx + 1}.</h6>
                  <div>
                    <h6 className="fs-12">Initiation date</h6>
                    <p>{formatDate(item?.createdAt || '')}</p>
                  </div>
                  <img src={buildingImg} className="" alt={'building'} />
                  <div>
                    <p
                      className={`fw-bold w-max rounded-50 px-3 fs-12 capitalize ${
                        item.type === 'shortlet'
                          ? 'previous'
                          : item.type === 'rent'
                          ? 'current'
                          : 'future'
                      }`}
                    >
                      {item.property.propertyDeedType}
                    </p>
                    <p>{item.name}</p>
                    {item.property.price && (
                      <h6>
                        {item.property.currency}&nbsp;
                        {numberWithCommas(item.property.price)}
                      </h6>
                    )}

                    <div className="flex gap-4">
                      <div className="flex gap-2 items-center">
                        <i className="fa-regular fa-bed-front text-primary"></i>
                        <h6>{item.property.numberOfBedrooms}</h6>
                      </div>
                      <div className="flex gap-2 items-center">
                        <i className="fa-regular fa-bath text-primary"></i>
                        <h6>{item.property.numberOfBathrooms}</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <h6 className="future fs-12 w-max px-2">
                    REQUEST {item.paymentStatus?.toUpperCase()}
                  </h6>
                  <h6>Purchase request has been made for this property</h6>
                </div>
                <div>
                  <i className="fa-regular fa-ellipsis text-primary rounded-circle p-1 border-green"></i>
                </div>
              </div>
            ))}
          </div>
          <DevelopersPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      ) : (
        <div className="text-center">
          <h6 className="pt-4">No Shown Interests Yet!</h6>
        </div>
      )}
    </>
  )
}

export default SalesInterest
