import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DeveloperDashboardLayout } from '@layouts/DeveloperDashboardLayout'
import FeaturedProjects from './featured-projects'
import Ratings from './ratings'
import image from '../../../assets/images/profile-pic.png'
import { getDeveloperProfileInfo } from '@services/krentDeveloper'
import { DevProfile } from 'types'

const DevelopersProfile: React.FC = () => {
  const navigate = useNavigate()
  const [profileInfo, setProfileInfo] = useState<DevProfile | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const controller = new AbortController()

    const fetchProfileInfo = async () => {
      try {
        const response: DevProfile = await getDeveloperProfileInfo(
          controller.signal,
        )
        setProfileInfo(response.data)
      } catch (err) {
        setError(
          err instanceof Error ? err.message : 'An unknown error occurred',
        )
      } finally {
        setIsLoading(false)
      }
    }

    fetchProfileInfo()

    return () => controller.abort()
  }, [])

  const handleEditProfileDetails = () => {
    navigate('/developer-profile/edit')
  }

  if (isLoading) return <div>Loading...</div>
  if (error) return <div>Error: {error}</div>

  return (
    <DeveloperDashboardLayout title="My Profile">
      <div>
        <Breadcrumb />
        <ProfileActions onEdit={handleEditProfileDetails} />
        <ProfileInfo profileInfo={profileInfo} />
        <hr />
        <FeaturedProjects />
        <hr />
        {profileInfo?._id && <Ratings id={profileInfo._id} />}
      </div>
    </DeveloperDashboardLayout>
  )
}

const Breadcrumb: React.FC = () => (
  <div className="flex items-center gap-2">
    <p>Home</p>
    <i className="fa-light fa-angle-right"></i>
    <p>Dashboard</p>
    <i className="fa-light fa-angle-right"></i>
    <p className="text-primary">Profile</p>
  </div>
)

const ProfileActions: React.FC<{ onEdit: () => void }> = ({ onEdit }) => (
  <div className="w-max ml-auto flex gap-3 my-4">
    <button
      className="fw-bold text-white bg-primary rounded-10 p-2 px-3 flex gap-2 items-center"
      onClick={onEdit}
    >
      <i className="fa-sharp-duotone fa-solid fa-pen-field"></i>
      Edit profile
    </button>
  </div>
)

interface ProfileInfoProps {
  profileInfo: DevProfile | null
}

const ProfileInfo: React.FC<ProfileInfoProps> = ({ profileInfo }) => (
  <div className="mt-4">
    <div className="flex gap-20 items-start">
      <div>
        <img
          src={image}
          alt="Avatar"
          style={{ objectFit: 'cover' }}
          height={100}
          width={100}
          className="rounded-circle"
        />
      </div>
      <div className="w-full">
        <ProfileField
          label="Developer name"
          value={
            profileInfo?.firstname && profileInfo?.lastname
              ? `${profileInfo.firstname.toLowerCase()} ${profileInfo.lastname.toLowerCase()}`
              : 'N/A'
          }
          className="uppercase"
        />
        <ProfileField label="Company name" value={profileInfo?.companyName} />
        <ProfileField label="Tax ID number" value={profileInfo?.taxIdNumber} />
        <ProfileField
          label="Bio (Company details)"
          value={profileInfo?.bio}
          className="w-70"
        />
      </div>
    </div>
  </div>
)

interface ProfileFieldProps {
  label: string
  value?: string | null
  className?: string
}

const ProfileField: React.FC<ProfileFieldProps> = ({
  label,
  value,
  className = '',
}) => (
  <div className="flex gap-4 my-4">
    <h6 className="w-15">{label}</h6>
    <p className={className}>{value || 'N/A'}</p>
  </div>
)

export default DevelopersProfile
