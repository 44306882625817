import {
  getAllDeveloperProjectProperties,
  getSingleProjectDetails,
} from '@services/krentDeveloper'
import { DeveloperPropertyCard } from '@shared/cards/developer-property'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
// import buildingImg from '../../../assets/images/building.png'
import propertyIcon from '../../../assets/icons/prop.svg'
import rulerIcon from '../../../assets/icons/ruler.svg'
import { BaseLayout } from '@layouts/BaseLayout'
import image from '../../../assets/images/profile-pic.png'
import ImageCarousel from './image-carousel'

const DeveloperAccountProjects = () => {
  const { id } = useParams<{ id: string }>()
  const [project, setProject] = useState<any>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [properties, setProperties] = useState<any[]>([])
  const [filteredProperties, setFilteredProperties] = useState<any[]>([])
  const [selectedType, setSelectedType] = useState<string>('all')
  const navigate = useNavigate()

  const handleViewDeveloperProfile = () => {
    navigate('/dev-profile', { state: { developerData: project.user } })
  }

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    const fetchProjectDetails = async () => {
      try {
        const response = await getSingleProjectDetails(id!, signal)
        setProject(response.data || [])
        setLoading(false)
      } catch (err) {
        if (err instanceof Error) {
          if (err.name === 'AbortError') {
            console.log('Request was aborted')
          } else {
            setError('Failed to fetch project details')
          }
        } else {
          setError('An unknown error occurred')
        }
        setLoading(false)
      }
    }

    const fetchProjectProperties = async () => {
      try {
        if (id) {
          const response = await getAllDeveloperProjectProperties(id, signal)
          const propertiesData = response.data as any
          setProperties(propertiesData || [])
          setFilteredProperties(propertiesData || []) // Set all properties by default
        }
      } catch (err) {
        console.error('Failed to fetch project properties:', err)
      }
    }

    fetchProjectDetails()
    fetchProjectProperties()

    return () => {
      controller.abort()
    }
  }, [id])

  const handleTypeFilter = (type: string) => {
    setSelectedType(type)
    if (type === 'all') {
      setFilteredProperties(properties)
    } else {
      const filtered = properties.filter(property => property.propertyType === type)
      setFilteredProperties(filtered)
    }
  }

  if (loading) return <div>Loading...</div>
  if (error) return <div>{error}</div>
  if (!project) return <div>No project found</div>

  return (
    <BaseLayout>
      <div className="w-80 mx-auto mt-4">
        <div className="mt-7">
          <h6 className="my-4">Projects details</h6>
        </div>

          <div className="flex my-10 gap-4 items-start ">
            <ImageCarousel images={project.coverImage} />

            <div className="w-full">
              <h5
                className={`fw-bold w-max rounded-50 px-3 fs-12 mb-3 ${
                  project.projectTimeline === 'previous'
                    ? 'previous'
                    : project.projectTimeline === 'current'
                    ? 'current'
                    : 'future'
                }`}
              >
                <span className="capitalize">{project.projectTimeline} </span>
                project
              </h5>
              <div className="flex gap-3">
                {project.projectPropertiesType?.map(
                  (item: any, index: number) => (
                    <h6
                      className="uppercase bg-primary-light w-max rounded-2 p-2 fs-12"
                      key={index}
                    >
                      {item ?? ''}
                    </h6>
                  ),
                )}
              </div>
              <h4 className="my-3">{project.projectName}</h4>

            <p className="gap-2 flex items-center my-3">
              <i className="fa fa-location-dot text-primary fa-regular"></i>
              {project.address}
            </p>
            <div className="my-3">
              <h6 className="my-2">Project description</h6>
              <p className="" style={{ width: '80%' }}>
                {project.description}
              </p>
            </div>
            <div>
              <h6>Property types under this project</h6>
              <div className="flex gap-2 capitalize items-center mt-2">
                {project.projectPropertiesType?.map(
                  (item: any, index: number) => (
                    <p className="fs-14 mt-0" key={index}>
                      {item ?? ''},
                    </p>
                  ),
                )}
              </div>
            </div>
            <div className="flex gap-20 items-center my-3">
              <div className="flex gap-1">
                <img src={propertyIcon} alt="propertyIcon" />
                <h6>{project.properties?.length} Properties</h6>
              </div>
              <div className="flex  gap-1">
                <img src={rulerIcon} alt="propertyIcon" />
                <h6>{project.projectSize} sqft</h6>
              </div>
            </div>
          </div>
        </div>

        <div className="flex gap-40 mt-7 border rounded-4 p-4">
          <div>
            <h5>Listed by the developer</h5>
            <div className="flex gap-4 mt-4">
              <img
                src={image}
                alt={'Avatar'}
                style={{ objectFit: 'cover' }}
                height={50}
                width={50}
                className="rounded-circle"
              />
              <div>
                <h5 className="capitalize">
                  {project.user?.firstname.toLowerCase()}{' '}
                  {project.user?.lastname.toLowerCase()}
                </h5>
                <p>Developer</p>
              </div>
            </div>
          </div>
          <div>
            <h5>About</h5>
            <p>{project.user?.aboutMe}</p>
            <div className="my-2">
              <button
                onClick={handleViewDeveloperProfile}
                className="border-green bg-white text-primary rounded-10 p-2"
              >
                View developer profile
              </button>
            </div>
          </div>
        </div>

        <div className="mt-7">
          <div className="flex justify-between mb-4">
            <h5>Properties in this project</h5>
          </div>
          <div className="flex gap-4">
            <h6
              className={`uppercase w-max rounded-2 p-2 fs-12 cursor-pointer ${
                selectedType === 'all' ? 'bg-primary text-white' : 'bg-primary-light'
              }`}
              onClick={() => handleTypeFilter('all')}
            >
              all
            </h6>
            {project.projectPropertiesType?.map((item: string, index: number) => (
              <h6
                className={`uppercase w-max rounded-2 p-2 fs-12 cursor-pointer ${
                  selectedType === item ? 'bg-primary text-white' : 'bg-primary-light'
                }`}
                key={index}
                onClick={() => handleTypeFilter(item)}
              >
                {item}
              </h6>
            ))}
          </div>
          {filteredProperties.length < 1 ? (
            <div className="text-center my-4">No property in this project</div>
          ) : (
            <div className="projects-div">
              {filteredProperties.map((p, idx) => (
                <div key={idx}>
                  <DeveloperPropertyCard property={p} client />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </BaseLayout>
  )
}

export default DeveloperAccountProjects
