import React, { useCallback, useEffect, useState } from 'react'
import { DevProject } from '@interfaces/app.interface'
import { BaseLayout } from '@layouts/BaseLayout'
import { getAllDevelopersProjects } from '@services/krentDeveloper'
import { SearchCard2 } from '@shared/cards'
import { Projectcard } from '@shared/cards/ProjectCard'
import DevelopersPagination from '@shared/navbars/DevelopersNavbar/Pagination'
import {
  getPageFromSessionStorage,
  addPageToSessionStorage,
} from '@utils/helpers'

const ITEMS_PER_PAGE = 9

const DeveloperAccount = () => {
  const [projects, setProjects] = useState<DevProject[]>([])
  const [currentPage, setCurrentPage] = useState(() => {
    const storedPage = parseInt(getPageFromSessionStorage())
    return isNaN(storedPage) ? 1 : storedPage
  })
  const [totalPages, setTotalPages] = useState(1)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const fetchProjects = useCallback(async (page: number) => {
    setLoading(true)
    setError(null)
    const abortController = new AbortController()

    try {
      const response = await getAllDevelopersProjects(
        ITEMS_PER_PAGE,
        page,
        abortController.signal,
      )
      console.log(response)
      setProjects((response.data as any) || [])
      setTotalPages(Math.ceil(response.pagination?.totalPages || 1))
    } catch (error) {
      setError('Failed to fetch projects. Please try again.')
      console.error(error)
    } finally {
      setLoading(false)
    }

    return () => {
      abortController.abort()
    }
  }, [])

  useEffect(() => {
    fetchProjects(currentPage)
  }, [fetchProjects, currentPage])

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage)
    addPageToSessionStorage(newPage)
  }

  return (
    <BaseLayout>
      <div className="w-80 mx-auto mt-4">
        <div className="mt-4">
          <h6 className='my-4'>Filter projects</h6>
          <SearchCard2
            extraClass="rounded-top-left-0 rounded-top-right-0"
            sizeClass=" col-md-6 col-8"
            page={currentPage}
            setPage={handlePageChange}
          />
        </div>

        <div className="my-10">
          <div className="row row-cols-1 row-cols-md-3 g-5 mb-5 w-full">
            {loading &&
              [...new Array(6).fill(0)].map((_, j) => (
                <div className="col" key={j}>
                  <div
                    className="card border-light shadow-sm"
                    style={{ height: '25rem' }}
                    aria-hidden="true"
                  >
                    <svg
                      className="bd-placeholder-img card-img-top"
                      width="100%"
                      height="180"
                      xmlns="http://www.w3.org/2000/svg"
                      role="img"
                      aria-label="Placeholder"
                      preserveAspectRatio="xMidYMid slice"
                      focusable="false"
                    >
                      <title>Placeholder</title>
                      <rect width="100%" height="100%" fill="#868e96"></rect>
                    </svg>
                    <div className="card-body">
                      <h5 className="card-title placeholder-glow">
                        <span className="placeholder col-6"></span>
                      </h5>
                      <p className="card-text placeholder-glow">
                        <span className="placeholder col-7"></span>
                        <span className="placeholder col-4"></span>
                        <span className="placeholder col-4"></span>
                        <span className="placeholder col-6"></span>
                        <span className="placeholder col-8"></span>
                      </p>
                      <span
                        tabIndex={-1}
                        className="btn my-4 btn-primary bg-primary disabled placeholder col-6"
                      ></span>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {error && <p className="text-red-500">{error}</p>}
          {!loading && !error && (
            <>
              <div className="projects-div">
                {projects.map((project, idx) => (
                  <div key={project._id || idx}>
                    <Projectcard project={project} client />
                  </div>
                ))}
              </div>
              <DevelopersPagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </>
          )}
        </div>
      </div>
    </BaseLayout>
  )
}

export default DeveloperAccount
