import { BaseLayout } from '@layouts/BaseLayout'
import React, { useCallback, useEffect, useState } from 'react'
import image from '../../../../assets/images/profile-pic.png'
import { useLocation } from 'react-router-dom'
import AllProjects from '../all-projects'
import { DevProject } from '@interfaces/app.interface'
import {
  createDeveloperReviews,
  getAllDeveloperProjects,
  getDeveloperReviews,
} from '@services/krentDeveloper'
import Tabs from '@shared/Tabs'
import starsImg from '../../../../assets/images/stars.svg'
import { toast } from 'react-toastify'

// Updated Review interface with user structure
interface Review {
  id: string
  user: {
    firstname: string
    lastname: string
  }
  rating: number
  comment: string
  date: string
}

const ViewDeveloperProfile: React.FC = () => {
  const location = useLocation()
  const developerData = (location.state as any)?.developerData || {}

  const [projects, setProjects] = useState<DevProject[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [currentTimeline, setCurrentTimeline] = useState<string>('all')
  const [reviews, setReviews] = useState<Review[]>([])
  const [sortOrder, setSortOrder] = useState<'newest' | 'oldest'>('newest')
  const [newReview, setNewReview] = useState<string>('')
  const [newRating, setNewRating] = useState<number>(0)
  const [isPostingReview, setIsPostingReview] = useState<boolean>(false)

  const fetchReviews = useCallback(async () => {
    setLoading(true)
    setError(null)
    const abortController = new AbortController()

    try {
      const response = await getDeveloperReviews(
        developerData.id,
        abortController.signal,
      )
      setReviews(response.data || [])
    } catch (err) {
      setError('Failed to fetch reviews. Please try again.')
    } finally {
      setLoading(false)
    }

    return () => {
      abortController.abort()
    }
  }, [developerData.id])

  const handlePostReview = async (e: React.FormEvent) => {
    e.preventDefault()
    if (newReview.trim() === '' || newRating === 0) {
      toast.error('Please provide both a review and a rating.')
      return
    }

    setIsPostingReview(true)
    const abortController = new AbortController()

    try {
      const response = await createDeveloperReviews(
        developerData.id,
        {
          comment: newReview,
          rating: newRating,
        },
        abortController.signal,
      )

      const newReviewObject: Review = {
        id: response.data.id || Date.now().toString(),
        user: {
          firstname: 'You',
          lastname: '',
        },
        rating: newRating,
        comment: newReview,
        date: new Date().toISOString(),
      }
      setReviews([newReviewObject, ...reviews])
      setNewReview('')
      setNewRating(0)
      toast.success('Review posted successfully!')
    } catch (error) {
      toast.error('Failed to post review. Please try again.')
    } finally {
      setIsPostingReview(false)
    }
  }

  useEffect(() => {
    fetchReviews()
  }, [fetchReviews])

  const components = {
    'All Projects': <AllProjects projects={projects} />,
    'Current Projects': <AllProjects projects={projects} />,
    'Previous Projects': <AllProjects projects={projects} />,
    'Future Projects': <AllProjects projects={projects} />,
  }

  const handleTabChange = (tabName: string) => {
    let timeline: string
    switch (tabName) {
      case 'Current Projects':
        timeline = 'current'
        break
      case 'Previous Projects':
        timeline = 'previous'
        break
      case 'Future Projects':
        timeline = 'future'
        break
      default:
        timeline = 'all'
    }
    setCurrentTimeline(timeline)
  }

  const fetchProjects = useCallback(
    async (timeline: string) => {
      setLoading(true)
      setError(null)
      const abortController = new AbortController()

      try {
        const response = await getAllDeveloperProjects(
          developerData.id,
          // abortController.signal,
          timeline,
        )
        setProjects(response.data as DevProject[])
      } catch (error) {
        setError('Failed to fetch projects. Please try again.')
      } finally {
        setLoading(false)
      }

      return () => {
        abortController.abort()
      }
    },
    [developerData?.id],
  )

  useEffect(() => {
    fetchProjects(currentTimeline)
  }, [fetchProjects, currentTimeline])

  return (
    <BaseLayout>
      <div className=" p-12">
        <div className="flex gap-40 items-center">
          <div>
            <img
              src={image}
              alt="Avatar"
              style={{ objectFit: 'cover' }}
              height={250}
              width={250}
              className="rounded-circle"
            />
          </div>
          <div>
            <div className="flex items-center">
              <h6 className="w-15 fs-16">Developer Name</h6>
              <p className="fs-16 capitalize">
                {developerData.firstname?.toLowerCase()} &nbsp;
                {developerData.lastname?.toLowerCase()}
              </p>
            </div>
            <div className="flex items-center my-4">
              <h6 className="w-15 fs-16">Bio (Company Details)</h6>
              <p className="fs-16">{developerData.aboutMe}</p>
            </div>
          </div>
        </div>
        <hr />
        <div>
          <h6>DEVELOPERS PROJECTS</h6>
          <div className="my-10">
            <Tabs
              components={components}
              onTabChange={handleTabChange}
              loading={loading}
              error={error}
            />
          </div>
        </div>
        <hr />
        <div>
          <h6>RATINGS AND REVIEWS</h6>
          <div className="flex gap-4 items-center my-3">
            <img src={starsImg} alt="stars" />
            <p>{reviews.length} reviews</p>
          </div>
          <div>
            <div className="flex items-center justify-between">
              <h5>Reviews</h5>
              <div className="flex items-center gap-1">
                <p className="no-text-wrap">Sort by:</p>
                <select
                  className="form-select py-1 px-3"
                  value={sortOrder}
                  onChange={(e) =>
                    setSortOrder(e.target.value as 'newest' | 'oldest')
                  }
                >
                  <option value="newest">Newest</option>
                  <option value="oldest">Oldest</option>
                </select>
              </div>
            </div>
            <div className="my-4">
              {loading && <p>Loading reviews...</p>}
              {error && <p className="text-red-500">{error}</p>}
              {!loading && !error && reviews.length > 0
                ? reviews.map((review) => (
                    <div className="border rounded-4 p-4 my-4" key={review.id}>
                      <div className="flex justify-between">
                        <div>
                          <h6 className='capitalize'>
                            {review.user.firstname.toLowerCase()}{' '}
                            {review.user.lastname.toLowerCase()}
                          </h6>
                          <img src={starsImg} alt={`${review.rating} stars`} />
                        </div>
                        <p>{new Date(review.date).toLocaleDateString()}</p>
                      </div>
                      <p className="my-1">{review.comment}</p>
                      <div className="flex gap-2 w-max ml-auto mt-2">
                        <p>Was this review helpful?</p>
                        <div className="flex gap-2">
                          <a href="/">Yes</a>
                          <span>|</span>
                          <a href="/">No</a>
                        </div>
                      </div>
                    </div>
                  ))
                : 'No reviews yet'}
            </div>
            <form onSubmit={handlePostReview}>
              <div>
                <label className="fw-bolder form-label text-capitalize">
                  Post a review
                </label>
                <textarea
                  className="form-control form-control-0 ps-3 py-3 border"
                  placeholder="Write a review"
                  value={newReview}
                  required
                  rows={5}
                  onChange={(e) => setNewReview(e.target.value)}
                />
              </div>
              <div className="my-3">
                <label className="fw-bolder form-label text-capitalize">
                  Rating
                </label>
                <select
                  className="form-select py-1 px-3"
                  value={newRating}
                  onChange={(e) => setNewRating(Number(e.target.value))}
                  required
                >
                  <option value={0}>Select a rating</option>
                  <option value={1}>1 Star</option>
                  <option value={2}>2 Stars</option>
                  <option value={3}>3 Stars</option>
                  <option value={4}>4 Stars</option>
                  <option value={5}>5 Stars</option>
                </select>
              </div>
              <button
                className="bg-primary rounded-10 text-white w-max p-2"
                type="submit"
                disabled={isPostingReview}
              >
                {isPostingReview ? 'Posting...' : 'Post review'}
              </button>
            </form>
          </div>
        </div>
      </div>
    </BaseLayout>
  )
}

export default ViewDeveloperProfile
