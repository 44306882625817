import React, { useState, useEffect, useCallback } from 'react'
import CustomSelect from '@shared/forms/select'

export interface PropertyPricingState {
  price: string
  currency: PropertyCurrency
  rentDuration: string
  serviceCharge: string
  cautionFee: string
  serviceChargeDuration: string
}

type PropertyCurrency = 'ngn' | 'eur' | 'usd' | ''

interface PropertyPricingProps {
  pricingDetails: PropertyPricingState
  onPricingDetailsChange: (newDetails: Partial<PropertyPricingState>) => void
  onValidationChange?: (isValid: boolean) => void
}

const PropertyPricing: React.FC<PropertyPricingProps> = ({
  pricingDetails,
  onPricingDetailsChange,
  onValidationChange,
}) => {
  const [errors, setErrors] = useState<Partial<Record<keyof PropertyPricingState, string>>>({})
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false)

  const validateField = (field: keyof PropertyPricingState, value: string): string => {
    if (!value?.toString().trim()) return 'This field is required'
    if (field === 'price' && isNaN(Number(value))) return 'Price must be a valid number'
    return ''
  }

  const renderError = (field: keyof PropertyPricingState) =>
    hasAttemptedSubmit && errors[field] && <p className="text-red text-sm ml-2">{errors[field]}</p>

  const validateForm = useCallback((): boolean => {
    const newErrors: Partial<Record<keyof PropertyPricingState, string>> = {}
    let isValid = true

    const requiredFields: (keyof PropertyPricingState)[] = ['price', 'currency']

    requiredFields.forEach((field) => {
      const error = validateField(field, pricingDetails[field] as string)
      if (error) {
        newErrors[field] = error
        isValid = false
      }
    })

    setErrors(newErrors)
    onValidationChange?.(isValid)
    return isValid
  }, [pricingDetails, onValidationChange])

  useEffect(() => {
    const isValid = validateForm()
    onValidationChange?.(isValid)
  }, [pricingDetails, onValidationChange, validateForm])

  const handleChange = (field: keyof PropertyPricingState, value: string) => {
    setHasAttemptedSubmit(true)
    const error = validateField(field, value)
    setErrors((prev) => ({
      ...prev,
      [field]: error,
    }))
    onPricingDetailsChange({ [field]: value })
  }

  const currencyOptions = [
    { value: 'ngn', label: 'NGN' },
    { value: 'eur', label: 'EUR' },
    { value: 'usd', label: 'USD' },
  ]

  // const rentDurationOptions = [
  //   { value: '1-year', label: '1 Year' },
  //   { value: '5-years', label: '5 Years' },
  //   { value: '10-years', label: '10 Years' },
  // ]

  // const serviceChargeDurationOptions = [
  //   { value: 'monthly', label: 'Monthly' },
  //   { value: 'quarterly', label: 'Quarterly' },
  //   { value: 'yearly', label: 'Yearly' },
  // ]

  return (
    <div>
      <div className="my-4">
        <label className="fw-bolder form-label text-capitalize">Price</label>
        <input
          type="number"
          className={`form-control rounded-pill py-3 px-4 fs-14 mb-3 ${
            hasAttemptedSubmit && errors.price ? 'border-red-500' : ''
          }`}
          value={pricingDetails.price}
          onChange={(e) => handleChange('price', e.target.value)}
          placeholder="Enter amount"
        />
        {renderError('price')}
      </div>

      <div
        className={
          hasAttemptedSubmit && errors.currency ? 'border-red-500' : ''
        }
      >
        <CustomSelect
          label="Currency"
          value={pricingDetails.currency}
          onChange={(value) =>
            handleChange('currency', value as PropertyCurrency)
          }
          options={currencyOptions}
        />
        {renderError('currency')}
      </div>
    </div>
  )
}

export default PropertyPricing
