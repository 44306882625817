import { numberWithCommas } from '@utils/helpers'
import React from 'react'
import ImageCarousel from '../image-carousel'

const PropertyView = ({property}: any) => {
  return (
    <div className="flex my-10 gap-10 items-start">
        <ImageCarousel images={property.otherImages} />

        <div className="w-full">
          <h5
            className={`fw-bold w-max rounded-50 px-3 fs-12 mb-3 ${
              property.propertyDeedType === 'shortlet'
                ? 'previous'
                : property.propertyDeedType === 'rent'
                ? 'current'
                : 'future'
            }`}
          >
            <span className="capitalize">{property.propertyDeedType}</span>
          </h5>
          <h6 className="uppercase bg-primary-light w-max rounded-2 p-2 fs-12">
            {property.propertyType}
          </h6>
          <h4 className="my-3">{property.propertyName}</h4>
          <h4 className="my-3">
            <span className="uppercase">{property.currency}</span>&nbsp;
            {numberWithCommas(property.price)}
          </h4>

          <p className="gap-2 flex items-center my-3">
            <i className="fa fa-location-dot text-primary fa-regular"></i>
            {property.address}
          </p>

          <div className="my-3">
            <h6 className="my-2">Property description</h6>
            <p style={{ width: '80%' }}>{property.propertyDescription}</p>
          </div>
          <div className="flex gap-20 items-center my-2">
            <div>
              <span className="fs-14 fw-bold">
                <i className="fa-light fa-bed-front text-primary"></i>&nbsp;{' '}
                {property.numberOfUnits}&nbsp; total units
              </span>
            </div>

            <div>
              <span className="fs-14 fw-bold">
                <i className="fa-light fa-bath text-primary"></i>&nbsp;{' '}
                {property.numberOfBedrooms} &nbsp; available units
              </span>
            </div>
          </div>
          <div className="flex gap-20 items-center">
            <div>
              <span className="fs-14 fw-bold">
                <i className="fa-light fa-bed-front text-primary"></i>&nbsp;
                {property.numberOfBathrooms}&nbsp; bedrooms
              </span>
            </div>
            <div>
              <span className="fs-14 fw-bold">
                <i className="fa-light fa-bath text-primary"></i>&nbsp;
                {property.numberOfBedrooms} &nbsp;bathrooms
              </span>
            </div>
          </div>
        </div>
      </div>
  )
}

export default PropertyView