import { formatDate, formatDateToTime, numberWithCommas } from '@utils/helpers'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { DashboardLayout } from '@layouts/DashboardLayout'
import { fetchDevClientDocument, getComments } from '@services/krentDeveloper'
import ImageCarousel from '@pages/Developers/Projects/image-carousel'

const steps = [
  'Request accepted',
  'Inspection confirmed',
  'Payment made',
  'Documentation process',
  'Documentation received',
]
const getCurrentStep = (item: any) => {
  if (!item.isBooked) return 0

  if (item.isBooked && !item.inspected) return 1

  if (item.inspected && item.paymentStatus !== 'confirmed') return 2

  if (
    item.paymentStatus === 'confirmed' &&
    item.documentationStatus !== 'Interest Request Process'
  )
    return 3

  if (item.purchaseDocument) return 4

  if (item.purchaseDocument) return 5

  return 6
}

const UserPurchasedPropertyDetails = () => {
  const { state } = useLocation()
  const item = (state as any).item
  const [devComments, setDevComments] = useState([])
  const [devClientDocument, setDevClientDocument] = useState(null)

  console.log(state, item, 'state')
  const fetchComments = async (itemId: string) => {
    const abortController = new AbortController()
    try {
      const response = await getComments(itemId, abortController.signal)
      setDevComments(response.data)
    } catch (error) {
      console.error('Error fetching comments:', error)
    }
  }
  const fetchClientDocument = async (developerId: string) => {
    const abortController = new AbortController()
    try {
      const response = await fetchDevClientDocument(
        developerId,
        abortController.signal,
      )
      setDevClientDocument(response.data)
    } catch (error) {
      console.error('Error fetching client document:', error)
    }
  }

  useEffect(() => {
    if (item?._id) {
      fetchComments(item._id)
    }

    if (item?._id) {
      fetchClientDocument(item._id)
    }
  }, [item])
  console.log(devClientDocument, item, 'devCokfkfkmmme')

  if (!state) {
    return <div>No property details found</div>
  }

  // if (!isLoaded) {
  //   return <div>Loading...</div>
  // }
  return (
    <DashboardLayout>
      <div className="px-10 mx-auto my-4">
        <div className="mt-2">
          <h6 className="my-4">Property details</h6>
          <div className="flex justify-between items-center">
            <div className="flex gap-2 items-center">
              <i className="fa-solid fa-angle-left "></i>
              <a href={`/purchases`} className="text-link-secondary fs-16">
                Back to purchases
              </a>
            </div>
          </div>
        </div>
        <div className="flex my-10 gap-4 items-start">
          <ImageCarousel images={item.property?.otherImages} />

          <div className="w-full">
            <div
              className="stepper-container"
              style={
                {
                  '--total-steps': 5,
                  '--current-step': getCurrentStep(item),
                } as React.CSSProperties
              }
            >
              {Array.from({ length: 5 }, (_, index) => (
                <div key={index} className="w-full " style={{ height: '80px' }}>
                  <div
                    className={`step-item ${
                      index < getCurrentStep(item) ? 'active' : ''
                    }`}
                  >
                    <div className="step-number">{index + 1}</div>
                    {index < 4 && <div className="step-line"></div>}
                  </div>
                  <div className="mt-1">{steps[index]}</div>
                </div>
              ))}
            </div>
            <h5
              className={`fw-bold w-max rounded-50 px-3 fs-12 mb-3 ${
                item.property?.propertyType === 'bungalow'
                  ? 'previous'
                  : item.property?.propertyType === 'flats'
                  ? 'current'
                  : 'future'
              }`}
            >
              <span className="capitalize">
                {item.property?.propertyType}
              </span>
            </h5>
            <h6 className="uppercase bg-primary-light w-max rounded-2 p-2 fs-12">
              {item.property?.propertyType}
            </h6>
            <h4 className="my-3">{item.property?.propertyName}</h4>
            <h4 className="my-3">
              <span className="uppercase">{item.property?.currency}</span>
              &nbsp;
              {numberWithCommas(item.property?.price)}
            </h4>

            <p className="gap-2 flex items-center my-3">
              <i className="fa fa-location-dot text-primary fa-regular"></i>
              {item.property?.address}
            </p>
            <div className="flex gap-20 items-center my-2">
            <div>
              <span className="fs-14 fw-bold">
                <i className="fa-light fa-bed-front text-primary"></i>&nbsp;{' '}
                {item.property.numberOfUnits}&nbsp; total units
              </span>
            </div>

            <div>
              <span className="fs-14 fw-bold">
                <i className="fa-light fa-bath text-primary"></i>&nbsp;{' '}
                {item.property.numberOfBedrooms} &nbsp; available units
              </span>
            </div>
          </div>
            <div className="flex gap-20 items-center">
              <div>
                <span className="fs-14 fw-bold">
                  <i className="fa-light fa-bed-front text-primary"></i>&nbsp;
                  {item.property?.numberOfBathrooms}&nbsp; bedrooms
                </span>
              </div>
              <div>
                <span className="fs-14 fw-bold">
                  <i className="fa-light fa-bath text-primary"></i>&nbsp;
                  {item.property?.numberOfBedrooms} &nbsp;bathrooms
                </span>
              </div>
            </div>

            {/* <div className="my-4">
              <h6 className="fs-14">
                Documentation {item.documentationProgress}% (in progress)
              </h6>
              <div
                className="progress-bar-wrapper"
                style={{
                  backgroundColor: '#f3f3f3',
                  borderRadius: '10px',
                  height: '10px',
                  width: '80%',
                  marginTop: '10px',
                }}
              >
                <div
                  className="progress-bar"
                  style={{
                    width: `${item.documentationProgress}%`,
                    height: '100%',
                    backgroundColor: '#47BBA9',
                    borderRadius: '10px',
                    transition: 'width 0.5s ease-in-out',
                  }}
                />
              </div>
            </div> */}
            <div className="flex justify-between w-80 mt-4">
              <div>
                <h6 className="fs-14">Initiation date</h6>
                <p className="text-center">
                  {item.initiationDate
                    ? formatDate(item.initiationDate || '...')
                    : '...'}
                </p>
                <p></p>
              </div>
              <div>
                <h6 className="fs-14">Closed date</h6>
                <p className="text-center">
                  {item.closedDate
                    ? formatDate(item.closedDate || '...')
                    : '...'}
                </p>
                <p></p>
              </div>
            </div>
            <div className="my-2">
              <div>
                {item.purchaseDocument && (
                  <div className="text-primary">
                    <h6>Download Document from Developer</h6>
                    <div>
                      {Object.entries(item.purchaseDocument).map(
                        ([key, value]) => (
                          <div
                            key={key}
                            className="flex items-center gap-4 my-2"
                          >
                            <h6 className="capitalize fs-14 w-30">{key}:</h6>
                            <a
                              download
                              href={String(value)}
                              className="border-green rounded-10 p-2 text-primary w-full flex justify-between"
                            >
                              <p>
                                {String(value).length > 20
                                  ? `${String(value).slice(0, 20)}...`
                                  : String(value)}
                              </p>
                              <i className="fa-regular fa-download text-primary p-1"></i>
                            </a>
                          </div>
                        ),
                      )}
                    </div>
                  </div>
                )}
              </div>
              {!item.purchaseRequestReceipt && (
                <div>
                  <h6 className="fs-12">
                    Please proceed to make transfer payment
                  </h6>
                  <p>
                    If you have not made payment, kindly make transfer to this
                    details
                  </p>
                  <div className="my-3">
                    <p className="fs-16">
                      <span className="fw-bold">Acc name:&nbsp;</span>
                      Krent space
                    </p>
                    <p className="fs-16">
                      <span className="fw-bold">Bank name:&nbsp;</span>
                      Wema bank
                    </p>
                    <p className="fs-16">
                      <span className="fw-bold">Acc number:&nbsp;</span>
                      0123456789
                    </p>
                  </div>
                </div>
              )}
            </div>
            <>
              {item.isBooked === false && (
                <div className=" bg-primary-light rounded-2 p-2 fs-14 flex gap-2">
                  <i className="fa-regular fa-info-circle mt-1"></i>
                  <p>
                    Your interest has been communicated to the agent. We are
                    waiting for a confirmation on the availablity of the
                    property.
                  </p>
                </div>
              )}

              <button className="bg-primary text-white rounded-10 my-4 p-2 fs-14 fw-bold">
                View Transaction
              </button>
            </>
          </div>
        </div>

        <div className="flex gap-3">
          <div className=" justify-between border rounded-4 p-3 w-full">
            <h5 className="">Developer details</h5>

            <div className="flex py-2 gap-4">
              <div className="w-full">
                <p className="capitalize">
                  {item.developer.firstname.toLowerCase()}&nbsp;
                  {item.developer.lastname.toLowerCase()}
                </p>
                <p>Developer</p>
              </div>
              <div className="w-full">
                <div className="flex gap-4">
                  <h6>Company name</h6>
                  <p>Green</p>
                </div>
                <div className="flex gap-4">
                  <h6>Tax ID number</h6>
                  <p>1234578</p>
                </div>
              </div>
            </div>
            {devComments.length > 0 && (
              <>
                <hr />
                <div>
                  <h6 className="pb-2">Developer comments</h6>
                  {devComments.map((comment: any, idx: any) => (
                    <div key={idx} className="w-15">
                      <div className="border p-2 rounded-10">
                        <p>{comment.content}</p>
                      </div>
                      <p className="fs-10 py-2 w-max ml-auto">
                        {formatDateToTime(comment.timestamp)}
                      </p>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
          <div className=" justify-between border rounded-4 p-3 w-full">
            <h5 className="">Agent details</h5>

            <div className="flex py-2 gap-4">
              <div className="w-full">
                <p className="capitalize">
                  {item.agent.firstname.toLowerCase()}&nbsp;
                  {item.agent.lastname.toLowerCase()}
                </p>
                <p>Agent</p>
              </div>
              <div className="w-full">
                <div className="flex gap-4">
                  <h6>Email</h6>
                  <p>{item.agent.email}</p>
                </div>
                <div className="flex gap-4">
                  <h6>Phone number</h6>
                  <p>{item.agent.phone}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  )
}

export default UserPurchasedPropertyDetails
