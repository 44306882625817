import {
    DirectionsRenderer,
    GoogleMap,
    Marker,
    useJsApiLoader,
  } from '@react-google-maps/api'
  import { isAuthenticated, numberWithCommas } from '@utils/helpers'
  import PropertyFeatures from 'components/Developer/Property/property-features'
  import React, { useEffect, useState } from 'react'
  import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
  import buildingImg from '../../../assets/images/building.png'
  import utilitiesIcon from '../../../assets/icons/utilities.svg'
  import entertainmentIcon from '../../../assets/icons/entertainment.svg'
  import kitchenIcon from '../../../assets/icons/kitchen.svg'
  import safetyIcon from '../../../assets/icons/safety.svg'
  import othersIcon from '../../../assets/icons/others.svg'
  import { DeveloperPropertyCard } from '@shared/cards/developer-property'
  import {
    createUserInterest,
    getAllAgents,
    getAllDeveloperProjectProperties,
    getDeveloperSingleProperty,
  } from '@services/krentDeveloper'
  import { Modal } from 'react-bootstrap'
  import { Agent } from '@utils/constants'
  import { DeveloperProjectsResponse } from '@interfaces/app.interface'
  import { DeveloperDashboardLayout } from '@layouts/DeveloperDashboardLayout'
  
  const DevInterestDetails = () => {
    const { state } = useLocation()
    const navigate = useNavigate()
    const [center, setCenter] = useState({ lat: 9.082, lng: 8.6753 })
    const [directionsResponse, setDirectionsResponse] = useState<any>(null)
    const [map, setMap] = useState<google.maps.Map | null>(null)
    const [otherProperties, setOtherProperties] = useState<any[]>([])
    const [showAgentModal, setShowAgentModal] = useState(false)
    const [allAgents, setAllAgents] = useState([])
    const [showLoginModal, setShowLoginModal] = useState(false)
    const { id } = useParams<{ id: string }>()
    const [property, setProperty] = useState<DeveloperProjectsResponse | any>('')
  
    const { isLoaded } = useJsApiLoader({
      googleMapsApiKey: process.env['REACT_APP_GOOGLE_API_KEY'] || '',
      libraries: ['places'],
    })
  
    const shownInterest = (state as any)?.shownInterest
  
    useEffect(() => {
      const controller = new AbortController()
      const signal = controller.signal
  
      const fetchPropertyDetails = async () => {
        try {
          if (id) {
            const response = await getDeveloperSingleProperty(id, signal)
  
            console.log(response)
            setProperty(response.data as any)
          }
        } catch (err) {
          console.error('Failed to fetch propert details:', err)
        }
      }
  
      fetchPropertyDetails()
  
      return () => {
        controller.abort()
      }
    }, [id])
  
    useEffect(() => {
      const controller = new AbortController()
      const signal = controller.signal
  
      const fetchOtherProjectProperties = async () => {
        try {
          if (property?.project) {
            const response = await getAllDeveloperProjectProperties(
              property?.project,
              signal,
            )
            // Filter out the current property
            const filteredProperties = (response.data as any[]).filter(
              (p) => p.id !== property.id,
            )
            setOtherProperties(filteredProperties || [])
          }
        } catch (err) {
          console.error('Failed to fetch project properties:', err)
        }
      }
  
      fetchOtherProjectProperties()
  
      return () => {
        controller.abort()
      }
    }, [property?.project, property?.id])
    useEffect(() => {
      const controller = new AbortController()
      const signal = controller.signal
  
      const fetchOtherProjectProperties = async () => {
        try {
          if (property?.project) {
            const response = await getAllDeveloperProjectProperties(
              property?.project,
              signal,
            )
            // Filter out the current property
            const filteredProperties = (response.data as any[]).filter(
              (p) => p.id !== property.id,
            )
            setOtherProperties(filteredProperties || [])
          }
        } catch (err) {
          console.error('Failed to fetch project properties:', err)
        }
      }
  
      fetchOtherProjectProperties()
  
      return () => {
        controller.abort()
      }
    }, [property?.project, property?.id])
  
    useEffect(() => {
      if (property) {
        setCenter({
          lat: property.location?.coordinates[1] || 9.082,
          lng: property.location?.coordinates[0] || 8.6753,
        })
        setDirectionsResponse(property)
      }
    }, [property])
    useEffect(() => {
      if (showAgentModal) {
        const controller = new AbortController()
        const signal = controller.signal
  
        const fetchAgents = async () => {
          try {
            const response = await getAllAgents(signal)
            setAllAgents(response.data || [])
          } catch (error) {
            console.error('Failed to fetch agents:', error)
          }
        }
  
        fetchAgents()
  
        return () => {
          controller.abort()
        }
      }
    }, [showAgentModal])
    const handleSendInterest = async (agentId: string) => {
      if (isAuthenticated()) {
        try {
          const payload = {
            propertyId: id,
            agentId,
          }
          const response = await createUserInterest(payload)
          response.success && navigate('/purchases')
  
        } catch (error) {
          // toast.error(error)
  
          console.error('Failed to send interest:', error)
        }
      } else {
        localStorage.setItem('InterestIdReroute', JSON.stringify(id))
        setShowLoginModal(true)
      }
    }
    if (!property) {
      return <div>No property details found</div>
    }
  
    if (!isLoaded) {
      return <div>Loading...</div>
    }
    return (
      <DeveloperDashboardLayout title="My Sales">
        <div className="px-10 mx-auto my-4">
          <div className="mt-2">
            <h6 className="my-4">Property details</h6>
            <div className="flex justify-between items-center">
              <div className="flex gap-2 items-center">
                <i className="fa-solid fa-angle-left "></i>
                <a
                  href={`/developer-sales`}
                  className="text-link-secondary fs-16"
                >
                  Back to project
                </a>
              </div>
              {!shownInterest && (
                <div className="flex gap-3 items-center">
                  <button
                    onClick={() => setShowAgentModal(true)}
                    className="flex gap-2 items-center bg-primary text-white px-4 py-3 rounded-10 primary-border"
                  >
                    <i className="fa-regular fa-thumbs-up fa-xl"></i>
                    <span>I am interested</span>
                  </button>
                  <button className="flex gap-2 items-center bg-white primary-border text-primary px-4 py-3 rounded-10">
                    <i className="fa-regular fa-rotate-left fa-xl"></i>
                    <span>See 360 view</span>
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="flex my-10 gap-10 items-start">
            <div className="flex items-center gap-10 w-full">
              <i className="fa fa-angle-left text-white bg-primary px-2 py-1 rounded-full fa-solid"></i>
              <div style={{ width: '80%' }}>
                <img
                  src={property.coverImage || buildingImg}
                  className="proj-img rounded-b-15"
                  alt="property"
                />
              </div>
              <i className="fa fa-angle-right text-white bg-primary px-2 py-1 rounded-full fa-solid"></i>
            </div>
  
            <div className="w-full">
              <h5
                className={`fw-bold w-max rounded-50 px-3 fs-12 mb-3 ${
                  property.propertyDeedType === 'shortlet'
                    ? 'previous'
                    : property.propertyDeedType === 'rent'
                    ? 'current'
                    : 'future'
                }`}
              >
                <span className="capitalize">{property.propertyDeedType}</span>
              </h5>
              <h6 className="uppercase bg-primary-light w-max rounded-2 p-2 fs-12">
                {property.propertyType}
              </h6>
              <h4 className="my-3">{property.propertyName}</h4>
              <h4 className="my-3">
                <span className="uppercase">{property.currency}</span>&nbsp;
                {numberWithCommas(property.price)}
              </h4>
  
              <p className="gap-2 flex items-center my-3">
                <i className="fa fa-location-dot text-primary fa-regular"></i>
                {property.address}
              </p>
  
              <div className="my-3">
                <h6 className="my-2">Property description</h6>
                <p style={{ width: '80%' }}>{property.propertyDescription}</p>
              </div>
  
              <div className="flex gap-20 items-center">
                <div>
                  <span className="fs-14 fw-bold">
                    <i className="fa-light fa-bed-front text-primary"></i>&nbsp;
                    {property.numberOfBathrooms}&nbsp; bedrooms
                  </span>
                </div>
                <div>
                  <span className="fs-14 fw-bold">
                    <i className="fa-light fa-bath text-primary"></i>&nbsp;
                    {property.numberOfBedrooms} &nbsp;bathrooms
                  </span>
                </div>
              </div>
            </div>
          </div>
  
          <div>
            <h5 className="my-4">What this place offers</h5>
            <div className="flex justify-between border rounded-4 p-3">
              {property.utilitiesAndAmenities && (
                <PropertyFeatures
                  title="Utilities and Amenities"
                  icon={utilitiesIcon}
                  items={property.utilitiesAndAmenities}
                />
              )}
              {property.entertainment && (
                <PropertyFeatures
                  title="Entertainment"
                  icon={entertainmentIcon}
                  items={property.entertainment}
                />
              )}
              {property.kitchenAndDining && (
                <PropertyFeatures
                  title="Kitchen and Dining"
                  icon={kitchenIcon}
                  items={property.kitchenAndDining}
                />
              )}
              {property.safety && (
                <PropertyFeatures
                  title="Safety"
                  icon={safetyIcon}
                  items={property.safety}
                />
              )}
              {property.otherFeatures && (
                <PropertyFeatures
                  title="Other Features"
                  icon={othersIcon}
                  items={property.otherFeatures}
                />
              )}
            </div>
          </div>
  
          <div className="mt-4">
            <h5 className="my-4">Map</h5>
            <h6 className="my-2">The precise location of this property</h6>
  
            <p className="gap-2 flex items-center my-2 text-primary">
              <i className="fa fa-location-dot text-primary fa-regular"></i>
              {property.address} {property.city}, {property.country}
            </p>
            <div style={{ height: '25rem', position: 'relative' }}>
              <GoogleMap
                center={center}
                zoom={15}
                mapContainerStyle={{ width: '100%', height: '100%' }}
                onLoad={setMap}
              >
                <Marker position={center} />
                {directionsResponse && (
                  <DirectionsRenderer directions={directionsResponse} />
                )}
              </GoogleMap>
  
              <div
                className="me-auto bg-white rounded-circle d-flex align-items-center justify-content-center social-icon mb-2 cursor-pointer"
                style={{
                  position: 'absolute',
                  left: '30px',
                  bottom: '30px',
                }}
                onClick={() => {
                  map?.panTo(center)
                  map?.setZoom(15)
                }}
              >
                <i className="fa fa-location-arrow fs-22 text-primary"></i>
              </div>
            </div>
          </div>
          {!shownInterest && (
            <div className="mt-7">
              <div className="flex justify-between mb-4">
                <h5>Other Properties in this project</h5>
              </div>
              {otherProperties.length < 1 ? (
                <div className="text-center">
                  No other property in this project
                </div>
              ) : (
                <div className="projects-div">
                  {otherProperties.map((p, idx) => (
                    <div key={idx}>
                      <DeveloperPropertyCard property={p} client />
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
  
          <Modal
            show={showAgentModal}
            onClose={() => setShowAgentModal(false)}
            // size={'md'}
            position="top"
          >
            <div className="row px-4 py-3 h-40 overflow-scroll">
              <div className="flex justify-between items-center">
                <h5 className="fw-bolder mb-2">Choose an agent</h5>
                <div className="flex gap-3 items-center">
                  <button
                    className="fs-16 px-4 rounded-10 primary-border text-primary bg-white"
                    onClick={() => setShowAgentModal(false)}
                  >
                    SKIP
                  </button>
                  <button
                    onClick={() => setShowAgentModal(false)}
                    className="bg-white"
                  >
                    <i className="fa-solid fa-x "></i>
                  </button>
                </div>
              </div>
              <div>
                {allAgents.map((agent: Agent, idx) => (
                  <div key={idx}>
                    <div>
                      <h5>
                        {agent?.firstname.toLowerCase()}{' '}
                        {agent?.lastname.toLowerCase()}
                      </h5>
                      <p>4.5 stars rating (20 reviews)</p>
                    </div>
                    {agent.id && (
                      <button
                        onClick={() => handleSendInterest(agent?.id || '')}
                        className="flex gap-2 items-center bg-primary text-white px-4 py-2 rounded-10 primary-border fs-16"
                      >
                        <span>Send interest</span>
                      </button>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </Modal>
          <Modal show={showLoginModal} onClose={() => setShowLoginModal(false)}>
            <div className="row px-4 py-3">
              <div className="my-4">
                <button
                  onClick={() => setShowLoginModal(false)}
                  className="bg-white float-right"
                >
                  <i className="fa-solid fa-x "></i>
                </button>
              </div>
              <h5 className="fw-bolder mb-2">
                <i className="fa-regular fa-lock "></i> Log In to Continue
              </h5>
              <p className="fs-16 my-4">
                To continue, you need to create an account or login to an existing
                account
              </p>
              <div className="d-flex gap-3 justify-content-between mb-3">
                <Link
                  to={{ pathname: '/get-started/', search: 'utm_source=modal' }}
                  className="btn btn-outline-dark rounded-pill py-2 px-2 fw-bold w-full"
                >
                  Create your account
                </Link>
                <Link
                  to={{ pathname: '/sign-in/', search: 'utm_source=modal' }}
                  className="btn btn-primary rounded-pill py-2 px-2 w-full"
                >
                  Log in to your account
                </Link>
              </div>
            </div>
          </Modal>
        </div>
      </DeveloperDashboardLayout>
    )
  }
  
  export default DevInterestDetails
  