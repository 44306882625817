import { useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'

import { OnboardingLayout } from '@layouts/OnboardingLayout'
import { InputComponent } from '@shared/forms'
import { PrimaryButton } from '@shared/buttons'

// import google from '../../assets/icons/google.svg';

import { register, verifyNiesv } from '@services/krent.service'
import Logo from '@shared/Logo'
import { SelectDropdown } from '@shared/forms/SelectComponent'

export const SignUp = () => {
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [preferredName, setPreferredName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [regNumber, setRegNumber] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [agree, setAgree] = useState(false)
  const [error, setError] = useState('')
  const [userNameError, setUserNameError] = useState('')
  const [success, setSuccess] = useState('')
  const [loading, setLoading] = useState<boolean>(false)
  const [togglePassword, setTogglePassword] = useState(false)
  const [referral, setReferral] = useState('')
  const [selectedOption, setSelectedOption] = useState('')
  const [nieszError, setNieszError] = useState('')

  const navigate = useNavigate()
  const { type } = useParams()

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(e.target.value)
    if (e.target.value !== 'yes') {
      setRegNumber('')
      setNieszError('')
    }
  }
  const validateNiesv = async () => {
    try {
      const niesvNumber = regNumber
      await verifyNiesv(niesvNumber)
      return true
    } catch (err) {
      setNieszError(typeof err === 'string' ? err : 'Invalid NIESV number')
      return false
    }
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    setLoading(true)
    setError('')
    setNieszError('')

    try {
      if (type !== 'developer' && !agree) {
        setError('You must agree to the terms and conditions')
        setLoading(false)
        return
      }

      if (preferredName === firstname || preferredName === lastname) {
        setUserNameError(
          'User Name can not be the same with firstname or lastname',
        )
        setLoading(false)
        return
      }

      if (type === 'agent' && selectedOption === 'yes') {
        const isValid = await validateNiesv()
        if (!isValid) {
          setLoading(false)
          return
        }
      }

      const data = {
        firstname,
        lastname,
        email,
        phone: phoneNumber,
        password,
        passwordConfirm: password,
        preferredName,
        niesvNumber:
          type === 'agent' && selectedOption === 'yes' ? regNumber : undefined,
        role:
          type === 'buyer'
            ? 'user'
            : type === 'developer'
            ? 'developer'
            : type === 'seller'
            ? 'landlord'
            : 'agent',
        socialMediaSource: referral,
      }

      if (type === 'developer') {
        navigate('/get-started/developer-upload', { state: data })
      } else {
        const response = await register(data)
        setSuccess(response.message)
        navigate('/redirect', { state: { firstname, lastname, email } })
      }
    } catch (err) {
      setError(typeof err === 'string' ? err : 'Registration failed')
    } finally {
      setLoading(false)
      setTimeout(() => {
        setUserNameError('')
      }, 3000)
    }
  }
  const referrals = [
    { title: 'Krent Customer Service', id: 1 },
    { title: 'Agent', id: 2 },
    { title: 'Instagram', id: 3 },
    { title: 'Twitter', id: 4 },
    { title: 'Facebook', id: 5 },
    { title: 'Google', id: 6 },
  ]
  const dropdownOptions = [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
  ]
  return (
    <OnboardingLayout>
      <div className="container">
        <h3
          className="text-md-start fw-bolder mb-2 text-link text-black mt-3"
          onClick={() => navigate('/')}
        >
          <Logo />
        </h3>

        <h4 className="fw-bolder">
          {type === 'buyer'
            ? 'Create a buyer account'
            : type === 'developer'
            ? 'Create a developer account'
            : type === 'agent'
            ? 'Create an agent account'
            : type === 'property-manager'
            ? 'Create a property manager account'
            : 'Create a seller account'}
        </h4>

        <p className="text-secondary fs-14 mb-4">
          Signing up is easy, let’s get you on board
        </p>

        {error && (
          <span className="text-danger text-center mb-1 fs-14">{error}</span>
        )}
        {success && (
          <span className="text-success text-center mb-1 fs-14">{success}</span>
        )}

        {/* <PrimaryButton
						type='outline'
						text='Continue With Google'
						icon={google}
					/> */}

        <form className="mb-children mt-4 mt-md-3" onSubmit={handleSubmit}>
          <div className="col mb-3">
            <InputComponent
              label="First Name"
              placeholder="Enter your First name"
              icon="user"
              iconType="fa"
              type="text"
              value={firstname}
              change={(e) => setFirstname(e.target.value)}
              required
            />
          </div>

          <div className="col mb-3">
            <InputComponent
              label="Last Name"
              placeholder="Enter your Last name"
              icon="user"
              iconType="fa"
              type="text"
              value={lastname}
              change={(e) => setLastname(e.target.value)}
              required
            />
          </div>

          <div className="col mb-3">
            <InputComponent
              label="User Name"
              placeholder="Enter your username"
              icon="user"
              iconType="fa"
              type="text"
              value={preferredName}
              change={(e) => {
                setPreferredName(e.target.value)
              }}
              required
            />
            {userNameError && (
              <span className="text-danger fs-12">{userNameError}</span>
            )}
          </div>
          {type === 'agent' && (
            <div className="col mb-3">
              <SelectDropdown
                label="Are you a NIESV or a Registered Agent?"
                options={dropdownOptions}
                icon="user"
                iconType="fa"
                value={selectedOption}
                change={handleSelectChange}
                required
              />

              {selectedOption === 'yes' && (
                <div className="mt-3">
                  <InputComponent
                    label="Enter your NIESV/agent number"
                    placeholder="Enter your NIESV/agent number"
                    icon="user"
                    iconType="fa"
                    type="text"
                    value={regNumber}
                    change={(e) => setRegNumber(e.target.value)}
                    required
                  />
                  {nieszError && (
                    <span className="text-danger fs-12">{nieszError}</span>
                  )}
                </div>
              )}
            </div>
          )}

          <div className="col mb-3">
            <InputComponent
              label="Phone Number"
              placeholder="Enter your phone number"
              icon="Call"
              type="text"
              value={phoneNumber}
              change={(e) => setPhoneNumber(e.target.value)}
              required
              length={11}
            />
          </div>

          <div className="col mb-3">
            <InputComponent
              label="Email Address"
              placeholder="Enter your email address"
              icon="Message"
              type="email"
              value={email}
              change={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          {/* <div className='col  mb-3'>
            <div className='row'>
              <div className='col-11'>
                <InputComponent
                  label='Password'
                  placeholder='Enter your password'
                  icon='Lock'
                  type={togglePassword ? 'text' : 'password'}
                  value={password}
                  change={(e) => setPassword(e.target.value)}
                  passwordIcon='open'
                  required
                />
              </div>
              <div className='col-1 d-flex flex-column justify-content-center align-items-center'>
                <span
                  style={{
                    marginTop: '30px',
                  }}
                  className='input-group-text input-group-text-0 '
                >
                  {togglePassword ? (
                    <i
                      className='iconly-Show icli fs-4'
                      onClick={() => setTogglePassword(!togglePassword)}
                      style={{ cursor: 'pointer', color: '#05569f' }}
                    ></i>
                  ) : (
                    <i
                      className='iconly-Hide icli fs-4'
                      onClick={() => setTogglePassword(!togglePassword)}
                      style={{ cursor: 'pointer', color: '#05569f' }}
                    ></i>
                  )}
                </span>
              </div>
            </div>
          </div> */}
          {/* test */}
          <div className="form-group col mb-3">
            <label
              className="text-uppercase fw-600 fs-12 m-1"
              htmlFor="password"
            >
              Password
            </label>
            <div className="input-group">
              <span
                className="input-group-text input-group-text-0 "
                id="basic-addon1"
              >
                <i className={`iconly-Lock icli fs-4`}></i>
              </span>

              <input
                type={togglePassword ? 'text' : 'password'}
                className="form-control form-control-0 ps-3 py-3 border-start-0"
                id="password"
                aria-describedby="emailHelp"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                style={{ borderRight: 'none' }}
              />

              <span className="input-group-text input-group-text-0 ">
                {togglePassword ? (
                  <i
                    className="iconly-Show icli fs-4"
                    onClick={() => setTogglePassword(!togglePassword)}
                    style={{ cursor: 'pointer', color: '#05569f' }}
                  ></i>
                ) : (
                  <i
                    className="iconly-Hide icli fs-4"
                    onClick={() => setTogglePassword(!togglePassword)}
                    style={{ cursor: 'pointer', color: '#05569f' }}
                  ></i>
                )}
              </span>
            </div>
          </div>
          {/* referral */}
          <div className="form-group mb-4">
            <label className="text-uppercase fw-600 fs-12 m-1">
              How did you hear about Krent?
            </label>
            <div className="input-group">
              <span
                className="input-group-text input-group-text-0 "
                id="basic-addon1"
              >
                {/* <i className={`fa-light fa--user icli fs-4`}></i> */}
                <i className={`iconly-Volume-Up icli fs-4`}></i>
              </span>
              <select
                required
                className="form-control form-control-0 ps-3 py-3 border-start-0"
                defaultValue={referral}
                onChange={(e) => {
                  setReferral(e.target.value)
                }}
              >
                <option value="">Select An Option</option>
                {referrals.map((d: any) => {
                  return (
                    <option key={d.id} value={d.title}>
                      {d.title}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>
          {/* test */}
          {type !== 'developer' && (
            <div className="col mb-3">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="terms"
                  checked={agree}
                  id="terms"
                  onChange={() => setAgree(!agree)}
                  required
                />
                <label
                  className="form-check-label fs-14 fw-400"
                  htmlFor="terms"
                >
                  By signing up you agree to our Terms of service and privacy
                  policy.
                </label>
              </div>
            </div>
          )}

          <div className="d-grid">
            <PrimaryButton
              type="primary"
              text={type === 'developer' ? 'Save and continue' : 'Sign up'}
              loading={loading}
            />
          </div>
        </form>

        <p className="text-secondary text-center my-4 my-md-3 fs-14">
          Already have an account?{' '}
          <Link className="text-link" to="/sign-in">
            Sign In
          </Link>
        </p>
      </div>
    </OnboardingLayout>
  )
}
