import { Link } from 'react-router-dom'
// import logo from '../../../assets/images/logo.png';
import logobb from '../../../../assets/images/logobb.png'
import { removeCookie } from '@utils/cookies'
import { useNavigate, useLocation } from 'react-router-dom'
import { logout } from '@services/krent.service'
import { DevNavigationLinks } from '@utils/constants'

// import { useSessionStorage } from '../../../../hooks'

export const DeveloperDashboardSideBar = () => {
  //   const [user, setUser] = useSessionStorage('user', {})
  const navigate = useNavigate()
  const location = useLocation()
  const handleLogout = () => {
    let abortController = new AbortController()
    logout(abortController.signal)
      .then(() => {
        // setUser({})
        window && window.sessionStorage.clear()
        removeCookie('1Q_SPA')
        navigate('/', { replace: true, state: { from: location } })
      })
      .catch((e) => {
        console.log(e)
      })
  }

  return (
    <div className="d-flex flex-column flex-shrink-0 ">
      <div className=' px-2 pt-3 mb-auto"'>
        <div className="row d-flex align-items-center mb-3">
          <div className="d-flex flex-row justify-content-between align-items-center fs-24 px-4 w-100">
            <Link className="navbar-brand fw-bolder align-middle" to="/">
              <span
                style={{
                  width: '120px',
                  display: 'inline-flex',
                  alignItems: 'center',
                }}
              >
                <img
                  src={logobb}
                  alt="Krent"
                  style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                  title="Krent"
                />
              </span>
            </Link>
            <div className="col-6 d-flex text-end justify-content-end d-lg-none">
              <button
                className="navbar-toggler border-0 cursor-pointer"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNavAltMarku"
                aria-controls="navbarNavAltMarku"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="fa fa-bars fs-2 mr-3"></span>
              </button>
            </div>
          </div>
          <div className="col-6 d-flex d-lg-none text-end justify-content-end"></div>
        </div>
        <div  className={`nav-list pt-4 px-3 collapse d-lg-block justify-content-end fs-14 fw-600`}
          id="navbarNavAltMarku">
          {DevNavigationLinks.map((link, idx) => (
            <div key={idx} className="my-1">
              <Link to={link.route || '/'}>
                <p
                  className={`nav-links  ${
                    location.pathname.includes(link?.route || '')
                      ? 'bg-primary-light '
                      : ''
                  }`}
                >
                  <img src={link.icon} alt={link.name} className="w-12" />
                  <span className="w-full">{link.name}</span>
                </p>
              </Link>
            </div>
          ))}
        </div>
      </div>

      <button
        onClick={handleLogout}
        // position-absolute bottom-0
        className="w-100 d-none d-lg-flex py-md-4 px-md-5 fs-18 d-flex flex-row justify-content-start align-items-center gap-2 text-red position-absolute bottom-0"
      >
        <i className="iconly-Login icli fs-22"></i>
        {/* Make API Call */}
        <span className="fs-14">Log Out</span>
      </button>
    </div>
  )
}
