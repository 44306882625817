import React, { useState } from 'react'
import { ProjectCardsProps, ProjectSample } from '@utils/constants'
import Projectcard from '@shared/cards/ProjectCard'
import { DevProject } from '@interfaces/app.interface'

const ITEMS_PER_PAGE = 10

const AllProjects: React.FC<ProjectCardsProps> = ({
  projects,
  pagination = true,
}) => {
  const [currentPage, setCurrentPage] = useState(1)
  const totalPages = Math.ceil(ProjectSample.length / ITEMS_PER_PAGE)

  const handlePrevious = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1))
  }

  const handleNext = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages))
  }

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE
  const endIndex = startIndex + ITEMS_PER_PAGE
  const currentProjects: DevProject[] = projects?.slice(startIndex, endIndex)

  const renderPageNumbers = () => {
    const pageNumbers = []
    const maxVisiblePages = 5

    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2))
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1)

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1)
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => setCurrentPage(i)}
          className={`mx-1 px-3 py-1 rounded-2 ${
            currentPage === i ? 'bg-primary text-white' : 'border'
          }`}
        >
          {i}
        </button>,
      )
    }

    return pageNumbers
  }

  return (
    <div>
      <div>
        {projects?.length === 0 ? (
          <h4 className="text-center my-10">No projects yet!</h4>
        ) : (
          <div className="projects-div">
            {currentProjects?.map((p, idx) => (
              <div key={idx}>
                <Projectcard project={p} />
              </div>
            ))}
          </div>
        )}
      </div>
      {pagination && (
        <div className="mt-4 flex justify-between items-center border p-3 rounded-2">
          <button
            onClick={handlePrevious}
            disabled={currentPage === 1}
            className={`px-3 py-1 rounded-2 fw-bold ${
              currentPage !== 1 ? 'bg-gray-200' : 'current'
            }`}
          >
            Previous
          </button>
          <div>{renderPageNumbers()}</div>
          <button
            onClick={handleNext}
            disabled={currentPage === totalPages}
            className={`px-3 py-1 rounded-2 fw-bold ${
              currentPage !== totalPages ? 'bg-gray-200' : 'current'
            }`}
          >
            Next
          </button>
        </div>
      )}
    </div>
  )
}

export default AllProjects
