import React from 'react'

interface Option {
  value: string
  label: string
}

interface CustomSelectProps {
  label: string
  value: string
  onChange: (value: string) => void
  options: Option[]
  className?: string
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  label,
  value,
  onChange,
  options,
  className,
}) => {
  return (
    <div className={`${className ? className : 'my-4'}`}>
      <label className="fw-bolder form-label text-capitalize">{label}</label>
      <select
        className="form-select rounded-pill py-3 px-4 fs-14 mb-3"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        <option value="">Select {label.toLowerCase()}</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  )
}

export default CustomSelect
