import React from 'react'

interface DevelopersPaginationProps {
  currentPage: number
  totalPages: number
  onPageChange: (page: number) => void
}

const DevelopersPagination: React.FC<DevelopersPaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const handlePrevious = () => {
    onPageChange(Math.max(currentPage - 1, 1))
  }

  const handleNext = () => {
    onPageChange(Math.min(currentPage + 1, totalPages))
  }

  const renderPageNumbers = () => {
    const pageNumbers = []
    const maxVisiblePages = 5

    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2))
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1)

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1)
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => onPageChange(i)}
          className={`mx-1 px-3 py-1 rounded-2 ${
            currentPage === i ? 'bg-primary text-white' : 'bg-white'
          }`}
        >
          {i}
        </button>,
      )
    }

    return pageNumbers
  }

  if (totalPages <= 1) return null

  return (
    <div className="mt-4 flex justify-between items-center border p-3 rounded-2">
      <button
        onClick={handlePrevious}
        disabled={currentPage === 1}
        className={`px-3 py-1 rounded-2 fw-bold ${
          currentPage !== 1 ? 'bg-gray-200' : 'current'
        }`}
      >
        Previous
      </button>
      <div>{renderPageNumbers()}</div>
      <button
        onClick={handleNext}
        disabled={currentPage === totalPages}
        className={`px-3 py-1 rounded-2 fw-bold ${
          currentPage !== totalPages ? 'bg-gray-200' : 'current'
        }`}
      >
        Next
      </button>
    </div>
  )
}

export default DevelopersPagination
