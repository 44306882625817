import React, { useState, useEffect, useRef } from 'react'

interface PropertyType {
  value: string
  label: string
}

interface MultiSelectPropertyTypeProps {
  title?: string
  placeholder?: string
  options: PropertyType[] 
  selectedOptions: string[]
  setSelectedOptions: (selected: string[]) => void
}

const MultiSelectPropertyType: React.FC<MultiSelectPropertyTypeProps> = ({
  title = 'What is the project property type',
  placeholder = 'Select property types...',
  options,
  selectedOptions,
  setSelectedOptions,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const containerRef = useRef<HTMLDivElement>(null)

  const toggleOption = (value: string): void => {
    setSelectedOptions(
      selectedOptions.includes(value)
        ? selectedOptions.filter((item) => item !== value)
        : [...selectedOptions, value],
    )
  }

  const removeOption = (value: string): void => {
    setSelectedOptions(selectedOptions.filter((item) => item !== value))
  }

  const toggleDropdown = (): void => setIsOpen(!isOpen)

  // Close dropdown when clicking outside of the component
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div className="my-4" ref={containerRef}>
      <label className="fw-bolder form-label text-capitalize">{title}</label>
      <div className="custom-select-container">
        <div
          className="custom-select-header rounded-pill py-2 px-3 fs-14"
          onClick={toggleDropdown}
        >
          {selectedOptions.length > 0 ? (
            <div className="selected-tags">
              {selectedOptions.map((value) => (
                <span key={value} className="selected-tag">
                  {options.find((option) => option.value === value)?.label}
                  <button
                    className="tag-remove-btn"
                    onClick={(e: React.MouseEvent) => {
                      e.stopPropagation()
                      removeOption(value)
                    }}
                  >
                    ×
                  </button>
                </span>
              ))}
            </div>
          ) : (
            placeholder
          )}
        </div>
        <p>You can add more than 1</p>

        {isOpen && (
          <div className="custom-select-options">
            {options.map((option) => (
              <div
                key={option.value}
                className={`custom-select-option ${
                  selectedOptions.includes(option.value) ? 'selected' : ''
                }`}
                onClick={() => toggleOption(option.value)}
              >
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default MultiSelectPropertyType
