import React, { useState, ChangeEvent } from 'react'
import image from '../../../assets/images/profile-pic.png'
import ProfileImageUpload from './image-upload'
import { DeveloperDashboardLayout } from '@layouts/DeveloperDashboardLayout'

const EditDeveloperProfile: React.FC = () => {
  const [profileImage, setProfileImage] = useState<string>(image)
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [username, setUsername] = useState('')
  const [description, setDescription] = useState('')
  const [companyWebsite, setCompanyWebsite] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [email, setEmail] = useState('')
  const [address, setAddress] = useState('')
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [isAccountInfoOpen, setIsAccountInfoOpen] = useState(true)
  const [isVerificationCenterOpen, setIsVerificationCenterOpen] = useState(true)

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setProfileImage(reader.result as string)
      }
      reader.readAsDataURL(file)
    }
  }
  const toggleAccountInfo = () => setIsAccountInfoOpen(!isAccountInfoOpen)
  const toggleVerificationCenter = () =>
    setIsVerificationCenterOpen(!isVerificationCenterOpen)

  return (
    <DeveloperDashboardLayout title="Edit profile">
      <div>
        <div className="flex items-center gap-2">
          <p>Home</p>
          <i className="fa-light fa-angle-right"></i>
          <p className="">Dashboard</p>
          <i className="fa-light fa-angle-right"></i>
          <p className="text-primary">Profile</p>
        </div>
        <div>
          <div className="flex justify-center my-4">
            <ProfileImageUpload
              image={profileImage}
              onImageChange={handleImageChange}
            />
          </div>
          <div>
            <div className="flex justify-between  items-center">
              <div className="flex items-center gap-2 ">
                <i className="fa-regular fa-user text-primary"></i>

                <h6>Personal Information</h6>
              </div>
              <button
                onClick={toggleAccountInfo}
                className="bg-transparent border-0"
              >
                <i
                  className={`fa-regular ${
                    isAccountInfoOpen ? 'fa-angle-up' : 'fa-angle-down'
                  }`}
                ></i>
              </button>
            </div>
            <hr />
            {isAccountInfoOpen && (
              <div>
                <div className="flex gap-40">
                  <div className="w-20">
                    <h6>Account Info</h6>
                    <p className="no-text-wrap">
                      This will be displayed on your profile
                    </p>
                  </div>
                  <div className="w-full">
                    <div className="flex gap-20 mb-4">
                      <div className="w-full">
                        <label className="fw-bolder form-label text-capitalize">
                          First name
                        </label>
                        <input
                          type="text"
                          className="form-control rounded-pill py-3 px-4 fs-14"
                          defaultValue={firstname}
                          onChange={(e) => {
                            setFirstname(e.target.value)
                          }}
                        />
                      </div>
                      <div className="w-full">
                        <label className="fw-bolder form-label text-capitalize">
                          Last name
                        </label>
                        <input
                          type="text"
                          className="form-control rounded-pill py-3 px-4 fs-14"
                          defaultValue={lastname}
                          onChange={(e) => {
                            setLastname(e.target.value)
                          }}
                        />
                      </div>
                    </div>
                    <div className="w-full my-4">
                      <label className="fw-bolder form-label text-capitalize">
                        Company name
                      </label>
                      <input
                        type="text"
                        className="form-control rounded-pill py-3 px-4 fs-14"
                        defaultValue={companyName}
                        onChange={(e) => {
                          setCompanyName(e.target.value)
                        }}
                      />
                    </div>
                    <div className="w-full my-4">
                      <label className="fw-bolder form-label text-capitalize">
                        Username
                      </label>
                      <input
                        type="text"
                        className="form-control rounded-pill py-3 px-4 fs-14"
                        defaultValue={username}
                        onChange={(e) => {
                          setUsername(e.target.value)
                        }}
                      />
                    </div>
                    <div className="my-4">
                      <label className="fw-bolder form-label text-capitalize">
                        Bio (Company details)
                      </label>
                      <textarea
                        className="form-control form-control-0 ps-3 py-3 border"
                        placeholder=""
                        required
                        rows={5}
                        value={description}
                        onChange={(e) => {
                          setDescription(e.target.value)
                        }}
                      />
                    </div>
                    <div className="w-full my-4">
                      <label className="fw-bolder form-label text-capitalize">
                        Link to company website
                      </label>
                      <input
                        type="text"
                        className="form-control rounded-pill py-3 px-4 fs-14"
                        defaultValue={companyWebsite}
                        onChange={(e) => {
                          setCompanyWebsite(e.target.value)
                        }}
                      />
                    </div>
                    <div className="w-full my-4">
                      <label className="fw-bolder form-label text-capitalize">
                        Phone number
                      </label>
                      <input
                        type="text"
                        className="form-control rounded-pill py-3 px-4 fs-14"
                        defaultValue={phoneNumber}
                        onChange={(e) => {
                          setPhoneNumber(e.target.value)
                        }}
                      />
                    </div>
                    <div className="w-full my-4">
                      <label className="fw-bolder form-label text-capitalize">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control rounded-pill py-3 px-4 fs-14"
                        defaultValue={email}
                        onChange={(e) => {
                          setEmail(e.target.value)
                        }}
                      />
                    </div>
                    <div className="w-full my-4">
                      <label className="fw-bolder form-label text-capitalize">
                        Address
                      </label>
                      <input
                        type="text"
                        className="form-control rounded-pill py-3 px-4 fs-14"
                        defaultValue={address}
                        onChange={(e) => {
                          setAddress(e.target.value)
                        }}
                      />
                    </div>
                  </div>
                </div>
                <hr />
                <div className="flex gap-40">
                  <div className="w-20">
                    <h6>Login Details</h6>
                    <p className="no-text-wrap">Modify your password</p>
                  </div>
                  <div className="w-full">
                    <div className="w-full">
                      <label className="fw-bolder form-label text-capitalize">
                        Current Password
                      </label>
                      <input
                        type="password"
                        className="form-control rounded-pill py-3 px-4 fs-14"
                        defaultValue={currentPassword}
                        onChange={(e) => {
                          setCurrentPassword(e.target.value)
                        }}
                      />
                    </div>
                    <div className="w-full my-4">
                      <label className="fw-bolder form-label text-capitalize">
                        New Password
                      </label>
                      <input
                        type="password"
                        className="form-control rounded-pill py-3 px-4 fs-14"
                        defaultValue={newPassword}
                        onChange={(e) => {
                          setNewPassword(e.target.value)
                        }}
                      />
                    </div>
                    <div className="w-full my-4">
                      <label className="fw-bolder form-label text-capitalize">
                        Confirm New Password
                      </label>
                      <input
                        type="password"
                        className="form-control rounded-pill py-3 px-4 fs-14"
                        defaultValue={confirmNewPassword}
                        onChange={(e) => {
                          setConfirmNewPassword(e.target.value)
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div>
            <div className="flex justify-between  items-center">
              <div className="flex items-center gap-2 ">
                <i className="fa-regular fa-circle-check text-primary"></i>

                <h6>Verification Center</h6>
              </div>
              <button
                onClick={toggleVerificationCenter}
                className="bg-transparent border-0"
              >
                <i
                  className={`fa-regular ${
                    isVerificationCenterOpen ? 'fa-angle-up' : 'fa-angle-down'
                  }`}
                ></i>
              </button>
            </div>
            <hr />
            {isVerificationCenterOpen && (
              <div>
                <div className="flex gap-40">
                  <div className="w-20">
                    <h6>Upload Credentials</h6>
                    <p className="">
                      Choose credential type and upload. This will be displayed
                      on your profile
                    </p>
                  </div>
                  <div className="w-full">Credential type</div>
                </div>
              </div>
            )}
          </div>
          <div className="w-max ml-auto my-4">
            <button
              className="fw-bold text-white bg-primary rounded-10 p-2 px-3 flex gap-2 items-center"
              // onClick={handleEditProfileDetails}
            >
              <i className="fa-sharp-duotone fa-solid fa-pen-field"></i>
              Save preferences
            </button>
          </div>
        </div>
      </div>
    </DeveloperDashboardLayout>
  )
}

export default EditDeveloperProfile
