import React, { useState, ChangeEvent, FC } from 'react'

interface SearchProps {
  placeholder?: string
}

const Search: FC<SearchProps> = ({ placeholder = 'Search...' }) => {
  const [searchTerm, setSearchTerm] = useState<string>('')

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  return (
    <div className="search-container">
      <input
        type="text"
        placeholder={placeholder}
        value={searchTerm}
        onChange={handleInputChange}
        className={`search-input ${searchTerm ? 'has-value' : ''}`}
      />
      {!searchTerm && (
        <i className="fa-solid fa-magnifying-glass search-icon"></i>
      )}
    </div>
  )
}

export default Search
