import React from 'react'

interface TableEmptyStateProps {
  title?: string
  subtitle?: string
}

const TableEmptyState: React.FC<TableEmptyStateProps> = ({
  title,
  subtitle,
}) => {
  return (
    <div className="flex flex-col items-center space-y-3">
      <div className="border-4 border-[#00B89C0D] bg-[#D3FFE7] p-3 rounded-full w-max">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 19L14.65 14.65M17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9Z"
            stroke="#00B89C"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div>
        <p className="text-[#101828] font-semibold">
          {title || 'No Property found'}
        </p>
        <p className="text-[#667085] text-sm">
          {subtitle || 'No Property found. Please try again'}
        </p>
      </div>
    </div>
  )
}

export default TableEmptyState
