import {Fragment, useEffect, useState} from 'react';

import {DashboardLayout} from '@layouts/index';

// import {Modal} from '@shared/controls/Modal';

import {
  RequestCard,
  RequestCardMobile,
  //  RequestViewCard
} from '@shared/cards';

// import { requests } from './data';

import notification from '../../assets/images/notifications.png';
import {
  // acceptTransaction,
  getPropertyTransactions,
} from '@services/krent.service'
import { useSessionStorage } from '../../hooks'
import ktoast from '@services/toast.service'
import { differenceInDays } from 'date-fns'
import DevTable from '@shared/Table/Dev/Table'
import { recent_requests_data } from '@utils/constants'
import { NotificationsInterfaceResponse } from '@interfaces/notifications.interface'
import {
  AgentRequestAction,
  completedInspection,
  getAgentRequest,
  getComments,
} from '@services/krentDeveloper'
import { Modal } from 'react-bootstrap'
import UploadReceipt from 'components/UsersDashboard/uploadReceipt'
import { toast } from 'react-toastify'
import { formatDateToTime } from '@utils/helpers'

interface Request {
  requestDate: string
  interestID: string
  propertyName: string
  status: string
  clientName: string
}
const steps = [
  'Request accepted',
  'Inspection confirmed',
  'Payment made',
  'Documentation process',
  'Documentation received',
]

export const Requests = () => {
  // const [showModal, setShowModal] = useState<boolean>(false);
  const [tab, setTab] = useState<string>('shortlet');
  const [intent, setIntent] = useState<string>('shortlet');
  const [search, setSearch] = useState<string>('');
  // const [id, setId] = useState<string>();
  const [user] = useSessionStorage('user', {});
  const [loading, setLoading] = useState<boolean>(false);
  const [transactions, setTransactions] = useState<any[]>([]);
  // const [request, setRequest] = useState<any>({});
  const [requests, setRequests] = useState<NotificationsInterfaceResponse[]>([])
  const [pendingModal, setPendingModal] = useState(false)
  const [acceptedModal, setAcceptedModal] = useState(false)
  const [selectedRequest, setSelectedRequest] = useState<Request | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [showProceedtoUploadDoc, setShowProceedtoUploadDoc] = useState(false)
  const [isAgentUpload, setIsAgentUpload] = useState(false)
  const [purchaseUpdate, setPurchaseUpdate] = useState(false)
  const [questionModal, setQuestionModal] = useState(false)
  const [devComments, setDevComments] = useState([])

  const handleAction = (action: any) => {
    setIsLoading(true)
    const abortController = new AbortController()
    if (selectedRequest) {
      AgentRequestAction(
        action,
        selectedRequest.interestID,
        abortController.signal,
      )
        .then((res) => {
          console.log(res)
          ktoast.success(`Request ${action}ed successfully`)
          setRequests((prevRequests) =>
            prevRequests.map((req) =>
              req.id === selectedRequest.interestID
                ? {
                    ...req,
                    status: action === 'accept' ? 'Accepted' : 'Rejected',
                  }
                : req,
            ),
          )
          setPendingModal(false)
          setTransactions((prevTransactions) =>
            prevTransactions.map((transaction) =>
              transaction.id === selectedRequest.interestID
                ? {
                    ...transaction,
                    status: action === 'accept' ? 'Accepted' : 'Rejected',
                  }
                : transaction,
            ),
          )
        })
        .catch((err) => {
          ktoast.error(`Failed to ${action} request: ${err || err.message}`)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
    return () => {
      abortController.abort()
    }
  }
  const tabList: string[] = ['shortlet', 'rent', 'sale', 'iBuying']

  const headings: string[] = [
    'PROPERTY',
    'COST PER NIGHT',
    'REQUEST FROM',
    'DATE OF REQUEST',
    'STATUS',
    'STAY DURATION',
    'ACTIONS',
  ];
  const headings2: string[] = [
    'PROPERTY',
    'COST PER NIGHT',
    'REQUEST FROM',
    'DATE OF REQUEST',
    'STATUS',
    'ACTIONS',
  ];

  // const filterRequests = (requests: any[], tab: string) => {
  // 	return requests.filter((request: any) => request.type === tab);
  // };

  const filteredTransaction = transactions?.filter(
    (transaction) => transaction?.listing?.intent === intent,
  )
  const handleInspectionCompleted = async () => {
    if (!selectedRequest) return {}
    setIsLoading(true)
    try {
      const abortController = new AbortController()
      const res = await completedInspection(
        selectedRequest.interestID,
        abortController.signal,
      )
      toast.success(res.message)
      setTimeout(() => {
        setQuestionModal(false)
      }, 2000)
    } catch (err) {
      console.error('Error completing inspection:', err)
    } finally {
      setIsLoading(false)
    }
  }

  const fetchComments = async (itemId: string) => {
    const abortController = new AbortController()
    try {
      const response = await getComments(itemId, abortController.signal)
      setDevComments(response.data)
    } catch (error) {
      console.error('Error fetching comments:', error)
    }
  }

  useEffect(() => {
    if (selectedRequest?.interestID) {
      fetchComments(selectedRequest.interestID)
    }

    // if (item?._id) {
    //   fetchClientDocument(item._id)
    // }
  }, [selectedRequest])
  useEffect(() => {
    const abortController = new AbortController();
    setLoading(true);

    getPropertyTransactions(user?.id, abortController.signal)
      .then((resp: any) => {
        setTransactions(resp.data);
      })
      .catch(() => {
        ktoast.error('Failed to load Transasctions');
      })
      .finally(() => {
        setLoading(false)
      })
  }, [user])
  useEffect(() => {
    const abortController = new AbortController()

    getAgentRequest(abortController.signal)
      .then((res) => {
        setRequests(res)
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          console.log(err)
        }
      })

    return () => {
      abortController.abort()
    }
  }, [])

  // const acceptRequest = (id: any) => {
  //   acceptTransaction(id!)
  //     .then((resp: any) => {
  //       console.log(resp.data);

  //       ktoast.success('Transasctions Accepted');
  //     })
  //     .catch((err) => {
  //       console.log(err);

  //       ktoast.error(err.errors[0].message);
  //     });
  // };
  // const showRequest = (req: any) => {
  //   setRequest(req);
  //   setShowModal(true);
  // };
  const renderStatusCell = (cell: any) => {
    if (cell.column.id === 'action') {
      const status = cell.row.original.status
      const isRejected = status === 'Rejected'

      const handleActionButtonClick = (e: any) => {
        e.preventDefault()
        setSelectedRequest(cell.row.original)

        const pendingStatuses = ['Interest', 'Pending']
        const acceptedStatuses = [
          'Accepted',
          'Inspection Booked',
          'Inspection Completed',
        ]

        if (pendingStatuses.includes(status)) {
          setPendingModal(true)
        } else if (acceptedStatuses.includes(status)) {
          setAcceptedModal(true)
        } else if (status === 'Purchase-Request') {
          setIsAgentUpload(true)
          setShowProceedtoUploadDoc(true)
        }
      }

      return (
        <div className="flex items-center justify-between">
          <button
            className={`rounded-10 p-2 border-green text-primary fw-bold border-green bg-white ${
              isRejected ? 'opacity-50 light-text cursor-not-allowed' : ''
            }`}
            onClick={handleActionButtonClick}
            disabled={isRejected}
          >
            View details
          </button>
        </div>
      )
    }
    if (cell.column.id === 'status') {
      const isCompleted = cell.value === 'Accepted'
      const isRejected = cell.value === 'Rejected'

      return (
        <div className="flex items-center justify-between">
          <span
            className={`rounded-10 p-2 ${
              isRejected ? 'previous ' : isCompleted ? 'current ' : 'future '
            } `}
          >
            {cell.value === 'Interest' ||
            cell.value === 'Purchase-request' ||
            cell.value === 'Pending'
              ? 'Pending'
              : cell.value}
          </span>
        </div>
      )
    }
    if (cell.column.id === 'details') {
      return (
        <div className="flex items-center justify-between">
          <button
            className="text-black border rounded-10 bg-white flex items-center"
            onClick={() => {
              setSelectedRequest(cell.row.original)
              setPurchaseUpdate(true)
            }}
          >
            <span>View purchase update</span>&nbsp;
            <i className="fa fa-arrow-up-right mr-2" />
          </button>
        </div>
      )
    }
    return cell.render('Cell')
  }
  const getCurrentStep = (selectedRequest: any) => {

    if (!selectedRequest?.status) return 0

    if (selectedRequest.status === 'Inspection Booked') return 1

    if (selectedRequest.status === 'Inspection Completed') return 2

    if (selectedRequest.status === 'confirmed' ||"Purchase-Request") return 3

    if (selectedRequest.status === 'Documentation process') return 4
    if (selectedRequest.status === 'Doc completed') return 5


    return 0
  }
  return (
    <DashboardLayout>
      <div className='row mb-3'>
        <h3 className='fw-bold mb-1'>Requests</h3>
        <p className='text-secondary fs-14'>
          Manage all requests on your property
        </p>
      </div>
    
      <div className="row">
        <div className="col-12">
          {loading ? (
            <p>Loading</p>
          ) : transactions && transactions.length > 0 ? (
            <Fragment>
              <div className="card border-0 border-round shadow-sm d-none d-md-flex">
                <div className="row">
                  {tabList.map((tabItem, i) => (
                    <div
                      className="col-2 text-center d-flex flex-column justify-content-end btn-el"
                      onClick={() => {
                        setTab(tabItem)
                        setIntent(
                          tabItem === 'shortlet'
                            ? 'shortlet'
                            : tabItem === 'rent'
                            ? 'long lease'
                            : tabItem === 'sale'
                            ? 'sale'
                            : 'iBuying',
                        )
                      }}
                      key={i}
                    >
                      <p
                        className={
                          tab === tabItem
                            ? 'fw-bold text-primary'
                            : 'light-text'
                        }
                        style={{
                          paddingBottom: '5px',
                          borderBottom: `${
                            tab === tabItem
                              ? '2px solid #1073CE'
                              : '2px solid #fff'
                          }`,
                        }}
                      >
                        {tabItem === 'iBuying'
                          ? 'i Buying'
                          : tabItem.replace(/\b[a-z]/g, (x) => x.toUpperCase())}
                      </p>
                    </div>
                  ))}

                  <div className="col-4 p-3 d-flex flex-row justify-content-center align-items-center gap-2">
                    <input
                      type="text"
                      className="form-control border-none"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    {/* <i className='iconly-Search icli fs-22 mr-2' /> */}
                  </div>
                </div>
                <table className="table table-borderless fs-14 d-none d-md-table">
                  <thead
                    className="w-100"
                    style={{ backgroundColor: '#F7F8FF', color: '#0E1956' }}
                  >
                    {intent === 'sale' || intent === 'iBuying' ? (
                      <tr>
                        {headings2.map((heading, index) => (
                          <th className="p-3 fs-12" key={index} scope="col">
                            {heading}
                          </th>
                        ))}
                      </tr>
                    ) : (
                      <tr>
                        {headings.map((heading, index) => (
                          <th className="p-3 fs-12" key={index} scope="col">
                            {heading}
                          </th>
                        ))}
                      </tr>
                    )}
                  </thead>
                  <tbody
                    className="table-group-divider"
                    style={{
                      color: '#0F0C3D',
                    }}
                  >
                    {filteredTransaction.length > 0 ? (
                      filteredTransaction.map((request) => {
                        const stayDuration = differenceInDays(
                          new Date(request?.departure_date),
                          new Date(request?.arrival_date),
                        )

                        return (
                          <RequestCard
                            id={request.id}
                            key={request.id}
                            property={request?.listing?.title}
                            propertyType={request?.listing?.intent}
                            cost={request?.listing?.price}
                            duration={'3'}
                            intent={intent}
                            listed={request?.createdAt}
                            status={request?.status}
                            stay={stayDuration}
                            from={
                              request?.renter?.name ||
                              request?.renter?.prefferedName ||
                              `${request?.renter?.firstname} ${request?.renter?.lastname}`
                            }
                            // setShowModal={() => showRequest(request)}
                            // loadRequest={() => showRequest(request)}
                            // setId={setId}
                            cardType="request"
                          />
                        )
                      })
                    ) : (
                      <p className="p-5 fw-bold">No Request</p>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="d-block d-md-none p-1">
                <div className="col-12 mb-3">
                  <input
                    type="text"
                    className="form-control border-round-xl bg-purple p-2 px-4 mb-2"
                    placeholder="Search..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <select
                    className="form-select border-round-xl bg-purple p-2 px-4 mb-2"
                    onChange={(e) => {
                      setTab(e.target.value)
                      setIntent(e.target.value)
                    }}
                  >
                    <option defaultValue={tab}>Select</option>
                    <option value="shortlet">Shortlet</option>
                    <option value="long lease">Rental</option>
                    <option value="sale">Sale</option>
                  </select>
                </div>
                <div className="col-12">
                  {transactions
                    ?.filter((trans) => trans?.listing?.intent === intent)
                    .map((request) => {
                      const stayDuration = differenceInDays(
                        new Date(request?.departure_date),
                        new Date(request?.arrival_date),
                      )
                      return (
                        <RequestCardMobile
                          id={request.id}
                          key={request.id}
                          property={request?.listing?.title}
                          propertyType={request?.listing?.intent}
                          cost={request?.listing?.price}
                          duration={'3'}
                          intent={intent}
                          listed={request?.createdAt}
                          status={request?.status}
                          stay={stayDuration}
                          from={request?.renter?.name}
                          // setShowModal={() => showRequest(request)}
                          // loadRequest={() => showRequest(request)}
                          // setId={setId}
                          cardType="request"
                        />
                      )
                    })}
                </div>
              </div>
            </Fragment>
          ) : (
            <div className="card border-0 border-round shadow-sm p-5 text-center d-flex align-items-center">
              <h6 className="fs-18 mt-1 fw-bold mb-4">No requests yet</h6>
              <img src={notification} alt="No transactions" width={200} />
              <p className="fs-14 light-text">
                Any request you receive here would show up here.
                <br />
                You don't have any requests right now
              </p>
            </div>
          )}
        </div>
      </div>
      <div className='mt-4'>
        <h5 className='py-2'>Developer Project Requests</h5>
        <DevTable
          columnData={recent_requests_data}
          rowData={requests}
          label="sold properties"
          totalDocs={requests?.length}
          renderCell={renderStatusCell}
        />
        <Modal show={pendingModal} onHide={() => setPendingModal(false)}>
          <div className="container p-4">
            <p className="fw-bold ">Someone has made a request for an agent</p>
            <p className="py-2">
              You have been selected to be an agent for this property: Which
              means an interest has been made for the property
            </p>
            <p>Please take prompt action by accepting or rejecting</p>
            <div className="w-full flex justify-between my-4 gap-4">
              <button
                onClick={() => handleAction('accept')}
                className="btn btn-primary rounded-pill py-2 text-white fw-bold w-full"
                disabled={isLoading}
              >
                {isLoading ? 'Processing...' : 'Accept'}
              </button>
              <button
                onClick={() => handleAction('reject')}
                className="btn border-red text-red bg-white rounded-pill py-2 fw-bold w-full"
                disabled={isLoading}
              >
                {isLoading ? 'Processing...' : 'Reject'}
              </button>
            </div>
          </div>
        </Modal>
        <Modal show={purchaseUpdate} onHide={() => setPurchaseUpdate(false)} size="lg">
          <div className="container p-4">
            <div className="flex justify-between items-center">
              <p className="fw-bold ">Purchase update</p>
              <button
                className="fw-bold bg-white"
                onClick={() => setPurchaseUpdate(false)}
              >
                X
              </button>
            </div>
            <hr />
            <div>
              <div
                className="stepper-container"
                style={
                  {
                    '--total-steps': 5,
                    '--current-step': getCurrentStep(selectedRequest),
                  } as React.CSSProperties
                }
              >
                {Array.from({ length: 5 }, (_, index) => (
                  <div key={index} className="w-full">
                    <div
                      className={`step-item ${
                        index < getCurrentStep(selectedRequest) ? 'active' : ''
                      }`}
                    >
                      <div className="step-number">{index + 1}</div>
                      {index < 4 && <div className="step-line"></div>}
                    </div>
                    <div className="mt-2 fs-12">{steps[index]}</div>
                  </div>
                ))}
              </div>
            </div>
            <hr />
            <div>
              {devComments.length > 0 && (
                <>
                  <hr />
                  <div>
                    <h6 className="pb-2">Developer comments</h6>
                    {devComments.map((comment: any, idx: any) => (
                      <div key={idx} className="w-15">
                        <div className="border p-2 rounded-10">
                          <p>{comment.content}</p>
                        </div>
                        <p className="fs-10 py-2 w-max ml-auto">
                          {formatDateToTime(comment.timestamp)}
                        </p>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </Modal>
        <Modal
          show={acceptedModal}
          onHide={() => setAcceptedModal(false)}
          size="sm"
        >
          <div className="container py-3">
            <p className="fw-bold ">
              You have accepted the request for an agent
            </p>
            <p>
              You have accepted to be an agent for this property:{' '}
              <span className="text-primary capitalize">
                {selectedRequest?.propertyName}
              </span>{' '}
              <span className="fw-bold">for {selectedRequest?.clientName}</span>
              .
            </p>
            <p className="my-2">
              Has the inspection been carried out yet? Click to upload proof of
              payment
            </p>
            <div className="text-center my-4">
              {selectedRequest?.status === 'Inspection Booked' ? (
                <button
                  className="bg-primary rounded-10 text-white p-2 h-max"
                  onClick={() => {
                    // setModalAction('completed')
                    setQuestionModal(true)
                  }}
                  disabled={isLoading}
                >
                  Inspection completed?
                </button>
              ) : (
                <button
                  className="bg-black rounded-10 text-white p-2 h-max"
                  onClick={() => {
                    setAcceptedModal(false)
                    setIsAgentUpload(true)
                    setShowProceedtoUploadDoc(true)
                  }}
                >
                  Proceed to upload documents
                </button>
              )}

              {/* <button
                className="bg-black rounded-10 text-white p-2 h-max"
                onClick={() => {
                  // setAcceptedModal(false)
                  setIsAgentUpload(true)
                  setShowProceedtoUploadDoc(true)
                }}
              >
                Proceed to upload documents
              </button> */}
            </div>
          </div>
        </Modal>
        <UploadReceipt
          show={showProceedtoUploadDoc}
          onClose={() => {
            setIsAgentUpload(false)
            setShowProceedtoUploadDoc(false)
          }}
          propertyId={selectedRequest?.interestID}
          item={selectedRequest}
          isAgentUpload={isAgentUpload}
        ></UploadReceipt>
        <Modal
          show={questionModal}
          onClose={() => setQuestionModal(false)}
          dismissible={false}
          // size="sm"
        >
          <div className="p-4">
            <div className="flex justify-between gap-2">
              <h5 className="w-full no-text-wrap">
                Has Inspection been completed?
              </h5>
              <button
                onClick={() => setQuestionModal(false)}
                className="bg-white"
              >
                <i className="fa-regular fa-x"></i>
              </button>
            </div>
            <div className="flex gap-2 my-4">
              <button
                onClick={handleInspectionCompleted}
                className="bg-primary rounded-10 p-2 w-full text-white"
              >
                Yes
              </button>
              <button
                onClick={() => setQuestionModal(false)}
                className="border-red text-red bg-white rounded-10 p-2 w-full"
              >
                No
              </button>
            </div>
          </div>
        </Modal>

        {/* <Modal
          show={showProceedtoUploadDoc}
          onHide={() => setShowProceedtoUploadDoc(false)}
        >
          <div>
            <h5>Upload client documents</h5>
          </div>
        </Modal> */}
      </div>


      {/* <Modal show={showModal} onClose={() => setShowModal(false)}>
        {request.id && <RequestViewCard req={request} accept={() => {}} />}
      </Modal> */}
    </DashboardLayout>
  );
};
