import Tabs from '@shared/Tabs'
import React from 'react'
import SalesInterest from './sales-interest'
import SoldSales from './sold-sales'
import { DeveloperDashboardLayout } from '@layouts/DeveloperDashboardLayout'

const DevelopersSales = () => {
  const components = {
    'Shown interest': <SalesInterest />,
    'Sales pipelines': <SoldSales />,
  }
  return (
    <DeveloperDashboardLayout title="My Sales">
      <div>
        <div className="flex items-center gap-2">
          <p>Home</p>
          <i className="fa-light fa-angle-right"></i>
          <p className="">Dashboard</p>
          <i className="fa-light fa-angle-right"></i>
          <p className="text-primary">Sales</p>
        </div>
        <div className="my-10">
          <Tabs components={components} roundedTabs />
        </div>
      </div>
    </DeveloperDashboardLayout>
  )
}

export default DevelopersSales
