import { OnboardingLayout } from '@layouts/OnboardingLayout'
import { registerDeveloper } from '@services/krentDeveloper'
import { PrimaryButton } from '@shared/buttons'
import { InputComponent } from '@shared/forms'
import ImageUpload from '@shared/image-upload/ImageUpload'
import Logo from '@shared/Logo'
import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

interface RegistrationState {
  firstname: string
  lastname: string
  email: string
  preferredName: string
  phone: string
}

const DeveloperUpload = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { state } = location as { state: RegistrationState | null }
  const [taxId, setTaxId] = useState('')
  const [websiteLink, setWebsiteLink] = useState('')
  const [details, setDetails] = useState('')
  const [agree, setAgree] = useState(false)
  const [success, setSuccess] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState<boolean>(false)
  const [profilePicture, setProfilePicture] = useState<File | null>(null)

  const { firstname = '', lastname = '', email = '' } = state || {}
  const handleImageUpload = (file: File): void => {
    setProfilePicture(file)
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setLoading(true)
    setError('')

    if (!agree) {
      setError('Please agree to the Terms of Service and Privacy Policy')
      setLoading(false)
      return
    }

    const formData = new FormData()

    Object.entries(state || {}).forEach(([key, value]) => {
      formData.append(key, value)
    })

    formData.append('taxId', taxId)
    formData.append('websiteLink', websiteLink)
    formData.append('details', details)
    if (profilePicture) {
      formData.append('profilePicture', profilePicture)
    }

    try {
      await registerDeveloper(formData)
      setSuccess('Account created')
      navigate('/redirect', { state: { firstname, lastname, email } })
    } catch (err) {
      setError('An error occurred, please try again!')
      setTimeout(() => {
        setError('')
        setLoading(false)
      }, 3000)
    } finally {
      setLoading(false)
    }
  }

  return (
    <OnboardingLayout>
      <div className="container">
        <h3
          className="text-md-start fw-bolder mb-2 text-link text-black mt-3"
          onClick={() => navigate('/')}
        >
          <Logo />
        </h3>

        <h4 className="fw-bolder">Create a developer account</h4>

        <p className="text-secondary fs-14 mb-4">
          Signing up is easy, let's get you on board
        </p>

        <form onSubmit={handleSubmit}>
          <div>
            <ImageUpload
              title="Enter your company image"
              onImageUpload={handleImageUpload}
            />
          </div>
          <div className="col mb-3">
            <InputComponent
              label="Tax identification number"
              placeholder="Enter  number"
              icon="id-badge"
              iconType="fa"
              type="text"
              value={taxId}
              change={(e) => {
                setTaxId(e.target.value)
              }}
              required
            />
          </div>
          <div className="col mb-3">
            <InputComponent
              label="Link to company website"
              placeholder="Enter link"
              icon="link"
              iconType="fa"
              type="text"
              value={websiteLink}
              change={(e) => {
                setWebsiteLink(e.target.value)
              }}
            />
          </div>
          <div className="col mb-3">
            <InputComponent
              type="text"
              label="Bio(Company details)"
              placeholder="Enter your company history"
              iconType="fa"
              value={details}
              change={(e) => setDetails(e.target.value)}
              required
              isTextArea={true}
              rows={6}
            />
          </div>
          <div className="col mb-3">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                name="terms"
                checked={agree}
                id="terms"
                onChange={() => setAgree(!agree)}
                required
              />
              <label className="form-check-label fs-14 fw-400" htmlFor="terms">
                By signing up you agree to our Terms of service and privacy
                policy.
              </label>
              {error && (
                <span className="text-danger text-center mb-1 fs-14">
                  {error}
                </span>
              )}
              {success && (
                <span className="text-success text-center mb-1 fs-14">
                  {success}
                </span>
              )}
            </div>
          </div>
          <div className="d-grid">
            <PrimaryButton
              type="primary"
              text="Create account"
              loading={loading}
            />
          </div>
        </form>
        <p className="text-secondary text-center my-4 my-md-3 fs-14">
          Already have an account?{' '}
          <Link className="text-link" to="/sign-in">
            Sign In
          </Link>
        </p>
      </div>
    </OnboardingLayout>
  )
}

export default DeveloperUpload
