import React from 'react'
import Search from './search'
import SortButton from './sort'
import FilterButton from './filter-btn'

const Filters = ({ filterButton = true }) => {
  return (
    <div className="flex justify-between items-center gap-4 p-4">
      <Search placeholder="Search" />
      <div className="flex gap-4">
        <SortButton />
        {filterButton && <FilterButton />}
      </div>
    </div>
  )
}

export default Filters
