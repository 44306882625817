// import {Link} from 'react-router-dom';

type Props = {
  avatar: string;
  text: string;
  timestamp: string;
  link?: any;
  direction: 'sender' | 'receiver';
  similarListingsUrl?: string;
  status?: string;
};

export const Bubble = ({
  avatar,
  text,
  timestamp,
  direction,
  // link,
  // similarListingsUrl,
}: // status,
Props) => {
  return direction === 'receiver' ? (
    <div className='col-9 col-md-8  py-2 px-2 float-start'>
      <div className='row text-start'>
        <div className='col-2'>
          <img src={avatar} className='rounded-circle' alt='' width={30} />
        </div>
        <div className='col-12'>
          <div className='fs-14 p-3 m-0 chat-bubble-receiver bg-primary200'>
            <p>{text}</p>
            {/* {link && (
              <a
                href={link?.renterData?.url}
                target='_blank'
                rel='noopener noreferrer'
              >
                Open Link
              </a>
            )} */}
            {/* {similarListingsUrl && (
              <Link to={similarListingsUrl}>Browse Similar Listings</Link>
            )} */}
          </div>
          <p className='m-0 fs-12 mt-1 mx-2 text-muted'>{timestamp}</p>
        </div>
      </div>
    </div>
  ) : (
    <div className='col-9 col-md-8 py-2 px-2 float-end'>
      <div className='row text-end'>
        <div className='col-12'>
          <div className='fs-14 p-3 m-0 chat-bubble-sender bg-primary200 textDark'>
            <p>{text}</p>
            {/* {similarListingsUrl && status === 'cancelled' && (
              <Link to={similarListingsUrl}>Browse Similar Listing</Link>
            )} */}
            {/* {link && (
              <a
                href={link?.landlordData?.url}
                target='_blank'
                rel='noopener noreferrer'
              >
                Open Link
              </a>
            )} */}
          </div>
          <p className='m-0 fs-12 mt-1 mx-2 text-muted'>{timestamp}</p>
        </div>
        <div className='col-2'>
          <img src={avatar} className='rounded-circle' alt='' width={30} />
        </div>
      </div>
    </div>
  );
};
