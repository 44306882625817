import React, { useState, useRef, useEffect } from 'react'
import buildingImg from '../../../assets/images/building.png'

interface ImageCarouselProps {
  images: string | string[]
}

const ImageCarousel: React.FC<ImageCarouselProps> = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0)
  const [imagesArray, setImagesArray] = useState<string[]>([])
  const scrollContainerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    setImagesArray(Array.isArray(images) ? images : [images])
  }, [images])

  const handlePrevious = (): void => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : imagesArray.length - 1,
    )
  }

  const handleNext = (): void => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex < imagesArray.length - 1 ? prevIndex + 1 : 0,
    )
  }

  const handleThumbnailClick = (index: number): void => {
    setCurrentImageIndex(index)
  }

  const scrollThumbnails = (direction: 'left' | 'right'): void => {
    if (scrollContainerRef.current) {
      const scrollAmount = direction === 'left' ? -100 : 100
      scrollContainerRef.current.scrollBy({
        left: scrollAmount,
        behavior: 'smooth',
      })
    }
  }

  return (
    <div className="w-full">
      <div className="flex items-center gap-4 w-full mb-4">
        {imagesArray.length > 1 && (
          <button
            onClick={handlePrevious}
            className="bg-primary text-white rounded-full"
          >
            <i className="fa fa-angle-left text-white bg-primary px-2 py-1 rounded-full fa-solid"></i>
          </button>
        )}
        <div className="h-64 relative flex-grow">
          <img
            src={imagesArray[currentImageIndex] || buildingImg}
            className="w-full h-full object-cover rounded-4"
            alt="Current property"
          />
        </div>
        {imagesArray.length > 1 && (
          <button
            onClick={handleNext}
            className="bg-primary text-white rounded-full"
          >
            <i className="fa fa-angle-right text-white bg-primary px-2 py-1 rounded-full fa-solid"></i>
          </button>
        )}
      </div>
      {imagesArray.length > 1 && (
        <div className="flex items-center gap-4 w-full">
          <button
            onClick={() => scrollThumbnails('left')}
            className="bg-primary text-white rounded-full"
          >
            <i className="fa fa-angle-left text-white bg-primary px-2 py-1 rounded-full fa-solid"></i>
          </button>
          <div
            ref={scrollContainerRef}
            className="flex gap-4 overflow-x-auto w-full scrollbar-hide"
          >
            {imagesArray.map((img, idx) => (
              <button
                key={idx}
                onClick={() => handleThumbnailClick(idx)}
                className={`flex-shrink-0 ${
                  currentImageIndex === idx ? 'ring-2 ring-primary' : ''
                }`}
              >
                <img
                  src={img || buildingImg}
                  className="w-10 h-10 object-cover rounded-1"
                  alt={`Thumbnail ${idx + 1}`}
                />
              </button>
            ))}
          </div>
          <button
            onClick={() => scrollThumbnails('right')}
            className="bg-primary text-white rounded-full"
          >
            <i className="fa fa-angle-right text-white bg-primary px-2 py-1 rounded-full fa-solid"></i>
          </button>
        </div>
      )}
    </div>
  )
}

export default ImageCarousel
