import React, { ChangeEvent } from 'react'
// import { Camera } from 'lucide-react';

interface ProfileImageUploadProps {
  image: string
  onImageChange: (event: ChangeEvent<HTMLInputElement>) => void
}

const ProfileImageUpload: React.FC<ProfileImageUploadProps> = ({
  image,
  onImageChange,
}) => {
  return (
    <div className="position-relative w-max">
      <img
        src={image || '/api/placeholder/96/96'}
        alt="Profile Avatar"
        style={{ objectFit: 'cover' }}
        height={100}
        width={100}
        className="rounded-circle"
      />
      <label
        htmlFor="image-upload"
        className="position-absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-full cursor-pointer transition-opacity opacity-0 hover:opacity-100"
      ></label>
      <div className="upload-pen">
        <i className="fa-solid fa-pen text-white bg-primary p-1 rounded-circle border border-white"></i>
      </div>
      <input
        id="image-upload"
        type="file"
        className="hidden"
        accept="image/*"
        onChange={onImageChange}
      />
    </div>
  )
}

export default ProfileImageUpload
