import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  GoogleMap,
  Marker,
  DirectionsRenderer,
  useJsApiLoader,
} from '@react-google-maps/api'
import { Modal } from 'react-bootstrap'
import { DeveloperDashboardLayout } from '@layouts/DeveloperDashboardLayout'
import { deleteDeveloperSingleProjectProperty } from '@services/krentDeveloper'

// import buildingImg from '../../../../assets/images/building.png'
import utilitiesIcon from '../../../../assets/icons/utilities.svg'
import entertainmentIcon from '../../../../assets/icons/entertainment.svg'
import kitchenIcon from '../../../../assets/icons/kitchen.svg'
import safetyIcon from '../../../../assets/icons/safety.svg'
import othersIcon from '../../../../assets/icons/others.svg'
import binIcon from '../../../../assets/icons/bin.svg'
import PropertyFeatures from 'components/Developer/Property/property-features'
import PropertyView from './property-view'

const ProjectProperty: React.FC = () => {
  const { state } = useLocation()
  const navigate = useNavigate()
  const [center, setCenter] = useState({ lat: 9.082, lng: 8.6753 })
  const [directionsResponse, setDirectionsResponse] = useState<any>(null)
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showDeleteSuccessModal, setShowDeleteSuccessModal] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  // const [error, setError] = useState<string | null>(null)

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env['REACT_APP_GOOGLE_API_KEY'] || '',
    libraries: ['places'],
  })

  const property = (state as any)?.property

  useEffect(() => {
    if (property) {
      setCenter({
        lat: property.location?.coordinates[1] || 9.082,
        lng: property.location?.coordinates[0] || 8.6753,
      })
      setDirectionsResponse(property)
    }
  }, [property])

  if (!property) {
    return <div>No property details found</div>
  }

  if (!isLoaded) {
    return <div>Loading...</div>
  }
  const handleDeletePropertyListing = async () => {
    if (!property._id) return

    setIsDeleting(true)
    try {
      await deleteDeveloperSingleProjectProperty(property._id, {})
      setShowDeleteModal(false)
      setShowDeleteSuccessModal(true)

      setTimeout(() => {
        setShowDeleteSuccessModal(false)
        navigate('/developer-projects')
      }, 10000)
    } catch (err) {
      console.error('Failed to delete property:', err)
      // setError('Failed to delete property. Please try again.')
    } finally {
      setIsDeleting(false)
    }
  }

  return (
    <DeveloperDashboardLayout title="My Projects">
      <div className="flex items-center gap-2">
        <p>Home</p>
        <i className="fa-light fa-angle-right"></i>
        <p>Dashboard</p>
        <i className="fa-light fa-angle-right"></i>
        <p>Project</p>
        <i className="fa-light fa-angle-right"></i>
        <p className="text-primary">Property Details</p>
      </div>

      <div className="w-max ml-auto flex gap-3 my-4">
        <button className="fw-bold text-white bg-primary rounded-10 p-2 px-3 flex gap-2 items-center">
          <i className="fa-sharp-duotone fa-solid fa-pen-field"></i>
          Edit Listing
        </button>
        <button
          className="fw-bold delete-btn rounded-10 p-2 px-3 flex gap-2 items-center"
          onClick={() => setShowDeleteModal(true)}
        >
          <i className="fa-solid fa-trash-can"></i>
          Delete Listing
        </button>
      </div>

      <PropertyView property={property}/>

      <div>
        <h5 className="my-4">What this place offers</h5>
        <div className="flex justify-between border rounded-4 p-3">
          {property.utilitiesAndAmenities && (
            <PropertyFeatures
              title="Utilities and Amenities"
              icon={utilitiesIcon}
              items={property.utilitiesAndAmenities}
            />
          )}
          {property.entertainment && (
            <PropertyFeatures
              title="Entertainment"
              icon={entertainmentIcon}
              items={property.entertainment}
            />
          )}
          {property.kitchenAndDining && (
            <PropertyFeatures
              title="Kitchen and Dining"
              icon={kitchenIcon}
              items={property.kitchenAndDining}
            />
          )}
          {property.safety && (
            <PropertyFeatures
              title="Safety"
              icon={safetyIcon}
              items={property.safety}
            />
          )}
          {property.otherFeatures && (
            <PropertyFeatures
              title="Other Features"
              icon={othersIcon}
              items={property.otherFeatures}
            />
          )}
        </div>
      </div>

      <div className="mt-4">
        <h5 className="my-4">Map</h5>
        <h6 className="my-2">The precise location of this property</h6>

        <p className="gap-2 flex items-center my-2 text-primary">
          <i className="fa fa-location-dot text-primary fa-regular"></i>
          {property.address} {property.city}, {property.country}
        </p>
        <div style={{ height: '25rem', position: 'relative' }}>
          <GoogleMap
            center={center}
            zoom={15}
            mapContainerStyle={{ width: '100%', height: '100%' }}
            onLoad={setMap}
          >
            <Marker position={center} />
            {directionsResponse && (
              <DirectionsRenderer directions={directionsResponse} />
            )}
          </GoogleMap>

          <div
            className="me-auto bg-white rounded-circle d-flex align-items-center justify-content-center social-icon mb-2 cursor-pointer"
            style={{
              position: 'absolute',
              left: '30px',
              bottom: '30px',
            }}
            onClick={() => {
              map?.panTo(center)
              map?.setZoom(15)
            }}
          >
            <i className="fa fa-location-arrow fs-22 text-primary"></i>
          </div>
        </div>
      </div>

      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        dismissible={false}
      >
        <div className="p-4">
          <img src={binIcon} alt="delete" />
          <h6 className="my-4">
            Are you sure you want to delete this listing?
          </h6>
          <p>
            This action will permanently delete this listing. This cannot be
            undone.
          </p>
          <div className="flex float-right gap-4 my-4">
            <button
              className="bg-transparent fw-600"
              onClick={() => setShowDeleteModal(false)}
            >
              Cancel
            </button>
            <button
              onClick={handleDeletePropertyListing}
              className="rounded-delete-btn"
              disabled={isDeleting}
            >
              {isDeleting ? 'Deleting...' : 'Delete forever'}
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        show={showDeleteSuccessModal}
        onHide={() => setShowDeleteSuccessModal(false)}
        dismissible={false}
      >
        <div className="p-4 text-center">
          <img src={binIcon} className="text-center" alt="success" />
          <h4 className="my-4">Property deleted successfully!</h4>
          <p>This window will close in 10 seconds.</p>
        </div>
      </Modal>

      {/* {error && <div className="text-error mt-4">{error}</div>} */}
    </DeveloperDashboardLayout>
  )
}

export default ProjectProperty
