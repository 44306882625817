import React, { useEffect, useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import Tabs from '@shared/Tabs'
import SearchInput from 'components/SearchInput'
import AllProjects from './all-projects'
import { getAllDeveloperProjects } from '@services/krentDeveloper'
import { DevProject } from '@interfaces/app.interface'
import { DeveloperDashboardLayout } from '@layouts/DeveloperDashboardLayout'
import { useSessionStorage } from '../../../hooks'

const Projects: React.FC = () => {
  const navigate = useNavigate()
  const [projects, setProjects] = useState<DevProject[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [currentTimeline, setCurrentTimeline] = useState<string>('all')
  const [user] = useSessionStorage('user', {});

  const fetchProjects = useCallback(async (timeline: string) => {
    setLoading(true)
    setError(null)
    const abortController = new AbortController()

    try {
      const response = await getAllDeveloperProjects(
        user.id,
        // abortController.signal,
        timeline,
      )
      setProjects(response.data as any)
    } catch (error) {
      setError('Failed to fetch projects. Please try again.')
    } finally {
      setLoading(false)
    }

    return () => {
      abortController.abort()
    }
  }, [])

  useEffect(() => {
    fetchProjects(currentTimeline)
  }, [fetchProjects, currentTimeline])

  const handleTabChange = (tabName: string) => {
    let timeline: string
    switch (tabName) {
      case 'Current Projects':
        timeline = 'current'
        break
      case 'Previous Projects':
        timeline = 'previous'
        break
      case 'Future Projects':
        timeline = 'future'
        break
      default:
        timeline = 'all'
    }
    setCurrentTimeline(timeline)
  }

  const components = {
    'All Projects': <AllProjects projects={projects} />,
    'Current Projects': <AllProjects projects={projects} />,
    'Previous Projects': <AllProjects projects={projects} />,
    'Future Projects': <AllProjects projects={projects} />,
  }

  const handleAddDetails = () => {
    navigate('/developer/project-form')
  }

  return (
    <DeveloperDashboardLayout title="My Projects">
      <div>
        <div className="flex items-center gap-2">
          <p>Home</p>
          <i className="fa-light fa-angle-right"></i>
          <p className="">Dashboard</p>
          <i className="fa-light fa-angle-right"></i>
          <p className="text-primary">Projects</p>
        </div>
        <div>
          <div className="my-10">
            <SearchInput placeholder="Search Projects" />
          </div>
          <div>
            <div className="float-right mb-4">
              <button
                className="fw-bold text-white bg-primary rounded-10 p-2 px-3"
                onClick={handleAddDetails}
              >
                + Add a new project
              </button>
            </div>
            <div className="my-10">
              <Tabs
                components={components}
                onTabChange={handleTabChange}
                loading={loading}
                error={error}
              />
            </div>
          </div>
        </div>
      </div>
    </DeveloperDashboardLayout>
  )
}

export default Projects
