import React, { useEffect, useState } from 'react'
import {
  ConfirmPayment,
  fetchDevClientDocument,
  getComments,
  getDeveloperSalesPipeline,
  sendClientsDocs,
  sendComment,
  // updateDocStatus,
} from '@services/krentDeveloper'
import DevTable from '@shared/Table/Dev/Table'
import { sold_props_data } from '@utils/constants'
import { Modal } from 'react-bootstrap'
import { formatDateToTime } from '@utils/helpers'
import { toast } from 'react-toastify'
interface PurchaseItem {
  interestID: string
  userID: string
  propertyAmount: string
  paymentReceipt: string
  propertyName?: string
  requestDate?: string
  status?: string
  propertyDocuments?: Record<string, string>
  kycDocuments?: Record<string, string>
}
interface DocData {
  id: number
  documentTitle: string
  file: File | null
}
const SoldSales = () => {
  const [sales, setSales] = useState([])
  const [prepDocModal, setPrepDocModal] = useState(false)

  const [openPaymentProof, setOpenPaymentProof] = useState(false)
  const [openIdentityProof, setOpenIdentityProof] = useState(false)
  const [uploadDocSection, setUploadDocSection] = useState(false)
  // const [documentTitle, setDocumentTitle] = useState('')
  // const [uploadedFiles, setUploadedFiles] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [inputComment, setInputComment] = useState('')
  const [uploadError, setUploadError] = useState<string | null>(null)
  const [isUploading, setIsUploading] = useState(false)

  const [documents, setDocuments] = useState<DocData[]>([
    { id: 1, documentTitle: '', file: null },
  ])
  const [
    selectedPurchaseItem,
    setSelectedPurchaseItem,
  ] = useState<PurchaseItem | null>(null)
  const [comments, setComments] = useState<any[]>([])
  // const [kycDetails, setKycDetails]= useState("")

  // const [currentInterestId, setCurrentInterestId] = useState('')
  useEffect(() => {
    const fetchSalesData = async () => {
      try {
        const abortController = new AbortController()
        const response = await getDeveloperSalesPipeline(abortController.signal)
        setSales(response)
      } catch (err) {
        console.error('Error fetching sales data:', err)
      }
    }

    fetchSalesData()
  }, [])

  console.log(isSubmitting)

  const handleConfirmPayment = async () => {
    if (selectedPurchaseItem && selectedPurchaseItem.interestID) {
      try {
        setIsSubmitting(true)
        const abortController = new AbortController()
        const res = await ConfirmPayment(
          selectedPurchaseItem.interestID,
          abortController.signal,
        )
        toast.success(res.message)
        console.log('Payment confirmed successfully')
      } catch (error) {
        console.error('Error confirming payment:', error)
      } finally {
        setIsSubmitting(false)
      }
    } else {
      console.error('No selected purchase item or interestId')
    }
  }

  const addMoreDocuments = async () => {
    const lastDoc = documents[documents.length - 1]

    if (!lastDoc.file || !lastDoc.documentTitle.trim()) {
      setUploadError(
        'Please fill in the document title and upload a file before adding more.',
      )
      return
    }

    if (!selectedPurchaseItem?.interestID) {
      setUploadError('No purchase item selected. Unable to upload document.')
      return
    }

    setIsUploading(true)
    setUploadError(null)

    try {
      const formData = new FormData()
      formData.append('title', lastDoc.documentTitle)
      formData.append('purchaseDocument', lastDoc.file as File)

      const response = await sendClientsDocs(
        selectedPurchaseItem.interestID,
        formData,
      )
      console.log(`KYC upload successful for document ${lastDoc.id}:`, response)

      setDocuments((prevDocs) => [
        ...prevDocs,
        { id: prevDocs.length + 1, documentTitle: '', file: null },
      ])
      setUploadError(null)
    } catch (error) {
      console.error('KYC upload failed:', error)
      setUploadError('Failed to upload KYC document. Please try again.')
    } finally {
      setIsUploading(false)
    }
  }

  const handleSendDocumentation = async () => {
    const incompleteDocuments = documents.filter(
      (doc) => !doc.file || !doc.documentTitle.trim(),
    )

    if (incompleteDocuments.length > 0) {
      setUploadError(
        'Please fill in all document titles and upload files for all documents.',
      )
      return
    }

    if (!selectedPurchaseItem?.interestID) {
      setUploadError('No purchase item selected. Unable to send documentation.')
      return
    }

    setIsSubmitting(true)
    setUploadError(null)

    try {
      const formData = new FormData()
      const lastDocument = documents[documents.length - 1]

      formData.append('title', lastDocument.documentTitle)
      formData.append('purchaseDocument', lastDocument.file as File)

      const res = await sendClientsDocs(
        selectedPurchaseItem?.interestID,
        formData,
      )
      toast.success(res.message)

      setDocuments([{ id: 1, documentTitle: '', file: null }])
    } catch (error) {
      console.error('Error sending documentation:', error)
      toast.error('Failed to send documentation. Please try again.')
    } finally {
      setIsSubmitting(false)
    }
  }

  const fetchComments = async (interestID: string) => {
    try {
      const abortController = new AbortController()
      const response = await getComments(interestID, abortController.signal)
      setComments(response.data)
    } catch (err) {
      console.error('Error fetching comments:', err)
    }
  }
  useEffect(() => {
    if (selectedPurchaseItem && selectedPurchaseItem.interestID) {
      fetchComments(selectedPurchaseItem.interestID)
    }
  }, [selectedPurchaseItem])

  const handleSubmitComment = async (e: React.FormEvent) => {
    e.preventDefault()
    if (
      !inputComment.trim() ||
      !selectedPurchaseItem ||
      !selectedPurchaseItem.interestID
    )
      return

    try {
      const abortController = new AbortController()
      await sendComment(
        selectedPurchaseItem.interestID,
        { content: inputComment, recipientId: selectedPurchaseItem.userID },
        abortController.signal,
      )
      setInputComment('')
      fetchComments(selectedPurchaseItem.interestID)
    } catch (err) {
      console.error('Error sending comment:', err)
    }
  }
  const handleChange = (id: number, field: string, value: string) => {
    setDocuments((prevDocs) =>
      prevDocs.map((doc) => (doc.id === id ? { ...doc, [field]: value } : doc)),
    )
  }

  const handleFileUpload = (
    id: number,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = e.target.files?.[0]
    if (file) {
      setDocuments((prevDocs) =>
        prevDocs.map((doc) => (doc.id === id ? { ...doc, file } : doc)),
      )
    }
  }

  const fetchDocuments = async (interestID: string) => {
    try {
      const abortController = new AbortController()
      const response = await fetchDevClientDocument(
        interestID,

        abortController.signal,
      )
      console.log('Fetched documents:', response)
    } catch (err) {
      console.error('Error fetching documents:', err)
    }
  }
  useEffect(() => {
    if (selectedPurchaseItem && selectedPurchaseItem.interestID) {
      fetchDocuments(selectedPurchaseItem.interestID)
    }
  }, [selectedPurchaseItem])

  const renderStatusCell = (cell: any) => {
    if (cell.column.id === 'status') {
      const isCompleted = cell.value.includes('Completed')
      const isPending = cell.value.includes('Pending')
      const rowData = cell.row.original
      return (
        <div className="flex items-center justify-between">
          <span
            className={`rounded-10 p-2 ${
              isPending ? 'future ' : isCompleted ? 'current ' : 'previous '
            } `}
          >
            {cell.value}
          </span>

          <button
            className={`rounded-10 p-2 border-green text-white bg-primary ${
              isCompleted && 'disable'
            }`}
            onClick={() => {
              setSelectedPurchaseItem(rowData)
              setPrepDocModal(true)
            }}
          >
            Prepare documentation
          </button>
        </div>
      )
    }
    return cell.render('Cell')
  }

  return (
    <div>
      <h6>Sold Properties</h6>
      <div className="my-4">
        <DevTable
          columnData={sold_props_data}
          rowData={sales}
          label="sold properties"
          totalDocs={sales.length}
          renderCell={renderStatusCell}
          isFilter={false}
        />
      </div>
      <Modal
        show={prepDocModal}
        onHide={() => setPrepDocModal(false)}
        size="lg"
      >
        <div className="p-10">
          <button
            onClick={() => setPrepDocModal(false)}
            className="fw-bold fs-16 bg-white float-right w-max"
          >
            X
          </button>
          <div className="flex gap-4 ">
            <div className=" w-full" style={{ width: '70%' }}>
              <div className="flex items-center justify-between py-3">
                <p className="fw-bold fs-14">Client Details</p>
              </div>
              <div className="flex gap-2">
                <i className="fa-regular fa-circle-info mt-1"></i>

                <p>
                  After download of applicable document and the documentation is
                  completed, Ensure to click on the “completed” button to be
                  able to upload and send documentation to client
                </p>
              </div>
              <div className="py-4 ">
                <div className="py-2">
                  <button
                    className="flex items-center justify-between w-full bg-white"
                    onClick={() => setOpenPaymentProof(!openPaymentProof)}
                  >
                    <h6>
                      Proof of payment information {openPaymentProof && '(PDF)'}
                    </h6>
                    <i
                      className={`fa-regular ${
                        openPaymentProof ? 'fa-angle-up' : 'fa-angle-down'
                      }`}
                    ></i>
                  </button>
                  <hr />

                  {openPaymentProof && (
                    <div className="overflow-scroll max-h-60">
                      <div className="flex justify-between">
                        <div>
                          <h6>Payment details</h6>
                          <p>
                            Property Name: {selectedPurchaseItem?.propertyName}
                          </p>
                          <p>
                            Property Amount:{' '}
                            {selectedPurchaseItem?.propertyAmount}
                          </p>
                          <p>
                            Property Request Date:{' '}
                            {selectedPurchaseItem?.requestDate}
                          </p>
                        </div>
                      </div>
                      <div className="py-3">
                        <p className="fs-14">
                          *Presented below are the details of the clients
                          transaction
                        </p>
                        {selectedPurchaseItem &&
                        selectedPurchaseItem.paymentReceipt ? (
                          <img
                            src={selectedPurchaseItem?.paymentReceipt}
                            className=""
                            alt="building"
                          />
                        ) : (
                          <p>No proof of payment yet</p>
                        )}
                      </div>
                      <div>
                        <h6>Terms and condition</h6>
                        <p>
                          1. The above amount has been paid in full for the
                          specified duraton of stay at the mentioned property
                        </p>
                        <p>
                          2.Any additional charges incurred uring the stay will
                          be settled seperately
                        </p>
                        <div className="flex gap-2 my-4">
                          <a
                            href={selectedPurchaseItem?.paymentReceipt}
                            download={selectedPurchaseItem?.paymentReceipt}
                            className="border-green underline-none bg-white text-center rounded-10 text-primary w-full py-2"
                          >
                            Download Payment Information
                          </a>

                          {selectedPurchaseItem?.status === 'Not Started' && (
                            <button
                              className="bg-primary rounded-10 text-white w-full py-2"
                              onClick={handleConfirmPayment}
                            >
                              Confirm payment
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="py-2">
                  <button
                    className="flex items-center justify-between w-full bg-white"
                    onClick={() => setOpenIdentityProof(!openIdentityProof)}
                  >
                    <h6>
                      Proof of Identity and KYC {openIdentityProof && '(PDF)'}
                    </h6>
                    <i
                      className={`fa-regular ${
                        openIdentityProof ? 'fa-angle-up' : 'fa-angle-down'
                      }`}
                    ></i>
                  </button>
                  <hr />
                  {openIdentityProof && (
                    <div className="overflow-scroll max-h-60">
                      {selectedPurchaseItem?.kycDocuments &&
                      Object.keys(selectedPurchaseItem.kycDocuments).length >
                        0 ? (
                        <div className="my-4">
                          <div>
                            {Object.entries(
                              selectedPurchaseItem.kycDocuments,
                            ).map(([key, value]) => (
                              <div
                                key={key}
                                className="flex items-center gap-4 my-2"
                              >
                                <h6 className="capitalize fs-14 w-10">
                                  {key}:
                                </h6>
                                <a
                                  download
                                  href={String(value)}
                                  className="border-green rounded-10 p-2 text-primary w-40 flex justify-between"
                                >
                                  <p>
                                    {String(value).length > 20
                                      ? `${String(value).slice(0, 20)}...`
                                      : String(value)}
                                  </p>
                                  <i className="fa-regular fa-download text-primary p-1"></i>
                                </a>
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <p className="text-red py-4">No KYC Documents Yet!</p>
                      )}
                    </div>
                  )}
                </div>
                <div className="py-2">
                  <button
                    className="flex items-center justify-between w-full bg-white"
                    onClick={() => setUploadDocSection(!uploadDocSection)}
                  >
                    <h6>Upload and send documentation to client</h6>
                    <i
                      className={`fa-regular ${
                        uploadDocSection ? 'fa-angle-up' : 'fa-angle-down'
                      }`}
                    ></i>
                  </button>
                  <hr />

                  {uploadDocSection && (
                    <div className="overflow-scroll max-h-60">
                      <div>
                        {selectedPurchaseItem?.propertyDocuments && (
                          <div className="my-4">
                            <h6>Previously uploaded documents</h6>
                            <div>
                              {Object.entries(
                                selectedPurchaseItem.propertyDocuments,
                              ).map(([key, value]) => (
                                <div
                                  key={key}
                                  className="flex items-center gap-4 my-2"
                                >
                                  <h6 className="capitalize fs-14 w-30">
                                    {key}:
                                  </h6>
                                  <a
                                    download
                                    href={String(value)}
                                    className="border-green rounded-10 p-2 text-primary w-full flex justify-between"
                                  >
                                    <p>
                                      {String(value).length > 20
                                        ? `${String(value).slice(0, 20)}...`
                                        : String(value)}
                                    </p>
                                    <i className="fa-regular fa-download text-primary p-1"></i>
                                  </a>
                                </div>
                              ))}
                            </div>
                            <h6>Have more documentation to send?</h6>
                          </div>
                        )}
                        <div>
                          {documents.map((doc) => (
                            <div key={doc.id} className="my-4">
                              <div className="my-2">
                                <label className="fw-bolder form-label text-capitalize">
                                  Document Title
                                </label>
                                <input
                                  type="text"
                                  className="form-control rounded-pill py-2 px-4 fs-14"
                                  value={doc.documentTitle}
                                  onChange={(e) =>
                                    handleChange(
                                      doc.id,
                                      'documentTitle',
                                      e.target.value,
                                    )
                                  }
                                  placeholder="Enter document Title"
                                />
                              </div>

                              <div className="my-2">
                                {doc.file ? (
                                  <p className="text-secondary border px-3 rounded-10 fw-bold">
                                    Uploaded: {doc.file.name}
                                  </p>
                                ) : (
                                  <>
                                    <label
                                      htmlFor={`file-upload-${doc.id}`}
                                      className="text-secondary border px-3 rounded-10 fw-bold cursor-pointer w-full"
                                    >
                                      Click to upload documentation
                                    </label>
                                    <input
                                      type="file"
                                      id={`file-upload-${doc.id}`}
                                      className="hidden"
                                      onChange={(e) =>
                                        handleFileUpload(doc.id, e)
                                      }
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                        <button
                          className="fw-bold text-primary my-4 border-green bg-white rounded-1 px-2"
                          onClick={addMoreDocuments}
                        >
                          {isUploading
                            ? 'Uploading...'
                            : '+ Add More Documents'}
                        </button>
                        {uploadError && (
                          <p className="text-danger">{uploadError}</p>
                        )}

                        <div>
                          <button
                            type="submit"
                            onClick={handleSendDocumentation}
                            className="bg-primary rounded-10 text-white fw-bold w-full my-4 py-3"
                          >
                            Upload and send documentation
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="py-4 " style={{ width: '30%' }}>
              <div className="">
                <h6>Comments</h6>
                <div>
                  <div className="mt-4">
                    {comments.map((comment, idx) => (
                      <div key={idx}>
                        <div className="border p-2 rounded-10">
                          <p>{comment.content}</p>
                        </div>
                        <p className="fs-10 py-2 w-max ml-auto">
                          {formatDateToTime(comment.timestamp)}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
                <hr />
                <form className="mt-auto" onSubmit={handleSubmitComment}>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      value={inputComment}
                      onChange={(e) => setInputComment(e.target.value)}
                      className="form-control border-round-xl px-1 py-2 fs-12"
                      placeholder="Enter a message"
                      style={{ outline: 'none' }}
                    />
                    <button
                      className="btn btn-primary flex items-center gap-2"
                      type="submit"
                    >
                      <i className="fas fa-paper-plane"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default SoldSales
