import React from 'react'
import { useNavigate } from 'react-router-dom'
import buildingImg from '../../../assets/images/building.png'
import propertyIcon from '../../../assets/icons/prop.svg'
import rulerIcon from '../../../assets/icons/ruler.svg'
import { ProjectCardProps } from '@utils/constants'

export const Projectcard: React.FC<ProjectCardProps> = ({
  project,
  client = false,
}) => {
  const navigate = useNavigate()

  const handleClick = () => {
    const route = client
      ? '/developer-listing-projects/'
      : '/developer-projects/'
    navigate(`${route}${project._id}`, { state: { project } })
  }

  const getTimelineClass = (timeline?: string) => {
    switch (timeline) {
      case 'previous':
        return 'previous'
      case 'current':
        return 'current'
      case 'future':
        return 'future'
      default:
        return ''
    }
  }

  return (
    <div className="col" onClick={handleClick} style={{ cursor: 'pointer' }}>
      <div
        className="border-light shadow-sm position-relative single-proj"
        style={{ overflow: 'hidden' }}
      >
        <img
          src={project.coverImage || buildingImg}
          className="single-proj-img"
          alt="building "
        />

        <div className="py-4 px-4 border rounded-b-15 single-proj-body">
          <h5
            className={`fw-bold w-max rounded-50 px-3 fs-12 mb-3 ${getTimelineClass(
              project.projectTimeline,
            )}`}
          >
            <span className="capitalize">{project.projectTimeline} </span>
            project
          </h5>

          <div className="flex gap-3">
            {project.projectPropertiesType?.map((subgroup, index) => (
              <h6
                key={index}
                className="uppercase bg-primary-light w-max rounded-2 p-2 fs-12"
              >
                {subgroup}
              </h6>
            ))}
          </div>

          <div className="my-3">
            <h6 className="fw-bolder fs-14 my-2">{project.projectName}</h6>
            <p className="fs-14 my-2">{project.address}</p>
          </div>

          <div className="flex gap-20 items-center">
            <ProjectDetail
              icon={propertyIcon}
              value={project.propertyNumber}
              label="Properties"
            />
            <ProjectDetail
              icon={rulerIcon}
              value={project.projectSize}
              label="Squarefoot"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

interface ProjectDetailProps {
  icon: string
  value?: number | string
  label: string
}

const ProjectDetail: React.FC<ProjectDetailProps> = ({
  icon,
  value,
  label,
}) => (
  <div>
    <div className="flex gap-1">
      <img src={icon} alt={label} />
      <h6>{value}</h6>
    </div>
    <p>{label}</p>
  </div>
)

export default Projectcard
