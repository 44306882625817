import React, { useEffect, useState, useCallback } from 'react'
import starsImg from '../../../assets/images/stars.svg'
import { getDeveloperReviews } from '@services/krentDeveloper'

interface Review {
  id: string
  user: {
    firstname: string
    lastname: string
  }
  rating: number
  comment: string
  date: string
}

interface RatingsProps {
  id: string
}

const Ratings: React.FC<RatingsProps> = ({ id }) => {
  const [reviews, setReviews] = useState<Review[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [sortOrder, setSortOrder] = useState<'newest' | 'oldest'>('newest')

  const fetchReviews = useCallback(async () => {
    setLoading(true)
    setError(null)
    const abortController = new AbortController()

    try {
      const response = await getDeveloperReviews(id, abortController.signal)
      setReviews(response.data || [])
    } catch (err) {
      setError('Failed to fetch reviews. Please try again.')
    } finally {
      setLoading(false)
    }

    return () => {
      abortController.abort()
    }
  }, [id])

  useEffect(() => {
    fetchReviews()
  }, [fetchReviews])

  // const sortedReviews = [...reviews].sort((a, b) => {
  //   return sortOrder === 'newest'
  //     ? new Date(b.date).getTime() - new Date(a.date).getTime()
  //     : new Date(a.date).getTime() - new Date(b.date).getTime()
  // })

  const averageRating =
    reviews.reduce((acc, review) => acc + review.rating, 0) / reviews.length

  return (
    <div>
      <h6>RATINGS AND REVIEWS</h6>
      <div className="flex gap-4 items-center my-3">
        <h4>{averageRating.toFixed(1)}</h4>
        <img src={starsImg} alt="stars" />
        <p>{reviews.length} reviews</p>
      </div>
      <div>
        <div className="flex items-center justify-between">
          <h5>Reviews</h5>
          <div className="flex items-center gap-1">
            <p className="no-text-wrap">Sort by:</p>
            <select
              className="form-select py-1 px-3"
              value={sortOrder}
              onChange={(e) =>
                setSortOrder(e.target.value as 'newest' | 'oldest')
              }
            >
              <option value="newest">Newest</option>
              <option value="oldest">Oldest</option>
            </select>
          </div>
        </div>
        <div className="my-4">
          {loading && <p>Loading reviews...</p>}
          {error && <p className="text-red-500">{error}</p>}
          {!loading && !error && reviews.length !== 0
            ? reviews.map((review, idx) => (
                <div className="border rounded-4 p-4 my-4" key={idx}>
                  <div className="flex justify-between">
                    <div>
                      <h6 className="capitalize">
                        {review.user.firstname.toLowerCase()}{' '}
                        {review.user.lastname.toLowerCase()}
                      </h6>
                      <img src={starsImg} alt={`${review.rating} stars`} />
                    </div>
                    <p>{new Date(review.date).toLocaleDateString()}</p>
                  </div>
                  <p className="my-1">{review.comment}</p>
                  <div className="flex gap-2 w-max ml-auto mt-2">
                    <p>Was this review helpful?</p>
                    <div className="flex gap-2">
                      <a href="/">Yes</a>
                      <span>|</span>
                      <a href="/">No</a>
                    </div>
                  </div>
                </div>
              ))
            : 'No reviews yet'}
        </div>
      </div>
    </div>
  )
}

export default Ratings
