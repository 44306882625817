import React, { useEffect, useState } from 'react'

interface TabsProps {
  components: { [key: string]: React.ReactNode }
  counts?: { [key: string]: number }
  handleSetActiveTab?: (index: number) => void
  activeIndex?: number
  roundedTabs?: boolean
  onTabChange?: (tabName: string) => void
  loading?: boolean
  error?: string | null
}

const Tabs: React.FC<TabsProps> = ({
  components,
  counts,
  handleSetActiveTab,
  activeIndex = 0,
  roundedTabs,
  onTabChange,
  loading,
  error,
}) => {
  const navItems = Object.keys(components)
  const [activeItem, setActiveItem] = useState<number>(activeIndex)

  useEffect(() => {
    setActiveItem(activeIndex)
  }, [activeIndex])

  const handleTabClick = (idx: number) => {
    setActiveItem(idx)
    if (handleSetActiveTab) handleSetActiveTab(idx)
    if (onTabChange) onTabChange(navItems[idx])
  }

  return (
    <div className="tabs-container">
      <div data-testid="">
        <ul
          className={`tabs-menu ${
            roundedTabs ? 'rounded-tabs-menu' : 'bottom-tab-menu'
          } ${navItems.length === 1 ? 'single-tab' : ''}`}
        >
          {navItems.map((navItem: string, idx: number) => {
            const title = navItem
            const count = counts ? counts[navItem] : undefined

            return (
              <li key={idx} className="tab-item fw-bold">
                <button
                  type="button"
                  className={`${
                    roundedTabs ? 'rounded-tab-button' : 'tab-button'
                  } ${activeItem === idx ? 'active' : ''}`}
                  onClick={() => handleTabClick(idx)}
                >
                  <span className="flex space-x-2">
                    <span>{title}</span>
                    {count !== undefined && (
                      <span
                        className={`tab-count ${
                          activeItem === idx ? 'active' : ''
                        }`}
                      >
                        {count}
                      </span>
                    )}
                  </span>
                </button>
              </li>
            )
          })}
        </ul>
      </div>
      <div data-testid="active-tab" className="tab-content">
        {loading ? (
          <p>Loading projects...</p>
        ) : error ? (
          <p className="text-red-500">{error}</p>
        ) : (
          components[navItems[activeItem]] || <div>No content available</div>
        )}
      </div>
    </div>
  )
}

export default Tabs
