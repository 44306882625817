import React, { useState, useRef, useEffect } from 'react'

const SortButton: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const sortOptions = [
    { name: 'Low to High', icon: 'lowtohigh' },
    { name: 'High to Low', icon: 'hightolow' },
    { name: 'None', icon: 'none' },
  ]

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div className="sort-button-container" ref={dropdownRef}>
      <button className="sort-button" onClick={() => setIsOpen(!isOpen)}>
        <svg
          width="18"
          height="16"
          viewBox="0 0 18 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="sort-icon"
        >
          <path
            d="M1.5 1.33337H12.3333M1.5 4.66671H9M1.5 8.00004H9M13.1667 4.66671V14.6667M13.1667 14.6667L9.83333 11.3334M13.1667 14.6667L16.5 11.3334"
            stroke="#47BBA9"
            strokeWidth="1.67"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        Sort
      </button>

      {isOpen && (
        <div className="sort-dropdown">
          <div className="sort-dropdown-header">
            <h3>Sort by Slots</h3>
          </div>
          <ul className="sort-options-list">
            {sortOptions.map((option, index) => (
              <li
                key={index}
                className="sort-option"
                onClick={() => {
                  setIsOpen(false)
                }}
              >
                <h5>{option.name}</h5>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

export default SortButton
