import React, { useState, useEffect, useCallback } from 'react'
import buildingImg from '../../../assets/images/building.png'
import DevelopersPagination from '@shared/navbars/DevelopersNavbar/Pagination'
import {
  bookedInspection,
  completedInspection,
  getUserInterests,
} from '@services/krentDeveloper'
import { formatDate, numberWithCommas } from '@utils/helpers'
import { PropertyInterest } from '@utils/constants'
import { useNavigate } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import UploadReceipt from 'components/UsersDashboard/uploadReceipt'
import { startPropertyTransactions } from '@services/krent.service'
import { collection, doc, serverTimestamp, setDoc, Timestamp } from 'firebase/firestore'
import ktoast from '@services/toast.service'
import { useSessionStorage } from '../../../hooks'
import { firestore } from '@services/firebase.service'

const ITEMS_PER_PAGE = 50

const Interests = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [interests, setInterests] = useState<PropertyInterest[]>([])
  const [totalPages, setTotalPages] = useState(1)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)

  const fetchInterests = useCallback(async () => {
    setIsLoading(true)
    setError(null)
    try {
      const abortController = new AbortController()
      const response = await getUserInterests(abortController.signal)
      setInterests(response.data)
      setTotalPages(Math.ceil(response.data.length / ITEMS_PER_PAGE))
    } catch (err) {
      setError(err as any)
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchInterests()
  }, [fetchInterests])

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage)
  }

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE
  const currentInterests = interests?.slice(
    startIndex,
    startIndex + ITEMS_PER_PAGE,
  )

  if (isLoading) return <div>Loading...</div>
  if (error) return <div>{error}</div>

  return (
    <div>
      <Header />
      <InterestList
        interests={currentInterests}
        refetchInterests={fetchInterests}
      />
      <DevelopersPagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  )
}

const Header = () => (
  <div className="my-10">
    <div className="w-max ml-auto">
      <a
        className="fw-bold text-white bg-primary rounded-10 py-3 px-3"
        href="/developer-listing"
      >
        {' '}
        + &nbsp; Add new interests
      </a>
    </div>
  </div>
)

const InterestList = ({
  interests,
  refetchInterests,
}: {
  interests: any[]
  refetchInterests: () => void
}) => (
  <div className="my-20">
    {interests.length < 1 ? (
      <div className="text-center">
        <h4>No interests created</h4>
      </div>
    ) : (
      interests.map((item, idx) => (
        <InterestCard
          key={idx}
          item={item}
          refetchInterests={refetchInterests}
        />
      ))
    )}
  </div>
)

const InterestCard = ({
  item,
  refetchInterests,
}: {
  item: any
  refetchInterests: () => void
}) => {
  const navigate = useNavigate()
  const [openBookInspectionModal, setOpenBookInspectionModal] = useState(false)
  const [showProceedtoPayment, setShowProceedtoPayment] = useState(false)
  const [inspectionBtnClicked, setInspectionBtnClicked] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [questionModal, setQuestionModal] = useState(false)
  const [modalAction, setModalAction] = useState<'booked' | 'completed'>(
    'booked',
  )
  const [user] = useSessionStorage('user', {});
  const messageCollections: any = process.env['REACT_APP_FIREBASE_COLLECTION'];

  const handleViewInterestDetails = () => {
    navigate(`/purchase-details/${item._id}`, {
      state: { shownInterest: true, item },
    })
  }

  const handleBookedCompleted = async () => {
    setIsLoading(true)
    try {
      const abortController = new AbortController()
      const res = await bookedInspection(item._id, abortController.signal)
      toast.success(res.message)
      setTimeout(() => {
        setQuestionModal(false)
        refetchInterests()
      }, 2000)
    } catch (err) {
      console.error('Error completing inspection:', err)
    } finally {
      setIsLoading(false)
    }
  }
  const handleInspectionCompleted = async () => {
    setIsLoading(true)
    try {
      const abortController = new AbortController()
      const res = await completedInspection(item._id, abortController.signal)
      setInspectionBtnClicked(true)
      toast.success(res.message)
      setTimeout(() => {
        setQuestionModal(false)
      }, 2000)
    } catch (err) {
      console.error('Error completing inspection:', err)
    } finally {
      setIsLoading(false)
    }
  }
  const handleQuestionModalAction = () => {
    if (modalAction === 'booked') {
      handleBookedCompleted()
    } else {
      handleInspectionCompleted()
    }
  }
  const handleGoToMessaging = () => {
    if (user && user?.id) {
      // setLoading(true);

      const formattedDateFrom = formatDate(new Date().toISOString());
      const formattedDateTo = formatDate(new Date().toISOString());

      startPropertyTransactions({
        listing: item._id,
        arrival_date: formattedDateFrom,
        departure_date: formattedDateTo,
      })
        .then((d: any) => {
          try {
            setDoc(
              doc(collection(firestore, messageCollections), d?.data?.id),
              {
                meta: {
                  request: {
                    id: d?.data?.id,
                    property: item._id,
                    // request_type: type,
                    // arrival_date: dateFrom,
                    // departure_date: dateTo,
                  },
                  
                  sent_by: {
                    name:
                      user?.prefferredName ||
                      `${user?.firstname} ${user?.lastname}`,
                    photoUrl: user?.photo,
                  },
                  owner: {
                    name: "Folu",
                  },
                },
                chats: [
                  {
                    from_uid: user?.id,
                    to_uid: "folu",
                    type: 'text',
                    value: "I'm interested in your property",
                    createdAt: Timestamp.now(),
                  },
                ],
                read: false,
                members: ["folu", user?.id],
                createdAt: serverTimestamp(),
              }
            ).then(() => {
              ktoast.success(
                'Your booking has been logged successfully. The Property owner will be notified of your interest.'
              );
              // setSuccess(
              //   'Your booking has been logged successfully. The Property owner will be notified of your interest.'
              // );
              setTimeout(() => {
                navigate(`/message/${d?.data.id}`);
              }, 4000);
            });
          } catch (e) {
            console.error('Error adding document: ', e);
          }
        })
        .catch((err) => {
          ktoast.error(err.errors[0].message);
          // setError(err.errors[0].message);
          setTimeout(() => {
            // setError('');
          }, 3000);
        })
        .finally(() => {
          // setLoading(false);
        });
      // Book
      setTimeout(() => {
        // setLoading(false);
      }, 2500);
    } else {
      // setShowModal(true);
      // setLoading(false);
    }
  };
  return (
    <div
      className="sale-interest-card flex justify-between"
      // onClick={handleViewInterestDetails}
    >
      <div className="flex gap-4">
        <div>
          <h6 className="fs-10">Initiation date</h6>
          <p className="fw-400">{formatDate(item?.createdAt || '')}</p>
        </div>
        <img
          src={item.property.otherImages[0] || buildingImg}
          className=""
          alt="building"
        />
        <div>
          <p
            className={`fw-bold w-max rounded-50 px-3 fs-12 capitalize ${getBadgeClass(
              item.type,
            )}`}
          >
            {item.property.propertyDeedType}
          </p>
          <p>{item.propertyName}</p>
          <h6>
            {item.property.currency.toUpperCase()}&nbsp;
            {numberWithCommas(item.property.price)}
          </h6>
          <PropertyDetails
            bedrooms={item.property.numberOfBedrooms}
            bathrooms={item.property.numberOfBathrooms}
          />
        </div>
      </div>
      <div>
        <h6 className="future fs-12 w-max px-2 uppercase">
          Interest Request {item.paymentStatus}
        </h6>
      </div>
      <div className="text-center">
        <h6>
          Interest sent {item.documentationProgress}%
          <br />
          (in progress)
        </h6>
        <div
          className="progress-bar-wrapper"
          style={{
            backgroundColor: '#f3f3f3',
            borderRadius: '10px',
            height: '10px',
            width: '100%',
            marginTop: '10px',
          }}
        >
          <div
            className="progress-bar"
            style={{
              width: `${item.documentationProgress}%`,
              height: '100%',
              backgroundColor: '#47BBA9',
              borderRadius: '10px',
              transition: 'width 0.5s ease-in-out',
            }}
          />
        </div>
      </div>

      <div className="flex gap-2">
        <button
          onClick={() => setOpenBookInspectionModal(true)}
          className="bg-gray rounded-10 text-black p-2 h-max border-black                "
        >
          {item.isBooked ? 'Agent Details' : 'Book Inspection'}{' '}
        </button>

        {item.isBooked && (
          <>
            {item.inspected || inspectionBtnClicked ? (
              ''
            ) : (
              <div className="tooltip-container">
                <button
                  className="bg-primary rounded-10 text-white p-2 h-max"
                  onClick={() => {
                    setModalAction('completed')
                    setQuestionModal(true)
                  }}
                  disabled={isLoading}
                >
                  Inspection completed?
                </button>
                <span className="tooltip-text">
                  Has the inspection being carried out yet? Click ONLY if
                  inspection has been completed
                </span>
              </div>
            )}
          </>
        )}
        {!item.isBooked && (
          <button
            className="bg-white border-green rounded-10 text-primary p-2 h-max"
            onClick={() => {
              setModalAction('booked')
              setQuestionModal(true)
            }}
          >
            Inspection booked?
          </button>
        )}
        {(item.inspected || inspectionBtnClicked) && (
          <button
            className="bg-black rounded-10 text-white p-2 h-max"
            onClick={() => setShowProceedtoPayment(true)}
          >
            Proceed to payment
          </button>
        )}
      </div>
      <div>
        <button onClick={handleViewInterestDetails} className="bg-white">
          <i className="fa-regular fa-ellipsis text-primary rounded-circle p-1 border-green"></i>
        </button>
      </div>
      <Modal
        show={questionModal}
        onClose={() => setQuestionModal(false)}
        dismissible={false}
        // size="sm"
      >
        <div className="p-4">
          <div className="flex justify-between gap-2">
            <h5 className="w-full no-text-wrap">
              {modalAction === 'booked'
                ? 'Has Inspection been booked?'
                : 'Has Inspection been completed?'}
            </h5>
            <button
              onClick={() => setQuestionModal(false)}
              className="bg-white"
            >
              <i className="fa-regular fa-x"></i>
            </button>
          </div>
          <div className="flex gap-2 my-4">
            <button
              onClick={handleQuestionModalAction}
              className="bg-primary rounded-10 p-2 w-full text-white"
            >
              Yes
            </button>
            <button
              onClick={() => setQuestionModal(false)}
              className="border-red text-red bg-white rounded-10 p-2 w-full"
            >
              No
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        show={openBookInspectionModal}
        onClose={() => setOpenBookInspectionModal(false)}
        dismissible={false}
        // size="sm"
      >
        <div className="container py-3">
          <div className="float-right">
            <button
              onClick={() => setOpenBookInspectionModal(false)}
              className="bg-white"
            >
              <i className="fa-regular fa-x"></i>
            </button>
          </div>

          <div className="p-3">
            <h6>Book inspection date with agent</h6>
            <p>Kindly schedule an inspection for the property with the agent</p>
            <div className="py-4">
              <div className="flex gap-2 items-center py-2">
                <i className="fa-regular fa-phone"></i>

                <h6>PHONE CALL : </h6>
                <h6 className="text-primary">{item.agent.phone}</h6>
              </div>
              <div className="flex gap-2 items-center py-2">
                <i className="fa-regular fa-chat"></i>

                <h6>CHAT WITH AGENT : </h6>
                <button className="text-primary bg-white"     
                onClick={handleGoToMessaging}
                >Go to messaging</button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <UploadReceipt
        show={showProceedtoPayment}
        onClose={() => setShowProceedtoPayment(false)}
        propertyId={item._id}
        item={item}
      ></UploadReceipt>
    </div>
  )
}

const PropertyDetails = ({
  bedrooms,
  bathrooms,
}: {
  bedrooms: number
  bathrooms: number
}) => (
  <div className="flex gap-4">
    <div className="flex gap-2 items-center">
      <i className="fa-regular fa-bed-front text-primary"></i>
      <h6>{bedrooms}</h6>
    </div>
    <div className="flex gap-2 items-center">
      <i className="fa-regular fa-bath text-primary"></i>
      <h6>{bathrooms}</h6>
    </div>
  </div>
)

const getBadgeClass = (type: string) => {
  switch (type) {
    case 'shortlet':
      return 'previous'
    case 'rent':
      return 'current'
    default:
      return 'future'
  }
}

export default Interests
