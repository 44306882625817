import React, { useState, useRef, useEffect } from 'react'

interface Filters {
  'All Institutions': boolean
  Federal: boolean
  State: boolean
  Private: boolean
  Sandwich: boolean
  'E-Learning': boolean
  'Distance/Open': boolean
}

const FilterButton: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [filters, setFilters] = useState<Filters>({
    'All Institutions': false,
    Federal: false,
    State: false,
    Private: false,
    Sandwich: false,
    'E-Learning': false,
    'Distance/Open': false,
  })
  const dropdownRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleFilterChange = (option: keyof Filters) => {
    setFilters((prev) => ({ ...prev, [option]: !prev[option] }))
  }

  return (
    <div className="filter-button-container" ref={dropdownRef}>
      <button className="filter-button" onClick={() => setIsOpen(!isOpen)}>
        <svg
          width="18"
          height="12"
          viewBox="0 0 18 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="filter-icon"
        >
          <path
            d="M4 6H14M1.5 1H16.5M6.5 11H11.5"
            stroke="#47BBA9"
            strokeWidth="1.67"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        Filters
      </button>

      {isOpen && (
        <div className="filter-dropdown">
          <div className="filter-dropdown-header">
            <h3>Filter</h3>
          </div>
          <ul className="filter-options-list">
            {Object.entries(filters).map(([option, isChecked]) => (
              <li key={option} className="filter-option">
                <label className="filter-label">
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={() => handleFilterChange(option as keyof Filters)}
                    className="filter-checkbox"
                  />
                  {option}
                </label>
              </li>
            ))}
          </ul>
          <div className="filter-actions">
            <button
              className="filter-apply-button"
              onClick={() => {
                setIsOpen(false)
              }}
            >
              Filter
            </button>
            <button
              className="filter-reset-button"
              onClick={() => {
                setFilters({
                  'All Institutions': false,
                  Federal: false,
                  State: false,
                  Private: false,
                  Sandwich: false,
                  'E-Learning': false,
                  'Distance/Open': false,
                })
                setIsOpen(false)
              }}
            >
              Reset Filter
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default FilterButton
