import { getInsuranceCompanies, setDamagesCover } from '@services/krent.service'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'

type InsuranceOption = {
  agency: string
  standardFee: number
  premiumFee: number
  id: string
}
type InsuranceCompany = {
  name: string
  id: string
  standardFee?: number
  premiumFee?: number
}
type DamagesCoverProps = {
  insuranceCompanies: string
  showDamagesCover: boolean
  setShowDamagesCover: (value: boolean) => void
  transactionId: string
}

export const DamagesCover: React.FC<DamagesCoverProps> = ({
  insuranceCompanies,
  showDamagesCover,
  setShowDamagesCover,
  transactionId
}) => {
  const [selectedFeeType, setSelectedFeeType] = useState<string>('')
  const [
    selectedInsurance,
    setSelectedInsurance,
  ] = useState<InsuranceOption | null>(null)
  const [insuranceOptions, setInsuranceOptions] = useState<InsuranceOption[]>(
    [],
  )

  const fetchInsuranceCompanies = async () => {
    try {
      const res = (await getInsuranceCompanies()) as {
        data: InsuranceCompany[]
      }

      const filteredCompanies = res.data
        .filter((company) => insuranceCompanies?.includes(company.id))
        .map((company) => ({
          id: company.id,
          agency: company.name,
          standardFee: company.standardFee || 0,
          premiumFee: company.premiumFee || 0,
        }))

      setInsuranceOptions(filteredCompanies)
    } catch (error) {
      console.error('Failed to fetch insurance companies', error)
      setInsuranceOptions([])
    }
  }

  useEffect(() => {
    if (insuranceCompanies && insuranceCompanies.length > 0) {
      fetchInsuranceCompanies()
    }
  }, [insuranceCompanies])

  const handleYesClick = () => {
    setShowDamagesCover(false)
    setShowDamagesCover(true)
  }

  const handleFeeTypeSelect = (type: string) => {
    setSelectedFeeType(type)
  }
  const handleInsuranceSelect = (insurance: InsuranceOption) => {
    setSelectedInsurance(insurance)
  }
  const handleSubmitDamagesCover = async () => {
    try {
      const payload = selectedFeeType === 'insurance' && selectedInsurance
        ? {
            damagesCoverageOption: 'insurance',
            insuranceCompanyId: selectedInsurance.id
          }
        : {
            damagesCoverageOption: 'caution'
          }

      await setDamagesCover(transactionId, payload)
      setShowDamagesCover(false)
    } catch (error) {
      console.error('Failed to set damages cover', error)
      // Optionally add error handling/user notification
    }
  }
  return (
    <>
      <div className="mt-3">
        <p>Would you like to proceed with the transaction?</p>

        <div className="d-flex gap-2 mt-3">
          <button
            className="rounded-10 btn btn-primary w-100 fw-bold"
            onClick={handleYesClick}
          >
            Yes
          </button>
          <button
            className="btn border-green text-primary w-100 fw-bold rounded-10"
            onClick={() => setShowDamagesCover(false)}
          >
            No
          </button>
        </div>
      </div>

      {/* Fees Selection Modal */}
      <Modal
        show={showDamagesCover}
        onHide={() => setShowDamagesCover(false)}
        centered
      >
        <div className="container p-4">
          <div className="d-flex justify-content-between">
            <h5 className="fw-bold">
              <i className="fa-regular fa-house-crack"></i>&nbsp;{' '}
              {selectedFeeType === 'insurance'
                ? 'Renting Insurance Fees'
                : 'Select damages cover'}
            </h5>
            <button
              className="bg-white border-0"
              onClick={() => setShowDamagesCover(false)}
            >
              <i className="fa-regular fa-x"></i>
            </button>
          </div>
          <p className="fs-16 mt-2">
            Selecting one of this would insure the property and amenities.
          </p>

          {selectedFeeType !== 'insurance' ? (
            <div className="mt-4">
              <div
                className={`d-flex align-items-center justify-content-between p-3 border rounded cursor-pointer ${
                  selectedFeeType === 'caution' ? 'border-primary' : ''
                }`}
                onClick={() => handleFeeTypeSelect('caution')}
              >
                <div className="d-flex align-items-center gap-2">
                  <input
                    type="radio"
                    checked={selectedFeeType === 'caution'}
                    onChange={() => {}}
                  />
                  <span>Caution fees</span>
                </div>
                <i className="fa-regular fa-circle-info text-info"></i>
              </div>
              <div
                className={`d-flex align-items-center justify-content-between p-3 border rounded cursor-pointer mt-3 ${
                  selectedFeeType === 'insurance' ? 'border-primary' : ''
                }`}
                onClick={() => handleFeeTypeSelect('insurance')}
              >
                <div className="d-flex align-items-center gap-2">
                  <input
                    type="radio"
                    checked={selectedFeeType === 'insurance'}
                    onChange={() => {}}
                  />
                  <span>Renting insurance fee</span>
                </div>
                <i className="fa-regular fa-circle-info text-info"></i>
              </div>
            </div>
          ) : (
            ''
          )}

          {selectedFeeType === 'insurance' && (
            <div className="mt-4 table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Select one</th>
                    <th>Agency</th>
                    <th>Standard fee/YR</th>
                    <th>Premium fee/YR</th>
                  </tr>
                </thead>
                <tbody>
                  {insuranceOptions.map((option, index) => (
                    <tr
                      key={index}
                      className="cursor-pointer"
                      onClick={() => handleInsuranceSelect(option)}
                    >
                      <td>
                        <input
                          type="radio"
                          name="insurance"
                          checked={selectedInsurance === option}
                          onChange={() => {}}
                        />
                      </td>
                      <td>{option.agency}</td>
                      <td>{option.standardFee.toLocaleString()}</td>
                      <td>{option.premiumFee.toLocaleString()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          <button
            className="btn btn-primary w-100 rounded-pill mt-4"
            onClick={handleSubmitDamagesCover}
            disabled={
              selectedFeeType === 'insurance'
                ? !selectedInsurance
                : !selectedFeeType
            }
          >
            Continue
          </button>
        </div>
      </Modal>
    </>
  )
}
