import React, { useState, useRef, ChangeEvent } from 'react'

interface FileWithPreview extends File {
  preview?: string
}

interface ProjImgUploadProps {
  onFilesSelected: (files: FileWithPreview[]) => void
  uploading: boolean
  multiple?: boolean
}

const ProjImgUpload: React.FC<ProjImgUploadProps> = ({
  onFilesSelected,
  uploading,
  multiple = true,
}) => {
  const [files, setFiles] = useState<FileWithPreview[]>([])
  const [error, setError] = useState<string>('')
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(event.target.files || [])
    if (multiple && selectedFiles.length + files.length > 10) {
      setError('You can only upload up to 10 images.')
      return
    }
    if (!multiple && selectedFiles.length > 1) {
      setError('You can only upload one image.')
      return
    }

    const validFiles = selectedFiles.filter((file) => {
      const isValid = ['image/jpeg', 'image/gif', 'image/png'].includes(
        file.type, 
      )
      if (!isValid) {
        setError('Only JPEG, GIF, or PNG files are allowed.')
      }
      return isValid
    })
    console.log(uploading)
    const filesWithPreviews = validFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      }),
    )

    const newFiles = multiple
      ? [...files, ...filesWithPreviews]
      : filesWithPreviews
    setFiles(newFiles)
    onFilesSelected(newFiles)
    setError('')
  }

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  return (
    <div className="project-img-upload">
      <div className="mb-4">
        <svg
          width="65"
          height="64"
          viewBox="0 0 65 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M31.0647 5.7207C17.9893 5.7207 11.453 5.7207 7.39009 9.7807C3.33301 13.8407 3.33301 20.377 3.33301 33.4465C3.33301 46.5161 3.33301 53.0524 7.39301 57.1124C11.4559 61.1724 17.9922 61.1724 31.0676 61.1724C44.143 61.1724 50.6793 61.1724 54.7422 57.1124C58.8022 53.0524 58.8022 46.5161 58.8022 33.4465V31.9882"
            stroke="#47BBA9"
            stroke-width="5.6"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12.0801 58.2205C24.3592 44.3605 38.1551 25.9826 58.7467 39.7667M49.988 2.82715V26.1838M61.6663 14.4326L38.3038 14.4763"
            stroke="#47BBA9"
            stroke-width="5.6"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div className="flex items-center justify-center mb-4 gap-2">
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          accept="image/jpeg,image/gif,image/png"
          multiple={multiple}
          className="hidden"
        />
        <button onClick={handleUploadClick} className="choose-file">
          Choose Files
        </button>
        <p className="ml-4">
          {files.length > 0
            ? `${files.length} file(s) selected`
            : 'No file selected'}
        </p>
      </div>
      <div className="text-sm text-gray-600">
        <p>{multiple ? 'Upload up to 10 images' : 'Upload an image'}</p>
        <p>Image type: JPEG, GIF or PNG only</p>
      </div>
      {error && (
        <div className="mt-4 p-2 bg-red-100 border border-red-400 text-red-700 rounded">
          {error}
        </div>
      )}
      <div className="mt-4 grid-cols-3 ">
        {files.map((file, index) => (
          <div key={index} className="relative">
            <img
              src={file.preview}
              alt={`preview ${index}`}
              className="w-15 h-24 object-cover rounded"
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default ProjImgUpload
