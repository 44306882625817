import React from 'react'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'

interface SuccessfulCreationProps {
  successModal: boolean
  setSuccessModal: (value: boolean) => void
  type?: string
  title?: string
  content?: string
  buttonText?: string
  buttonLink?: string
}

const SuccessfulCreation: React.FC<SuccessfulCreationProps> = ({
  successModal,
  setSuccessModal,
  type,
  title,
  content,
  buttonText,
  buttonLink,
}) => {
  return (
    <Modal
      show={successModal}
      onHide={() => setSuccessModal(false)}
      backdrop="static"
      keyboard={false}
    >
      <div className="mt-3 text-center py-4">
        <div className="">
          <svg
            width="140"
            height="112"
            viewBox="0 0 140 112"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M25.0967 55.9028C25.0967 31.1258 45.1975 11.0249 69.9746 11.0249C94.7516 11.0249 114.852 31.1258 114.852 55.9028C114.852 80.6798 94.7516 100.781 69.9746 100.781C45.1975 100.781 25.0967 80.6798 25.0967 55.9028ZM69.9746 17.9292C49.0107 17.9292 32.001 34.9389 32.001 55.9028C32.001 76.8667 49.0107 93.8764 69.9746 93.8764C90.9385 93.8764 107.948 76.8667 107.948 55.9028C107.948 34.9389 90.9385 17.9292 69.9746 17.9292Z"
              fill="#47BBA9"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M92.9079 35.9988C94.3677 37.2251 94.5571 39.4027 93.3308 40.8626L64.3328 75.384C63.6901 76.1491 62.7475 76.5982 61.7484 76.6153C60.7494 76.6323 59.7919 76.2157 59.1235 75.473L46.6958 61.6644C45.4204 60.2473 45.5352 58.0645 46.9524 56.7891C48.3695 55.5137 50.5523 55.6285 51.8277 57.0457L61.5996 67.9034L88.0442 36.4218C89.2704 34.9619 91.448 34.7726 92.9079 35.9988Z"
              fill="#47BBA9"
            />
            <path
              d="M122.758 86.3786C124.809 90.9677 129.855 98.9059 133.884 94.9543C137.528 91.3801 128.323 88.2901 122.758 86.3786Z"
              fill="#47BBA9"
            />
            <path
              d="M18.746 35.637C16.6946 31.0479 11.6489 23.1098 7.61981 27.0613C3.97549 30.6356 13.1809 33.7255 18.746 35.637Z"
              fill="#47BBA9"
            />
            <path
              d="M21.6753 19.6291C19.4917 12.4421 13.495 -0.26958 6.7832 4.83564C0.712272 9.45337 13.7821 15.755 21.6753 19.6291Z"
              fill="#47BBA9"
            />
            <path
              d="M18.1901 76.7106C13.3636 78.1151 4.04613 79.4028 4.45291 73.7741C4.82084 68.6828 13.1323 73.7035 18.1901 76.7106Z"
              fill="#47BBA9"
              stroke="#EEEEEE"
              strokeWidth="0.276172"
              strokeLinecap="round"
            />
            <path
              d="M124.557 46.5071C126.608 41.918 131.654 33.9799 135.683 37.9314C139.327 41.5057 130.122 44.5956 124.557 46.5071Z"
              fill="#47BBA9"
            />
            <path
              d="M121.628 30.4993C123.812 23.3122 129.809 10.6005 136.521 15.7058C142.591 20.3235 129.522 26.6252 121.628 30.4993Z"
              fill="#47BBA9"
            />
            <path
              d="M26.4614 90.3643C19.1227 94.7405 4.34641 100.847 2.60656 91.4132C1.03285 82.8805 16.8542 87.5823 26.4614 90.3643Z"
              fill="#47BBA9"
              stroke="#EEEEEE"
              strokeWidth="0.276172"
              strokeLinecap="round"
            />
          </svg>

          {type && (
            <p
              className="fs-14 light-text fw-bold"
              id="summary-description pt-4"
            >
              {type} uploaded successfully!
            </p>
          )}
          {title && (
            <p
              className="fs-14 light-text fw-bold"
              id="summary-description pt-4"
            >
              {title}
            </p>
          )}
          {content && <p className="fs-14 light-text mt-4">{content}</p>}

          {buttonText && buttonLink && (
            <Link
              className="bg-primary text-white p-2 rounded-15 mt-2"
              to={buttonLink}
            >
              {buttonText}
            </Link>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default SuccessfulCreation
