// @ts-nocheck

import { DashboardLayout } from '@layouts/index'
import notification from '../../assets/images/notifications.png'
import { useEffect, useState } from 'react'
import { getPropertyTransactions } from '@services/krent.service'
import { useSessionStorage } from '../../hooks'
import Table from '@shared/Table/Table'
import ktoast from '@services/toast.service'
import Tabs from '@shared/Tabs'

// Updated Intent type to include 'long lease'
type Intent = 'shortlet' | 'long lease' | 'sale' | 'ibuying' | 'all'

interface Bill {
  total: string
  [key: string]: any
}

interface Transaction {
  id: string
  property: string
  owner: string
  intent: Intent
  arrivalDate: string
  departureDate: string
  inspected: boolean
  updatedAt: string
  createdAt: string
  status: string
  bill: Bill[]
  listing: {
    intent: Intent
    [key: string]: any
  }
  [key: string]: any
}

interface User {
  id: string
  [key: string]: any
}

interface TabComponents {
  Shortlet: JSX.Element
  Rent: JSX.Element
  Sale: JSX.Element
  iBuying: JSX.Element
}

export const MyBookings = () => {
  const [user] = useSessionStorage('user', {} as User)
  const [loading, setLoading] = useState<boolean>(false)
  const [transactions, setTransactions] = useState<Transaction[]>([])
  const [filteredTransactions, setFilteredTransactions] = useState<
    Transaction[]
  >([])
  const [pendingPayments, setPendingPayments] = useState<number>(0)
  const [completedPayments, setCompletedPayments] = useState<number>(0)
  const [currentTimeline, setCurrentTimeline] = useState<string>('Shortlet')

  const handleGetPropertyTransactions = () => {
    setLoading(true)
    const abortController = new AbortController()

    getPropertyTransactions(user?.id, abortController.signal)
      .then((res: any) => {
        setTransactions(res.data)
        // Initially filter for default tab (Shortlet)
        setFilteredTransactions(
          res.data.filter(
            (t) => t.listing.intent?.toLowerCase() === 'shortlet',
          ),
        )
        setLoading(false)

        setPendingPayments(
          res.data
            .filter((c) => c.status === 'customer-accepted')
            .reduce((a, obj) => {
              const billTotal = obj.bill[0]?.total
                ? parseInt(obj.bill[0].total)
                : 0
              return a + billTotal
            }, 0),
        )

        setCompletedPayments(
          res.data
            .filter((c) => c.status === 'completed')
            .reduce((a, obj) => {
              const billTotal = obj.bill[0]?.total
                ? parseInt(obj.bill[0].total)
                : 0
              return a + billTotal
            }, 0),
        )
      })
      .catch((err: { errors: Array<{ message: string }> }) => {
        setLoading(false)
        ktoast.error(err?.errors[0]?.message)
      })
      .finally(() => {
        setLoading(false)
      })

    return () => {
      abortController.abort()
    }
  }

  useEffect(() => {
    handleGetPropertyTransactions()
  }, [user])

  const handleTabChange = (tabName: string): void => {
    setCurrentTimeline(tabName)

    const intentMap: Record<string, string> = {
      Shortlet: 'shortlet',
      Rent: 'long lease',
      Sale: 'sale',
      iBuying: 'ibuying',
    }

    const intentValue = intentMap[tabName]
    if (intentValue) {
      const filtered = transactions.filter(
        (t) => t.listing.intent?.toLowerCase() === intentValue.toLowerCase(),
      )
      setFilteredTransactions(filtered)
    }
  }

  const renderTableContent = (): JSX.Element => {
    if (loading) {
      return <p>Loading</p>
    }
    const tableHeaders = {
      Shortlet: [
        'Property',
        'owner',
        'Arrival Date',
        'Departure Date',
        'Extended',
        'Updated date',
        'Creation date',
        'status',
        'Actions',
      ],
      Rent: [
        'Property',
        'owner',
        'Arrival Date',
        'Departure Date',
        'inspected',
        'Updated date',
        'Creation date',
        'status',
        'Actions',
      ],
    }
    if (filteredTransactions?.length > 0) {
      return (
        <Table
          data={filteredTransactions}
          th={
            tableHeaders[currentTimeline] || [
              'Property',
              'owner',
              'Arrival Date',
              'Departure Date',
              'inspected',
              'Updated date',
              'Creation date',
              'status',
              'Actions',
            ]
          }
          transactions
          getTransactions={handleGetPropertyTransactions}
        />
      )
    }

    return (
      <div className="card border-0 border-round p-5 text-center d-flex align-items-center">
        <div>
          <h6 className="fs-18 mt-1 fw-bold mb-4">No transactions yet</h6>
          <img src={notification} alt="No transactions" width={200} />
          <p className="fs-14 light-text">
            Any transactions you make would show up here. You don't have any
            right now
          </p>
        </div>
      </div>
    )
  }

  const components: TabComponents = {
    Shortlet: renderTableContent(),
    Rent: renderTableContent(),
    Sale: renderTableContent(),
    iBuying: renderTableContent(),
  }

  return (
    <DashboardLayout>
      <div className="row mb-3">
        <h3 className="fw-bold mb-1">Bookings</h3>
        <p className="text-secondary fs-14">Manage your bookings on krent</p>
      </div>

      <div className="row mb-5">
        <div className="col-12 col-md-4 mb-3 mb-md-0">
          <div className="card border-0 border-round px-4 py-5 shadow-sm h-max">
            <h6 className="fs-14 mt-1">
              Pending Payments{' '}
              <i
                className="iconly-Info-Circle icli"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Tooltip on top"
              ></i>
            </h6>
            <h3 className="fw-bold">NGN {pendingPayments?.toLocaleString()}</h3>
          </div>
        </div>
        <div className="col-12 col-md-4 mb-3 mb-md-0">
          <div className="card border-0 border-round px-4 py-5 shadow-sm h-max">
            <h6 className="fs-14 mt-1">
              Completed Payments <i className="iconly-Info-Circle icli"></i>
            </h6>
            <h3 className="fw-bold">
              NGN {completedPayments?.toLocaleString()}
            </h3>
          </div>
        </div>
        <div className="col-12 col-md-4 mb-3 mb-md-0">
          <div className="card border-0 border-round px-4 py-5 shadow-sm h-max">
            <h6 className="fs-14 mt-1">
              Locked Payments <i className="iconly-Info-Circle icli"></i>
            </h6>
            <h3 className="fw-bold">NGN 0.00</h3>
          </div>
        </div>
      </div>

      <div className="row mt-5 mt-md-4">
        <div className="col-12 mb-5 mb-md-0">
          <h6 className="fw-bold fs-16 mb-3">Transactions</h6>
          <Tabs
            components={components}
            onTabChange={handleTabChange}
            loading={loading}
          />
        </div>
      </div>
    </DashboardLayout>
  )
}

export default MyBookings
