import { DeveloperDashboardLayout } from '@layouts/DeveloperDashboardLayout'
import { getDeveloperMetrics, getNotifications } from '@services/krentDeveloper'
import SearchInput from 'components/SearchInput'
import React, { useEffect, useState } from 'react'
import salesIcon from '../../../assets/icons/sales.svg'
import projectIcon from '../../../assets/icons/project.svg'
import { useSessionStorage } from '../../../hooks'
import { formatDateToTime } from '@utils/helpers'
import { useNavigate } from 'react-router-dom'

const DevelopersDashboard = () => {
  const [metrics, setMetrics] = useState({
    totalListings: 0,
    shortletListings: 0,
    rentalListings: 0,
    salesListings: 0,
  })
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [user] = useSessionStorage('user', {})
  const [notifications, setNotifications] = useState<any[]>([])
  const navigate = useNavigate()
  useEffect(() => {
    const controller = new AbortController()

    const fetchMetrics = async () => {
      try {
        const response = await getDeveloperMetrics(controller.signal)
        setMetrics(response.data)
      } catch (err) {
        setError('Failed to load metrics.')
        console.error(err)
      } finally {
        setLoading(false)
      }
    }

    fetchMetrics()

    return () => {
      controller.abort()
    }
  }, [])
  useEffect(() => {
    const fetchNotifications = async () => {
      if (user.id) {
        const abortController = new AbortController()
        try {
          const response = await getNotifications(
            'user',
            user.id,
            abortController.signal,
          )
          setNotifications(response.data)
          console.log('User Notifications:', response.data)
        } catch (err) {
          console.error('Error fetching notifications:', err)
        }
      }
    }

    fetchNotifications()
  }, [user.id])
  console.log(notifications)
  if (loading) {
    return <p>Loading...</p>
  }

  if (error) {
    return <p>{error}</p>
  }

  return (
    <>
      <DeveloperDashboardLayout title="Dashboard Overview">
        <div>
          <div className="flex items-center gap-2">
            <p>Home</p>
            <i className="fa-light fa-angle-right"></i>
            <p className="text-primary">Dashboard</p>
          </div>
          <div className="">
            <h4 className="my-4 capitalize">
              Welcome, {user.firstname.toLowerCase()}!
            </h4>
            <div className="my-10">
              <SearchInput />
            </div>
            {notifications && (
              <div className="flex justify-between notif-card">
                <div className="flex gap-10">
                  <i className="fa-regular fa-bell text-primary mt-1"></i>
                  <div>
                    <h6 className="">{notifications[0]?.title}</h6>
                    <p className="my-1">{notifications[0]?.message}</p>
                    <button
                      className="bg-primary rounded-10 text-white my-2"
                      onClick={() =>
                        navigate(
                          `/dev-interest-details/${notifications[0].interestId}`,
                        )
                      }
                    >
                      View details
                    </button>
                  </div>
                </div>
                <i className="fa-regular fa-times mt-1"></i>
              </div>
            )}
          </div>

          <div className="my-5">
            <h5>KEY METRICS</h5>
            <div className="d-flex justify-content-between gap-4 flex-md-row flex-column">
              {/* Total Listings */}
              <div className="metrics-card">
                <p className="metrics-grade flex items-center">
                  <i className="fa-solid mr-1 fa-caret-up text-primary"></i>
                  <span className="text-primary">
                    {metrics?.totalListings}%
                  </span>
                </p>
                <div className="flex gap-4">
                  <img
                    src={projectIcon}
                    alt="Total Listings"
                    className="metrics-img"
                  />
                  <div>
                    <h6 className="capitalize">Total Listings</h6>
                    <h6>{metrics?.totalListings}</h6>
                  </div>
                </div>
              </div>

              {/* Shortlet Listings */}
              <div className="metrics-card">
                <p className="metrics-grade flex items-center">
                  <i className="fa-solid mr-1 fa-caret-down text-red"></i>
                  <span className="text-red">{metrics?.shortletListings}%</span>
                </p>
                <div className="flex gap-4">
                  <img
                    src={salesIcon}
                    alt="Shortlet Listings"
                    className="metrics-img"
                  />
                  <div>
                    <h6 className="capitalize">Shortlet Listings</h6>
                    <h6>{metrics?.shortletListings}</h6>
                  </div>
                </div>
              </div>

              {/* Rental Listings */}
              <div className="metrics-card">
                <p className="metrics-grade flex items-center">
                  <i className="fa-solid mr-1 fa-caret-up text-primary"></i>
                  <span className="text-primary">
                    {metrics?.rentalListings}%
                  </span>
                </p>
                <div className="flex gap-4">
                  <img
                    src={projectIcon}
                    alt="Rental Listings"
                    className="metrics-img"
                  />
                  <div>
                    <h6 className="capitalize">Rental Listings</h6>
                    <h6>{metrics?.rentalListings}</h6>
                  </div>
                </div>
              </div>

              {/* Sales Listings */}
              <div className="metrics-card">
                <p className="metrics-grade flex items-center">
                  <i className="fa-solid mr-1 fa-caret-down text-red"></i>
                  <span className="text-red">{metrics?.salesListings}%</span>
                </p>
                <div className="flex gap-4">
                  <img
                    src={projectIcon}
                    alt="Sales Listings"
                    className="metrics-img"
                  />
                  <div>
                    <h6 className="capitalize">Sales Listings</h6>
                    <h6>{metrics?.salesListings}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h4>Recent Notifications</h4>
            <div className="h-70 overflow-scroll">
              {notifications && notifications.length > 0 ? (
                notifications.map((notification, idx) => (
                  <div key={idx} className="my-4">
                    <h6 className="fs-14">{notification.title}</h6>
                    <p>{notification.message}</p>
                    <h6 className="fs-12">
                      {formatDateToTime(notification.createdAt)}
                    </h6>
                    <hr />
                  </div>
                ))
              ) : (
                <p>No recent notifications</p>
              )}
            </div>
          </div>
        </div>
      </DeveloperDashboardLayout>
    </>
  )
}

export default DevelopersDashboard
