import GetMeta from '@pages/SeoPages/GetMeta'
import DeveloperHeader from '@shared/navbars/DevelopersNavbar/DeveloperHeader'
import { DeveloperDashboardSideBar } from '@shared/navbars/DevelopersNavbar/DeveloperSidebar'

type Props = {
  children?: JSX.Element | JSX.Element[]
  title: string
}

export const DeveloperDashboardLayout: React.FC<Props> = ({
  children,
  title,
}: Props) => {
  return (
    <div className="container-fluid">
      <GetMeta description="Krent is a real estate and property listing website in Nigeria. Find homes to buy or rent from trusted landlords and agents. Sell your property or find a new home easily with our wide selection of flats, apartments, houses, and more" />
      {/* <div className='row'> */}
      <div className="row vh-100">
        <div className="col-12 col-md-4 col-lg-3 col-xl-2 bg-white text-dark px-0 fixed-top side-bar ">
          {/* <div className='col-12 col-md-4 col-lg-3 col-xl-2 bg-dark text-light px-0 position-fixed top-0 start-0 bottom-0'> */}
          <DeveloperDashboardSideBar />
        </div>
        <div className="col-12 col-md-12 col-lg-9 col-xl-10 bg-white px-0 ms-sm-auto position-relative mt-2 mt-lg-0 ">
          <DeveloperHeader title={title} />
          <div className="py-2  p-md-4">
            {/* px-3 removed from the div */}
            <div className="container mt-3">{children}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
