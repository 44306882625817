import React from 'react'
import image from '../../../../assets/images/profile-pic.png'

type DeveloperHeaderProps = {
  title: string
}

const DeveloperHeader: React.FC<DeveloperHeaderProps> = ({ title }) => {
  return (
    <>
      <div className="dev-head">
        <h4>{title}</h4>
        <div className='flex gap-2 items-center'>
          <img
            src={image}
            alt={'Avatar'}
            style={{ objectFit: 'cover' }}
            height={50}
            width={50}
            className="rounded-circle"
          />
          <div>
            <h6>Kahuna Doe</h6>
            <p>kahunadoe@gmail.com</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default DeveloperHeader
