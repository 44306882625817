import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { DeveloperDashboardLayout } from '@layouts/DeveloperDashboardLayout'
import {
  createDeveloperProject,
  editDeveloperSingleProject,
  uploadProjectImage,
} from '@services/krentDeveloper'
import DetailsForm from './details-form'
import { Project } from 'types'
import ProjImgUpload from './proj-img-upload'
import SuccessfulCreation from './success-modal'
// import ktoast from '@services/toast.service'
import { toast } from 'react-toastify'

const initialProjectState: Project = {
  projectName: '',
  description: '',
  projectStatus: '',
  projectSize: '',
  propertyNumber: '',
  projectPropertiesType: [],
  projectTimeline: '',
  address: '',
  city: '',
  state: '',
}

const ProjectForm: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const projectToEdit = (location.state as any)?.project

  const [project, setProject] = useState<Project>(initialProjectState)
  const [currentStep, setCurrentStep] = useState<'details' | 'upload'>(
    'details',
  )
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [projectId, setProjectId] = useState<string | null>(null)
  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const [successModal, setSuccessModal] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (projectToEdit) {
      setProject(projectToEdit)
    }
  }, [projectToEdit])

  const handleInputChange = (name: keyof Project, value: string | string[]) => {
    setProject((prev) => ({ ...prev, [name]: value }))
  }

  const validateProject = (): boolean => {
    const requiredFields: (keyof Project)[] = [
      'projectName',
      'description',
      'projectStatus',
      'projectSize',
      'propertyNumber',
      'projectTimeline',
      'address',
      'city',
      'state',
    ]

    for (const field of requiredFields) {
      if (!project[field]) {
        setError(
          `Please fill in the ${field
            .replace(/([A-Z])/g, ' $1')
            .toLowerCase()} field.`,
        )
        return false
      }
    }

    if (project.projectPropertiesType.length === 0) {
      setError('Please select at least one project property type.')
      return false
    }

    return true
  }
  const handleNext = async () => {
    setError(null)

    if (!validateProject()) {
      return
    }

    setIsSubmitting(true)
    setLoading(true)

    try {
      let response
      if (projectToEdit && project?._id) {
        response = await editDeveloperSingleProject(project._id, project)
      } else {
        response = await createDeveloperProject(project)
      }
      setProjectId(response.data?._id)
      setCurrentStep('upload')
    } catch (err) {
      toast.error(err as any)
    } finally {
      setIsSubmitting(false)
      setLoading(false)
    }
  }

  const handlePrevious = () => setCurrentStep('details')

  const handleFilesSelected = (files: File[]) => {
    setSelectedFiles(files)
  }
  const handleSubmit = async () => {
    if (selectedFiles.length === 0) {
      setError('Please select at least one image to upload.')
      return
    }

    setIsSubmitting(true)
    setError(null)
    try {
      let uploadSuccess = true

      for (const file of selectedFiles) {
        const formData = new FormData()

        formData.append('coverImage', file)
        try {
          await uploadProjectImage('project', projectId as string, formData)
        } catch (error) {
          console.error('Error uploading file:', error)
          setError('An error occurred while uploading images.')
          uploadSuccess = false
        }
      }

      if (uploadSuccess) {
        setSuccessModal(true)
        setTimeout(() => {
          navigate('/developer-projects')
        }, 2000)
      }
    } catch (err) {
      console.error('Failed to submit project:', err)
      setError('Failed to submit project. Please try again.')
    } finally {
      setIsSubmitting(false)
    }
  }

  const renderBreadcrumbs = () => (
    <div className="flex items-center gap-2 my-4">
      <p>Home</p>
      <i className="fa-regular fa-angle-right"></i>
      <p className="">Dashboard</p>
      <i className="fa-regular fa-angle-right"></i>
      <p>Project</p>
      <i className="fa-regular fa-angle-right"></i>
      <p className="text-primary">
        {projectToEdit ? 'Edit Project' : 'Add Project'}
      </p>
    </div>
  )

  const renderActionButtons = () => (
    <div className="ml-auto w-max flex gap-4">
      <button
        className={`py-2 px-4 fw-bold rounded-50 flex gap-2 items-center ${
          currentStep === 'details'
            ? 'opacity-50 cursor-not-allowed prev-btn'
            : 'bg-white text-primary primary-border'
        }`}
        onClick={handlePrevious}
        disabled={currentStep === 'details'}
      >
        <i
          className={`fa fa-arrow-left text-white fa-solid rounded-full p-1 ${
            currentStep === 'details' ? ' bg-dark ' : 'bg-primary '
          }`}
        ></i>
        Previous
      </button>
      {currentStep === 'details' ? (
        <button
          className="bg-primary py-2 px-4 fw-bold text-white rounded-50 flex gap-2 items-center"
          onClick={handleNext}
        >
          {loading ? (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            'Next'
          )}
          <i className="fa-arrow-right fa-solid fa text-primary bg-white rounded-full p-1"></i>
        </button>
      ) : (
        <button
          className="bg-primary py-2 px-4 fw-bold text-white rounded-50 flex gap-2 items-center"
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          {isSubmitting
            ? 'Submitting...'
            : projectToEdit
            ? 'Update project'
            : 'Create project'}
          <i className="fa-circle-check fa-regular fa-sharp text-white p-1"></i>
        </button>
      )}
    </div>
  )

  return (
    <DeveloperDashboardLayout
      title={projectToEdit ? 'Edit project' : 'Add a new project'}
    >
      <>
        {renderBreadcrumbs()}
        <div>
          <h6 className="my-3">
            {projectToEdit
              ? 'Edit your project details'
              : 'Fill in the details to add projects to your portfolio'}
          </h6>

          <form onSubmit={(e) => e.preventDefault()}>
            {currentStep === 'details' ? (
              <DetailsForm
                project={project}
                onInputChange={handleInputChange}
              />
            ) : (
              <div>
                <label className="fw-bolder form-label text-capitalize">
                  Upload Property Images
                </label>
                <ProjImgUpload
                  onFilesSelected={handleFilesSelected}
                  uploading={isSubmitting}
                  multiple={true}
                />
              </div>
            )}

            {renderActionButtons()}

            {error && <div className="text-red-500 mt-4">{error}</div>}
          </form>
        </div>
      </>
      <SuccessfulCreation
        setSuccessModal={setSuccessModal}
        successModal={successModal}
        type={'Project'}
      />
    </DeveloperDashboardLayout>
  )
}

export default ProjectForm
