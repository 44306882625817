import React, { Fragment, useState } from 'react'
import { PrimaryButton } from '@shared/buttons'
import { Link } from 'react-router-dom'

type Props = {
  setOpenTerms: (param: boolean) => void
  setOpenPolicy: (param: boolean) => void
  agree: boolean
  setAgree: (param: boolean) => void
  acceptLoading: boolean
  acceptBooking: () => void
  kyc: any
  setOpenCancellation: (param: boolean) => void
  couponCode?: string
  setCouponCode?: any
}

export const BookShorlet = ({
  setOpenTerms,
  setOpenPolicy,
  agree,
  setAgree,
  acceptLoading,
  acceptBooking,
  kyc,
  setOpenCancellation,
  couponCode,
  setCouponCode
}: Props): any => {
  const [showCoupon, setShowCoupon] = useState(false)

  return (
    <Fragment>
      <div className="col mb-1 ">
        <span
          className="fs-12 text-link mr-3"
          onClick={() => setOpenTerms(true)}
        >
          Terms and Conditions
        </span>
        <span className="fs-12 text-link" onClick={() => setOpenPolicy(true)}>
          Cancellation Policy
        </span>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            name="terms"
            checked={agree}
            id="terms"
            onChange={() => setAgree(!agree)}
          />
          <label className="form-check-label fs-10 fw-400" htmlFor="terms">
            By booking you agree to the terms and condition of your host.
          </label>
        </div>
      </div>
      <button
        className="my-2 bg-white text-primary fw-bold"
        onClick={() => setShowCoupon(!showCoupon)}
      >
        Have a coupon code?
      </button>

      {showCoupon && (
        <div className="">
          <label htmlFor="customRange2" className="form-label text-black fw-bold fs-16">
            Enter coupon code
          </label>

          <input
            type="text"
            className="form-control rounded-pill py-3 px-4 fs-14 mb-3"
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
            placeholder="Enter 6 digit code given by admin"
          />
        </div>
      )}
      <div className="d-grid gap-2">
        <div>
          <PrimaryButton
            loading={acceptLoading}
            click={acceptBooking}
            type="primary"
            text="Book Now"
            disable={kyc.status !== 'APPROVED' || !agree}
          />
          <div className="fs-10 text-danger text-center  ">
            {kyc.status === 'PENDING' ? (
              <span>
                Please reach out to Krent support on{' '}
                <span className="text-primary">+234 911 800 8008 </span> for an
                update on your KYC pending approval to enable you proceed with
                your booking.
              </span>
            ) : kyc.status === 'APPROVED' ? (
              ''
            ) : (
              <span>
                {' '}
                Please complete your kyc <Link to="/settings">here</Link> to
                proceed
              </span>
            )}
          </div>
        </div>
        <PrimaryButton
          type="outline"
          text="Not Interested"
          click={setOpenCancellation}
        />
      </div>
    </Fragment>
  )
}
