import { useSessionStorage } from '../../../hooks'
import { ChangePasswordRequest } from '@interfaces/user.interface'
import { changePasswordReq, updateProfile } from '@services/krent.service'
import { useState } from 'react'
import ktoast from '@services/toast.service'
import { BaseApiResponse } from '@interfaces/app.interface'
import { useNavigate } from 'react-router-dom'
import { DeveloperDashboardLayout } from '@layouts/DeveloperDashboardLayout'

export const DevelopersSettings = () => {
  const navigate = useNavigate()
  const [user] = useSessionStorage('user', {})
  const [loading, setLoading] = useState<boolean>(false)
  const [passwordMatchError, setPasswordMatchError] = useState<boolean>(false)
  const [language, setLanguage] = useState<string>('')
  const [timeZone, setTimeZone] = useState<string>('')
  const [currency, setCurrency] = useState<string>('')

  const handlePasswordChange = (e: any) => {
    e.preventDefault()
    setLoading(true)
    const { password, newPassword, conPassword } = e.target
    if (newPassword.value === conPassword.value) {
      const abortController = new AbortController()
      const payload: ChangePasswordRequest = {
        oldPassword: password.value,
        passwordConfirm: conPassword.value,
        password: newPassword.value,
      }
      changePasswordReq(user?.id, abortController.signal, payload)
        .then((res: BaseApiResponse<{}>) => {
          if (res.success) {
            ktoast.success('Password changed successfully')
            navigate('/sign-in')
          }
        })
        .catch((e) => {
          ktoast.error('Sorry an error occurred')
          console.log(e)
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setPasswordMatchError(true)
    }
  }

  const handleSubmitPreference = (e: any) => {
    e.preventDefault()
    const abortController = new AbortController()
    if (language || currency || timeZone) {
      updateProfile(
        user?.id,
        {
          language,
          currency,
          timezone: timeZone,
        },
        abortController.signal,
      )
        .then(() => {
          // getProfile(abortController.signal).then((res: any) => {
          // setUser(res.data);
          // });
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false)
          setTimeout(() => {
            navigate('/profile')
          }, 1000)
        })
    }
  }

  return (
    <DeveloperDashboardLayout title="Settings">
      <div className="flex items-center gap-2">
          <p>Home</p>
          <i className="fa-light fa-angle-right"></i>
          <p className="">Dashboard</p>
          <i className="fa-light fa-angle-right"></i>
          <p className="text-primary">Settings</p>
        </div>
      <div className="row">
        <div className="col-12">
          <div className="card border-0 border-round shadow-sm d-flex">
            <div className="card-body pt-5" style={{ paddingLeft: '.8rem' }}>
              {/* Left Sidebar (visible on small screens) */}
              <div className="row">
                <div className="col-12  d-md-none py-5 ps-0 pe-5 border-end">
                  <div className="d-flex align-items-start">
                    <div
                      className="nav flex-column nav-pills me-3 nav-theme-vertical"
                      id="v-pills-tab"
                      role="tablist"
                      aria-orientation="vertical"
                    >
                      <button
                        className="nav-link text-start text-dark mb-4 rounded-0 ps-5 active"
                        id="v-pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-home"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-home"
                        aria-selected="true"
                      >
                        Login &amp; Security
                      </button>

                      <button
                        className="nav-link text-start text-dark mb-4 rounded-0 ps-5 "
                        id="v-pills-messages-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-messages"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-messages"
                        aria-selected="false"
                      >
                        Preferences
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {/* Right Content Section */}
                <div className="col-3 d-none d-md-inline-block ps-0 pe-5 border-end">
                  <div className="d-flex align-items-start">
                    <div
                      className="nav flex-column nav-pills me-3 nav-theme-vertical"
                      id="v-pills-tab"
                      role="tablist"
                      aria-orientation="vertical"
                    >
                      <button
                        className="nav-link text-start text-dark mb-4 rounded-0 ps-5 active"
                        id="v-pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-home"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-home"
                        aria-selected="true"
                      >
                        Login &amp; Security
                      </button>

                      <button
                        className="nav-link text-start text-dark mb-4 rounded-0 ps-5 "
                        id="v-pills-messages-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-messages"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-messages"
                        aria-selected="false"
                      >
                        Preferences
                      </button>
                    </div>
                  </div>
                </div>
                {/* Content Display */}
                <div className="col-12 col-md-9">
                  <div className="tab-content" id="v-pills-tabContent">
                    {/* Login & Security Tab */}
                    <div
                      className="tab-pane fade show active"
                      id="v-pills-home"
                      role="tabpanel"
                      aria-labelledby="v-pills-home-tab"
                      tabIndex={0}
                    >
                      <div className="container">
                        <p className="fw-bold fs-20 mb-5">
                          Login &amp; Security
                        </p>
                        <form className="mb-5" onSubmit={handlePasswordChange}>
                          <div className="row">
                            <div className="mb-4 col-md-6 col-12">
                              <label className="form-label fs-14 fw-600">
                                Old Password
                              </label>
                              <input
                                placeholder="Enter old password"
                                name="password"
                                type="password"
                                className="form-control rounded-pill py-3 px-4 fs-14"
                                required
                              />
                            </div>
                            <div className="mb-4 col-md-6 col-12">
                              <label className="form-label fs-14 fw-600">
                                New Password
                              </label>
                              <input
                                placeholder="Enter new password"
                                name="newPassword"
                                type="password"
                                className="form-control rounded-pill py-3 px-4 fs-14"
                                required
                                onFocus={() => setPasswordMatchError(false)}
                              />
                              {passwordMatchError && (
                                <div
                                  id="emailHelp"
                                  className="form-text text-danger"
                                >
                                  Passwords do not match
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="row mb-4">
                            <div className="mb-4 col-md-6 col-12">
                              <label className="form-label fs-14 fw-600">
                                Confirm Password
                              </label>
                              <input
                                placeholder="Confirm your new password"
                                name="conPassword"
                                type="password"
                                className="form-control rounded-pill py-3 px-4 fs-14"
                                required
                              />
                              {passwordMatchError && (
                                <div
                                  id="emailHelp"
                                  className="form-text text-danger"
                                >
                                  Passwords do not match
                                </div>
                              )}
                            </div>
                          </div>

                          <button
                            type="submit"
                            className="btn btn-outline-dark rounded-pill py-3 px-5 fw-bold fs-14"
                            disabled={loading}
                          >
                            {loading ? (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            ) : (
                              'Save '
                            )}
                          </button>
                        </form>

                        <p className="fw-bolder fs-14 mb-2">
                          Deactivate Account
                        </p>
                        <p className="text-secondary fs-12">
                          Remove your account from Krent permanently{' '}
                          <span className="text-underline text-danger">
                            Deactivate
                          </span>
                        </p>
                      </div>
                    </div>

                    {/* Preference Tab */}
                    <div
                      className="tab-pane fade"
                      id="v-pills-messages"
                      role="tabpanel"
                      aria-labelledby="v-pills-messages-tab"
                      tabIndex={0}
                    >
                      <div className="container">
                        <p className="fw-bold fs-20 mb-5">Preferences</p>
                        <form onSubmit={handleSubmitPreference}>
                          <div className="row mb-4">
                            <div className="mb-4 col-md-6 col-12">
                              <label
                                htmlFor="language"
                                className="form-label fs-14 fw-600"
                              >
                                Language
                              </label>
                              <select
                                className="form-select rounded-pill py-2 px-4"
                                id="language"
                                aria-describedby="emailHelp"
                                value={language}
                                onChange={(e) => setLanguage(e.target.value)}
                              >
                                <option value="">Select</option>
                                <option value="english">English</option>
                                <option value="french">French</option>
                              </select>
                            </div>
                            <div className="mb-4 col-6">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label fs-14 fw-600"
                              >
                                Currency
                              </label>
                              <select
                                className="form-select rounded-pill py-2 px-4"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                value={currency}
                                onChange={(e) => setCurrency(e.target.value)}
                              >
                                <option value="">Select</option>
                                <option value="NGN">Naira - NGN</option>
                                <option value="USD">Dollar - USD</option>
                              </select>
                            </div>
                          </div>

                          <div className="row mb-4">
                            <div className="mb-4 col-md-6 col-12">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label fs-14 fw-600"
                              >
                                Timezone
                              </label>
                              <select
                                className="form-select rounded-pill py-2 px-4"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                value={timeZone}
                                onChange={(e) => {
                                  setTimeZone(e.target.value)
                                }}
                              >
                                <option value="">Select</option>
                                <option value="GMT">GMT +1 Lagos</option>
                                <option value="UTC">UTC -05:00 New York</option>
                              </select>
                            </div>
                          </div>

                          <button
                            type="submit"
                            className="btn btn-outline-dark rounded-pill py-3 px-5 fw-bold fs-14"
                          >
                            Save Preferences
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DeveloperDashboardLayout>
  )
}
