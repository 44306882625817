import React, { useState, useEffect } from 'react'
import { useSessionStorage } from '../../hooks'
import { DashboardLayout } from '@layouts/DashboardLayout'
import { Modal } from 'react-bootstrap'
import MultiStepPaymentModal from 'components/UsersDashboard/paymentModal'
import { getNotifications } from '@services/krentDeveloper'
import { formatDateToTime } from '@utils/helpers'

interface User {
  id?: string
  name?: string
  preferredName?: string
  firstname?: string
  lastname?: string
}

const UsersDashboard: React.FC = () => {
  const [user] = useSessionStorage('user', {} as User)
  const [showProceedtoPayment, setShowProceedtoPayment] = useState(false)
  const [showCustomerSatisfaction, setShowCustomerSatisfaction] = useState(
    false,
  )
  const [notifications, setNotifications] = useState<any[]>([])
  const [selectedNotificationId, setSelectedNotificationId] = useState<string>()
  const displayName =
    user.name ||
    user.preferredName ||
    `${user.firstname || ''} ${user.lastname || ''}`.trim()

  useEffect(() => {
    const fetchNotifications = async () => {
      if (user.id) {
        const abortController = new AbortController()
        try {
          const response = await getNotifications(
            'user',
            user.id,
            abortController.signal,
          )
          setNotifications(response.data)
          console.log('User Notifications:', response.data)
        } catch (err) {
          console.error('Error fetching notifications:', err)
        }
      }
    }

    fetchNotifications()
  }, [user.id])

  return (
    <DashboardLayout>
      <div>
        <h5 className="fs-16 mb-0 lh-1 text-capitalize">
          Welcome {displayName}!
        </h5>

        <div className="my-10">
          {notifications.length > 0 && (
            <div className="flex justify-between notif-card">
              <div className="flex gap-10">
                <i className="fa-regular fa-bell text-primary mt-1"></i>
                <div>
                  <h6 className="">{notifications[0].title}</h6>
                  <p className="my-1">{notifications[0].message}</p>

                  <div className="flex my-4 items-center gap-3">
                    <button
                      className="bg-primary rounded-10 text-white px-3 py-2"
                      onClick={() => {
                        setSelectedNotificationId(notifications[0].id) // Set the selected notification ID
                        setShowProceedtoPayment(true)
                      }}
                    >
                      Yes, proceed to payment
                    </button>
                    <button
                      className="border-red text-red bg-white rounded-10 px-3 py-2"
                      onClick={() => setShowCustomerSatisfaction(true)}
                    >
                      No, it didn't
                    </button>
                  </div>
                </div>
              </div>
              <i className="fa-regular fa-times mt-1"></i>
            </div>
          )}
        </div>
        <hr />
        <div>
          <h4>Recent Notifications</h4>
          <div className="h-70 overflow-scroll">
            {notifications.length > 0 ? (
              notifications.map((notification, idx) => (
                <div key={idx} className="my-4">
                  <h6 className="fs-14">{notification.title}</h6>
                  <p>
                    {notification.message}
                  </p>
                  <h6 className="fs-12">{formatDateToTime(notification.timestamp)}</h6>

                  <hr />
                </div>
              ))
            ) : (
              <p>No recent notifications</p>
            )}
          </div>
        </div>
      </div>

      <MultiStepPaymentModal
        show={showProceedtoPayment}
        onClose={() => setShowProceedtoPayment(false)}
        propertyId={selectedNotificationId} // Pass the selected notification ID
      />
      <Modal
        show={showCustomerSatisfaction}
        onClose={() => setShowCustomerSatisfaction(false)}
      >
        <div className="container py-3 px-4">
          <div className="flex justify-between ">
            <p className="fw-bold ">
              Customer Satisfaction for property inspection
            </p>
            <button onClick={() => setShowCustomerSatisfaction(false)}>
              <i className="fa-x fa-regular bg-white"></i>
            </button>
          </div>
          <div className="my-4">
            <h6 className="fs-12">
              1. Which of these is the reason the inspection was not successful?
            </h6>

            <div>
              <h6 className="fs-12">
                2. Please provide more details on the issue
              </h6>
            </div>
          </div>

          <button
            data-bs-dismiss="modal"
            className="btn btn-primary w-full rounded-pill px-5 py-3 text-white fw-bold mt-3"
          >
            Submit
          </button>
        </div>
      </Modal>
    </DashboardLayout>
  )
}

export default UsersDashboard
