import React, { useEffect, useState } from 'react'
import { getPropertyAmenities } from '@services/krent.service'
import ktoast from '@services/toast.service'
import MultiSelectPropertyType from '@pages/Developers/Projects/multiselect-property'

interface Amenity {
  id?: string
  title: string
  icon: string
  offers: string[]
}

interface PropertyUtilitiesProps {
  onAmenitiesChange: (amenities: Amenity[]) => void
}

const PropertyUtilities: React.FC<PropertyUtilitiesProps> = ({
  onAmenitiesChange,
}) => {
  const [amenities, setAmenities] = useState<Amenity[]>([])
  const [selectedAmenities, setSelectedAmenities] = useState<Amenity[]>([])

  useEffect(() => {
    const fetchAmenities = async () => {
      try {
        const abortController = new AbortController()
        const response = await getPropertyAmenities(abortController.signal)

        const transformedAmenities: Amenity[] = response.data.map(
          (item: any) => ({
            id: item.id,
            title: item.title,
            icon: item.icon || '', 
            offers: item.offers,
          }),
        )

        setAmenities(transformedAmenities)
      } catch (err: any) {
        ktoast.error(err.errors[0].message)
      }
    }

    fetchAmenities()
  }, [])

  const handleAmenityChange = (selectedValues: string[], amenity: Amenity) => {
    const updatedAmenity = {
      ...amenity,
      offers: selectedValues,
    }

    setSelectedAmenities(prev => {
      const newAmenities = prev.filter(item => item.title !== amenity.title)
      return [...newAmenities, updatedAmenity]
    })

    // Ensure the parent component gets the updated state
    const updatedAmenities = selectedAmenities.filter(
      item => item.title !== amenity.title
    ).concat(updatedAmenity)
    
    onAmenitiesChange(updatedAmenities)
  }

  return (
    <div className="space-y-4">
      {amenities.map((amenity: Amenity) => {
        const options = amenity.offers.map(offer => ({
          label: offer,
          value: offer,
        }))

        const selectedOptions = selectedAmenities
          .find(item => item.title === amenity.title)
          ?.offers || []

        return (
          <MultiSelectPropertyType
            key={amenity.id || amenity.title}
            title={amenity.title}
            options={options}
            selectedOptions={selectedOptions}
            setSelectedOptions={(selected) => handleAmenityChange(selected, amenity)}
            placeholder={`Select ${amenity.title.toLowerCase()}...`}
          />
        )
      })}
    </div>
  )
}

export default PropertyUtilities